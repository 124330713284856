import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Badge, Flex, Row, Tabs} from 'antd';

import {ApplicationItem} from '../../../../models';
import {SportsmensGroupsModal} from '../SportsmensGroupsModal/SportsmensGroupsModal';
import {NominationCard} from '../NominationCard/NominationCard';
import {ApplicationsButton} from '../ApplicationsButton/ApplicationsButton';
import {
  EventResponse,
  EventNominationResponse,
  StatementResponse,
} from '../../../../store/api';

type arType = {
  nominations: EventNominationResponse[];
  category: string;
}[];

interface ApplicationsProps {
  event: EventResponse;
  setLoading: Dispatch<SetStateAction<boolean>>;
  statement?: StatementResponse;
}

export const Applications: FC<ApplicationsProps> = ({
  event,
  setLoading,
  statement,
}) => {
  const [activeNomination, setActiveNomination] =
    useState<EventNominationResponse | null>(null);
  const [categories, setCategories] = useState<arType>([]);

  const [applications, setApplications] = useState<ApplicationItem[]>(
    statement?.applications ?? [],
  );

  const getNominationApplications = useCallback(
    (nominationId: number) => {
      return applications.filter(app => app.nomination.id === nominationId);
    },
    [applications],
  );

  const relationIds = useMemo(() => {
    return applications
      .filter(app => app.nomination.type === activeNomination?.type)
      .map(app => app.relationId);
  }, [activeNomination, applications]);

  useEffect(() => {
    const categoriesValues: arType = [];

    const nominationsGroupByCategory = event.nominations.reduce<{
      [k: string]: EventNominationResponse[];
    }>((acc, current) => {
      if (acc[current.categoryName]) {
        acc[current.categoryName].push(current);
      } else {
        acc[current.categoryName] = [current];
      }
      return acc;
    }, {});

    for (const [key, value] of Object.entries(nominationsGroupByCategory)) {
      categoriesValues.push({category: key, nominations: value});
    }

    setCategories(categoriesValues);
  }, []);

  const addApplications = (newApplications: ApplicationItem[]) => {
    if (activeNomination) {
      setApplications([...applications, ...newApplications]);
    }
  };

  const getCategoryCount = (nominations: EventNominationResponse[]) => {
    const ids = nominations.map(nom => nom.id);

    return applications.filter(app => ids.includes(app.nomination.id)).length;
  };

  const deleteApplication = (nominationId: number, relationId: number) => {
    const newApplications = applications.filter(
      app =>
        app.nomination.id !== nominationId || app.relationId !== relationId,
    );

    setApplications(newApplications);
  };

  return (
    <>
      {activeNomination && (
        <SportsmensGroupsModal
          close={() => setActiveNomination(null)}
          nomination={activeNomination}
          addApplications={addApplications}
          relationsIds={relationIds}
        />
      )}

      <Tabs
        size="large"
        items={categories.map(cat => ({
          key: cat.category,
          label: (
            <Row align={'middle'}>
              {cat.category}
              <Badge
                count={getCategoryCount(cat.nominations)}
                offset={[2, 0]}
                style={{backgroundColor: '#52c41a'}}
              />
            </Row>
          ),
          children: cat.nominations.map(nomination => (
            <NominationCard
              key={nomination.name}
              nomination={nomination}
              addApplicationButtonHandler={() =>
                setActiveNomination(nomination)
              }
              applications={getNominationApplications(nomination.id)}
              deleteApplication={deleteApplication}
            />
          )),
        }))}
      />

      <ApplicationsButton
        event={event}
        applications={applications}
        statementId={statement?.id}
        setLoading={setLoading}
      />
    </>
  );
};
