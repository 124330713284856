interface IDataError {
  statusCode: number;
  error: string;
  message: string;
}

interface IError {
  status: number;
  data: IDataError;
}

export function getFetchError(error: unknown) {
  if (typeof error === 'object' && error !== null) {
    if ('data' in error) {
      if ((error as IError).data !== null) {
        return error as IError;
      }
    }
  }
}
