import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {Input} from 'antd';
import {openModal} from '../../../../store/reducers/modalSlice';
import {useAppDispatch, useDebounce} from '../../../../hooks';
import {IGroupResponse} from '../../../../models';

import styles from './style.module.css';

const {Search} = Input;

interface HeaderProps {
  groups: IGroupResponse[];
  setFilteredGroups: Dispatch<SetStateAction<IGroupResponse[]>>;
}

export const Header: FC<HeaderProps> = ({groups, setFilteredGroups}) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const dispatch = useAppDispatch();

  const debouncedSearchTerm = useDebounce(searchValue, 300);

  useEffect(() => {
    if (debouncedSearchTerm.length > 2) {
      onSearch();
    } else {
      setFilteredGroups(groups);
    }
  }, [debouncedSearchTerm]);

  const onSearch = () => {
    const searchResult = groups.filter(group => {
      const foundedSportsmen = group.sportsmens.find(sportsmen => {
        const {firstname, lastname, middlename} = sportsmen;
        return (
          `${lastname} ${firstname} ${middlename}`
            .toLowerCase()
            .search(searchValue.toLowerCase()) !== -1 && sportsmen
        );
      });

      if (foundedSportsmen) {
        return group;
      }
    });

    setFilteredGroups(searchResult);
  };

  return (
    <div className={styles.header}>
      <button
        className={styles.button}
        onClick={() => {
          dispatch(openModal({modal: 'groupModal'}));
        }}>
        <span className={styles.buttonText}>Добавить</span>
      </button>
      <Search
        value={searchValue}
        size="large"
        allowClear
        className={styles.search}
        placeholder="Поиск группы"
        onChange={e => setSearchValue(e.target.value)}
        onSearch={onSearch}
        enterButton
      />
    </div>
  );
};
