import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {IScore} from '@pages/Juding/types';
import {
  IJudgeAuthResponse,
  IJudgingSportsmenResponse,
  JudgingApplicationResponse,
} from '../api';
import {NewScore} from '@pages/Juding/Arbitrator/types';

export interface JudgeNames {
  I1: IScore;
  I2: IScore;
  I3: IScore;
  I4: IScore;
  I5: IScore;
  I6: IScore;
  A1: IScore;
  A2: IScore;
  A3: IScore;
  A4: IScore;
  A5: IScore;
  A6: IScore;
  S: IScore;
}

export interface IJudgeSportsmen extends JudgeNames {
  category: {name: string; catIndex: number};
  nomination: {name: string; nomIndex: number};
  id: number;
  name: string;
  score: string;
  decline: string;
  avg_isp: string;
  avg_art: string;
  avg_sl: string;
  line: string;
  pbs: string;
  avg_decline: string;
  total: string;
}

export interface JudgeState {
  judge: IJudgeAuthResponse | null;
  judgingMode: 4 | 6;
  spreadsheetId: string;
  selectedApplication: JudgingApplicationResponse | null;
  selectedApplicationIndex: number;
  newScoreData: NewScore | null;
  previousScoreData: IJudgingSportsmenResponse | null;
}

const initialState: JudgeState = {
  judge: null,
  judgingMode: 4,
  spreadsheetId: '',
  selectedApplication: null,
  selectedApplicationIndex: 0,
  newScoreData: null,
  previousScoreData: null,
};

export const judgeSlice = createSlice({
  name: 'judge',
  initialState,
  reducers: {
    setJudge: (state, action: PayloadAction<IJudgeAuthResponse>) => {
      state.judge = action.payload;
    },
    changeJudgingMode: (state, action: PayloadAction<4 | 6>) => {
      state.judgingMode = action.payload;
    },

    setSpreadsheetId: (state, action: PayloadAction<string>) => {
      state.spreadsheetId = action.payload;
    },
    setSelectedApplication: (
      state,
      action: PayloadAction<{
        application: JudgingApplicationResponse;
        applicationIndex: number;
      }>,
    ) => {
      state.selectedApplication = action.payload.application;
      state.selectedApplicationIndex = action.payload.applicationIndex;
    },

    setNewScoreData: (state, action: PayloadAction<NewScore>) => {
      state.newScoreData = action.payload;
    },
    setShowPreviousScoreData: (
      state,
      action: PayloadAction<IJudgingSportsmenResponse | null>,
    ) => {
      state.previousScoreData = action.payload;
    },
  },
});

export const {
  setJudge,
  changeJudgingMode,
  setSpreadsheetId,
  setSelectedApplication,
  setNewScoreData,
  setShowPreviousScoreData,
} = judgeSlice.actions;

export const getJudgeState = (state: RootState) => state.judge;

export default judgeSlice.reducer;
