import {FC, useEffect, useState} from 'react';
import {Button, Spin, Typography} from 'antd';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {AuthLayout} from '../../ui';
import {MainCard} from '../../components';
import {confirmMailApi} from '../../store/api';
import {getFetchError} from '../../utils/getFetchError';
import {useAppDispatch} from '../../hooks';
import {setMailConfirmed} from '../../store/auth/auth.slice';

import styles from './style.module.css';

const {Title} = Typography;

export const ConfirmMail: FC = () => {
  const [fetchError, setFetchError] = useState<string>('');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const [confirmMail, {isLoading, isSuccess, error}] =
    confirmMailApi.useConfirmMailMutation();

  const token = searchParams.get('token');
  useEffect(() => {
    if (token) {
      confirmMail(token);
    }
  }, []);

  useEffect(() => {
    if (error) {
      setFetchError(getFetchError(error)?.data.message ?? '');
    }
  }, [error]);

  const buttonHandler = (): void => {
    dispatch(setMailConfirmed());
    navigate('/');
  };

  return (
    <AuthLayout>
      <MainCard style={{width: '50%'}}>
        <Spin spinning={isLoading}>
          <div className={styles.container}>
            {isSuccess ? (
              <>
                <Title level={2}>Ваша почта подтверждена!</Title>
                <Button onClick={buttonHandler}>Перейти на сайт</Button>
              </>
            ) : (
              <>
                <Title level={3}>
                  {fetchError}. Чтобы получить новую ссылку авторизуйтесь на
                  сайте и запросите новую ссылку
                </Title>
                <Button onClick={buttonHandler}>Вход</Button>
              </>
            )}
          </div>
        </Spin>
      </MainCard>
    </AuthLayout>
  );
};
