import {FC, useContext, useMemo, useState} from 'react';
import {Table, TableProps} from 'antd';

import {ApplicationsTableColumns} from './ApplicationsTableColumns';
import {ModalSendScore} from '../ModalSendScore';
import {OnScoreHandlerParams} from './types';
import {JudgeType} from '@pages/Juding/Arbitrator/types';
import {DrawerContext} from '../../context';
import {IJudgingSportsmenResponse} from 'store/api';
import {useAppSelector, useUpdateScoresTable} from 'hooks';

interface ApplicationsTableProps {}

export const ApplicationsTable: FC<ApplicationsTableProps> = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [judge, setJudge] = useState<string>('');
  const [judgeType, setJudgeType] = useState<JudgeType>();
  const [scoreValue, setScoreValue] = useState<string>('');
  const [selectedApplication, setSelectedApplication] =
    useState<IJudgingSportsmenResponse>();

  const {setDrawerData, drawerData} = useContext(DrawerContext);
  const judgingMode = useAppSelector(state => state.judge.judgingMode);

  const {applications: tableData, updateDataTable} = useUpdateScoresTable();

  const setSelectedApplications = (
    applications: IJudgingSportsmenResponse[],
  ) => {
    setDrawerData(prevState => ({
      ...prevState,
      selectedApplications: applications,
    }));
  };

  const onScoreHandler = ({
    application,
    judge,
    scoreValue,
    judgeType,
  }: OnScoreHandlerParams) => {
    setOpen(true);
    setJudge(judge);
    setJudgeType(judgeType);
    setScoreValue(scoreValue);
    setSelectedApplication(application);
  };

  const columns = useMemo(
    () => ApplicationsTableColumns({judgeMode: judgingMode, onScoreHandler}),
    [judgingMode],
  );

  const onChange: TableProps<IJudgingSportsmenResponse>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra,
  ) => {
    if (extra.action === 'sort') {
      setSelectedApplications([]);
    }
  };

  return (
    <>
      {selectedApplication && (
        <ModalSendScore
          open={open}
          setOpen={setOpen}
          application={selectedApplication}
          judge={judge}
          scoreValue={scoreValue}
          updateDataTable={score =>
            updateDataTable(score, judge, judgeType, selectedApplication)
          }
        />
      )}

      <Table
        dataSource={tableData}
        rowKey={record => record.id}
        columns={columns}
        pagination={false}
        scroll={{
          x: '100%',
        }}
        style={{width: '100%'}}
        bordered
        size={'small'}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: drawerData.selectedApplications.map(app => app.id),
          onChange: (_, v) => setSelectedApplications(v),
        }}
        onChange={onChange}
      />
    </>
  );
};
