import {Centrifuge, Subscription} from 'centrifuge';

import {axiosBase} from 'api/axios';
import {store} from 'store/store';

const CENTRIFUGO_URL = `${process.env.REACT_APP_BASE_SOCKET_URL}`;

export class CentrifugeService {
  static connectionToken: string;
  static centrifuge: Centrifuge;
  static subscription: Subscription;

  static async getToken() {
    const authToken = store.getState().judge.judge?.token;

    return await axiosBase
      .get<{token: string}>('centrifugo/get-connection-token', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(res => {
        const newToken = res.data.token;
        this.connectionToken = newToken;
        return newToken;
      });
  }

  static async connectCentrifuge(
    onConnect?: () => void,
    onDisconnect?: () => void,
  ) {
    const centrifugeToken = localStorage.getItem('centrifugeToken');

    if (!centrifugeToken) {
      const tok = await this.getToken();

      localStorage.setItem('centrifugeToken', tok);
    }

    if (!this.centrifuge) {
      const newCentrifugo = new Centrifuge(
        `${CENTRIFUGO_URL}/connection/websocket`,
        {
          token: centrifugeToken ?? this.connectionToken,
          getToken: () => this.getToken(),
        },
      );

      newCentrifugo.connect();

      this.centrifuge = newCentrifugo;

      newCentrifugo
        .on('connecting', function (ctx) {
          console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
          onDisconnect?.();
        })
        .on('connected', function (ctx) {
          onConnect?.();
          console.log(`connected over ${ctx.transport}`);
        })
        .on('disconnected', function (ctx) {
          onDisconnect?.();
          console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
        })
        .on('error', function (ctx) {
          console.log('client error', ctx);
        });
    }
  }

  static disconnectCentrifuge() {
    if (this.centrifuge?.state === 'connected') {
      this.centrifuge?.disconnect();
    }
  }

  static async getSubscription(
    channel: string,
    callback?: (data: any) => void,
  ) {
    const centrifugeToken = localStorage.getItem('centrifugeToken');

    if (!this.centrifuge || !centrifugeToken) {
      await this.connectCentrifuge();
    }

    if (!this.centrifuge.getSubscription(channel)) {
      this.subscription = this.centrifuge.newSubscription(channel);

      this.subscription.subscribe();
    }

    this.subscription
      ?.on('subscribing', function (ctx) {
        console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
      })
      .on('subscribed', function (ctx) {
        console.log('subscribed', ctx);
      })
      .on('unsubscribed', function (ctx) {
        console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
      })
      .on('publication', function (ctx) {
        console.log('publication', ctx);
        callback?.(ctx.data);
      });

    return this.subscription;
  }

  static unsubscribe(channel: string) {
    this.centrifuge?.removeSubscription(
      this.centrifuge.getSubscription(channel),
    );

    this.subscription?.unsubscribe();
  }

  // static publication(callback: any) {
  //   this.subscription?.on('publication', ctx => {
  //     callback(ctx);
  //   });
  // }

  // static resetCentrifuge() {
  //   this.centrifuge = null;
  //   this.subscription = null;
  //   this.subscriptionToken = '';

  //   localStorage.removeItem('centrifugeToken');
  // }
}
