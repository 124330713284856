import {axiosBase} from '../api/axios';
import {IRegisterData} from '../pages/Auth/auth.interface';

export enum UserRole {
  Admin = 'Admin',
  User = 'User',
}

export interface IAuthData {
  user: {
    firstname: string;
    lastname: string;
    middlename: string;
    email: string;
    mailConfirmed: boolean;
    roles: UserRole[];
  } | null;
  accessToken: string;
}

export const AuthService = {
  async login(email: string, password: string) {
    const response = await axiosBase.post<IAuthData>('/auth/login', {
      email,
      password,
    });

    return response.data;
  },
  async register(data: IRegisterData) {
    const response = await axiosBase.post<IAuthData>('/auth/register', data);

    return response.data;
  },
  async forgotPassword(data: string) {
    await axiosBase.post('/forgot-password', {email: data});

    return true;
  },
  async resetPassword(data: any) {
    await axiosBase.patch('/forgot-password', data);

    return true;
  },
  async getCities(cityQuery: string) {
    return await axiosBase.get('/auth/getCities', {params: {cityQuery}});
  },
};
