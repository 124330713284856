import {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {Flex} from 'antd';

import {adminApi} from '../../../../store/api';
import {EventStatisticCityTable} from './EventStatisticCityTable';
import {EventStatisticRankTable} from './EventStatisticRankTable';
import {EventStatisticNominationTable} from './EventStatisticNominationTable';
import {EventStatisticCoachTable} from './EventStatisticCoachTable';
import {EventStatisticTotal} from './EventStatisticTotal';

interface EventStatisticProps {
  selectedEventId: string;
  setSelectedEventId: Dispatch<SetStateAction<string>>;
}

export const EventStatistic: FC<EventStatisticProps> = ({
  selectedEventId,
  setSelectedEventId,
}) => {
  const {
    data: eventData,
    isFetching: fetchingEventStatistic,
    isError,
  } = adminApi.useGetEventStatisticQuery(
    {eventId: selectedEventId},
    {
      skip: !selectedEventId,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (isError) {
      setSelectedEventId('');
    }
  }, [isError]);

  useEffect(() => {
    if (eventData) {
    }
  }, [eventData]);

  if (!eventData) {
    return null;
  }

  return (
    <Flex vertical gap={16}>
      <EventStatisticTotal
        applicationsCount={eventData.applicationsCount}
        uniqueSportsmensCount={eventData.uniqueSportsmensCount}
      />

      <EventStatisticCityTable
        categories={eventData.categories}
        data={eventData.cityTable}
      />
      <EventStatisticRankTable
        categories={eventData.categories}
        data={eventData.rankTable}
      />
      <EventStatisticNominationTable
        categories={eventData.categories}
        data={eventData.nominationTable}
      />
      <EventStatisticCoachTable data={eventData.coachTable} />
    </Flex>
  );
};
