import React, {useState} from 'react';
import {SportsmensTable} from './components';
import {SportsmensGroupsLayout} from '../../ui';

export const Sportsmens: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <SportsmensGroupsLayout loading={loading}>
      <SportsmensTable setLoading={setLoading} />
    </SportsmensGroupsLayout>
  );
};
