import {Button, Checkbox, DatePicker, Form, Input, Select} from 'antd';

import {CreateEventData, eventApi} from 'store/api';
import {MainLayout} from 'ui';
import {AddEventCategoriesList} from './components';
import {EventFields} from './types';
import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';

const eventStatuses = [
  {
    label: 'Подача заявок',
    value: 'active',
  },
  {
    label: 'Судейство',
    value: 'judging',
  },
  {
    label: 'Завершено',
    value: 'completed',
  },
  {
    label: 'Отменено',
    value: 'canceled',
  },
];

export const CreateEvent = () => {
  const [form] = Form.useForm<EventFields>();
  const nominations = Form.useWatch('nominations', form);
  const navigate = useNavigate();

  const [createEvent, {isSuccess}] = eventApi.useCreateEventMutation();

  const onFinish = (values: EventFields) => {
    const {
      title,
      location,
      eventDates,
      registrationDates,
      nominations,
      image,
      information,
      published,
      status,
    } = values;

    const createData: CreateEventData = {
      title,
      location,
      categoriesNominations: nominations,
      image,
      information,
      published,
      startRegistration: eventDates[0],
      deadlineRegistration: eventDates[1],
      beginDate: registrationDates[0],
      endDate: registrationDates[1],
      state: status,
    };

    createEvent(createData);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate('/events');
    }
  }, [isSuccess]);

  return (
    <MainLayout title="Создание соревнования">
      <Form
        form={form}
        name="eventCreateForm"
        layout="vertical"
        wrapperCol={{span: 12}}
        onFinish={onFinish}
        size="large"
        autoComplete="off">
        <Form.Item<EventFields>
          name="title"
          rules={[
            {required: true, message: 'Поле обязательно для заполнения'},
          ]}>
          <Input placeholder="Название соревнования" />
        </Form.Item>

        <Form.Item<EventFields>
          name="location"
          rules={[
            {required: true, message: 'Поле обязательно для заполнения'},
          ]}>
          <Input placeholder="Место проведения" />
        </Form.Item>

        <Form.Item<EventFields>
          label="Даты проведения"
          name={'eventDates'}
          rules={[
            {required: true, message: 'Поля обязательны для заполнения'},
          ]}>
          <DatePicker.RangePicker placeholder={['Начало', 'Конец']} />
        </Form.Item>

        <Form.Item<EventFields>
          label="Даты регистрации"
          name={'registrationDates'}
          rules={[
            {required: true, message: 'Поля обязательны для заполнения'},
          ]}>
          <DatePicker.RangePicker placeholder={['Начало', 'Конец']} />
        </Form.Item>

        <Form.Item<EventFields>
          name="published"
          valuePropName="checked"
          wrapperCol={{span: 16}}>
          <Checkbox>Опубликовать</Checkbox>
        </Form.Item>

        <Form.Item<EventFields> name="status">
          <Select placeholder="Статус соревнования" options={eventStatuses} />
        </Form.Item>

        <Form.Item<EventFields> name="information">
          <Input.TextArea placeholder="Информация" rows={4} />
        </Form.Item>

        <AddEventCategoriesList nominations={nominations} />

        <Form.Item name="event">
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </Form.Item>
      </Form>
    </MainLayout>
  );
};
