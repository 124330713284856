import axios from 'axios';

const API_URL = `${process.env.REACT_APP_BASE_URL}/api`;

export const axiosBase = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
