import {useEffect, useState} from 'react';
import {useAppSelector} from 'hooks';

import {ApplicationsTable, ArbitratorPageHeader} from './components';
import {getJudgeState} from 'store/reducers';
import {JudgingLayout} from 'ui';
import {getNewApplicationsScores} from 'utils/getNewApplicationsScores';
import {DrawerContext, DrawerData, initDrawerData} from './context';
import {IJudgingSportsmenResponse} from 'store/api';

export const ArbitratorOld = () => {
  const [drawerData, setDrawerData] = useState<DrawerData>(initDrawerData);
  const [applications, setApplications] = useState<IJudgingSportsmenResponse[]>(
    [],
  );

  const newScoreData = useAppSelector(state => state.judge.newScoreData);
  const {judgingMode} = useAppSelector(getJudgeState);

  useEffect(() => {
    if (newScoreData) {
      if (
        drawerData &&
        drawerData.nomination?.id === newScoreData.nomination.id
      ) {
        const newApplications = getNewApplicationsScores({
          dataTable: applications,
          newScore: newScoreData,
          judgingMode,
        });

        setApplications(newApplications);
      }
    }
  }, [newScoreData, drawerData.nomination]);

  return (
    <JudgingLayout>
      <DrawerContext.Provider value={{drawerData, setDrawerData}}>
        <ArbitratorPageHeader setApplications={setApplications} />

        <ApplicationsTable applications={applications} />
      </DrawerContext.Provider>
    </JudgingLayout>
  );
};
