import React, {ReactNode} from 'react';
import {Modal, Typography} from 'antd';
import {ExclamationCircleFilled} from '@ant-design/icons';

import styles from '../../pages/Groups/components/GroupList/style.module.css';

const {confirm} = Modal;
const {Title} = Typography;

export const showDeleteConfirm = (
  title: string,
  deleteHandler: () => void,
  content: ReactNode,
) => {
  confirm({
    title: (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <ExclamationCircleFilled style={{color: '#ff4d4f', fontSize: 26}} />
        <Title style={{margin: 0, marginInlineStart: 10}} level={3}>
          {title}
        </Title>
      </div>
    ),
    centered: true,
    icon: null,
    width: '50%',
    content: <div style={{fontSize: 18, marginInlineStart: 36}}>{content}</div>,
    okText: 'Удалить',
    okType: 'danger',
    okButtonProps: {
      type: 'primary',
      size: 'large',
      style: {fontWeight: 600, fontSize: 16},
    },
    cancelButtonProps: {
      className: styles.cancelButton,
      size: 'large',
      type: 'text',
      style: {fontSize: 16, fontWeight: 600, color: '#1890ff'},
    },
    cancelText: 'Отмена',
    onOk() {
      deleteHandler();
    },
    onCancel() {},
  });
};
