import React, {FC, PropsWithChildren} from 'react';
import {Radio, RadioChangeEvent, Spin} from 'antd';
import {MainLayout} from '../index';
import {useLocation, useNavigate} from 'react-router-dom';

import styles from './style.module.css';

interface SportsmensGroupsLayoutProps extends PropsWithChildren {
  loading: boolean;
}

const options = [
  {label: 'Спортсмены', value: 'sportsmens'},
  {label: 'Группы', value: 'groups'},
];

export const SportsmensGroupsLayout: FC<SportsmensGroupsLayoutProps> = ({
  loading,
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateHandler = ({target: {value}}: RadioChangeEvent) => {
    navigate(`/${value}`);
  };

  return (
    <MainLayout title={'Спортсмены'}>
      <Radio.Group
        size="large"
        options={options}
        onChange={navigateHandler}
        value={location.pathname.substring(1)}
        optionType="button"
        buttonStyle="solid"
      />

      <div className={styles.siteLayoutBackground}>
        <Spin spinning={loading} size="large">
          {children}
        </Spin>
      </div>
    </MainLayout>
  );
};
