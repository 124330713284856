import {useEffect, useRef, useState} from 'react';
import {io, Socket} from 'socket.io-client';

import {NewScore} from '@pages/Juding/Arbitrator/types';
import {useAppDispatch} from './hook';
import {setNewScoreData, setShowPreviousScoreData} from 'store/reducers';
import {IJudgingSportsmenResponse} from 'store/api';

const API_URL = `${process.env.REACT_APP_BASE_URL}`;

export const useSocketHook = () => {
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const socketRef = useRef<Socket | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    socketRef.current = io(API_URL, {secure: true, autoConnect: false});

    return () => {
      socketRef.current?.disconnect();
      socketRef.current?.off('connect');
      socketRef.current?.off('disconnect');
      socketRef.current?.off('pong');
    };
  }, []);

  const connect = () => {
    socketRef.current?.connect();

    socketRef.current?.on('connect', () => {
      setIsConnected(true);
    });
    socketRef.current?.on('disconnect', () => {
      setIsConnected(false);
    });
  };

  const connectArbitrator = ({
    eventId,
    brigade,
  }: {
    eventId: number;
    brigade: number;
  }) => {
    socketRef.current?.emit('arbJoin', {
      eventId,
      brigade,
    });

    socketRef.current?.on('newScore', (newScore: NewScore) => {
      dispatch(setNewScoreData(newScore));
    });
  };

  const connectJudge = ({
    eventId,
    brigade,
  }: {
    eventId: number;
    brigade: number;
  }) => {
    connect();
    socketRef.current?.emit('judgeJoin', {
      eventId,
      brigade,
    });

    socketRef.current?.on(
      'showScoreJudge',
      (payload: IJudgingSportsmenResponse | null) => {
        dispatch(setShowPreviousScoreData(payload));
      },
    );
  };

  return {
    socket: socketRef.current,
    connect,
    isConnected,
    connectArbitrator,
    connectJudge,
  };
};
