import React, {FC, useEffect, useState} from 'react';
import {Input} from 'antd';
import {SportsmensMiniTable} from '../../../../components';
import {ISportsmen} from '../../../../models';
import {sportsmenApi} from '../../../../store/api';
import {useDebounce} from '../../../../hooks';

const {Search} = Input;

interface AddGroupModalSportsmensTableProps {
  groupSportsmens: ISportsmen[];
  rowHandler: (sportsmen: ISportsmen) => void;
  disableTable: boolean;
}

const defaultPageSize = 10;

export const AddGroupModalSportsmensTable: FC<
  AddGroupModalSportsmensTableProps
> = ({groupSportsmens, rowHandler, disableTable}) => {
  const [filteredSportsmens, setFilteredSportsmens] = useState<ISportsmen[]>(
    [],
  );
  const [currentPage, setCurrenPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>('');

  const debouncedSearchTerm = useDebounce(searchValue, 300);

  const {data: sportsmens, isFetching} = sportsmenApi.useGetSportsmensQuery({});

  const setSportsmens = () => {
    const sportsmensData =
      searchValue.length > 2 ? onSearch() : sportsmens?.items;

    setFilteredSportsmens(
      sportsmensData?.filter(
        filteredSportsmen =>
          !groupSportsmens.find(
            groupSportsmen => groupSportsmen.id === filteredSportsmen.id,
          ),
      ) || [],
    );
  };

  useEffect(() => {
    setSportsmens();
  }, [groupSportsmens]);

  useEffect(() => {
    if (sportsmens) {
      setSportsmens();
    }
  }, [sportsmens]);

  useEffect(() => {
    setSportsmens();
  }, [debouncedSearchTerm]);

  const onSearch = () => {
    return (
      sportsmens?.items.filter(sportsmen => {
        const {firstname, lastname, middlename} = sportsmen;
        return (
          `${lastname} ${firstname} ${middlename}`
            .toLowerCase()
            .search(searchValue.toLowerCase()) !== -1 && sportsmen
        );
      }) ?? []
    );
  };

  return (
    <>
      <Search
        value={searchValue}
        size="large"
        allowClear
        placeholder="Поиск спортсмена"
        onChange={e => setSearchValue(e.target.value)}
        onSearch={onSearch}
        enterButton
        disabled={disableTable}
      />
      <SportsmensMiniTable
        data={filteredSportsmens}
        rowHandler={rowHandler}
        loading={isFetching}
        disableRow={disableTable}
        pagination={{
          current: currentPage,
          total: filteredSportsmens.length,
          pageSize: defaultPageSize,
          showSizeChanger: false,
          onChange: page => setCurrenPage(page),
        }}
        style={{
          marginTop: 10,
        }}
      />
    </>
  );
};
