import {createContext} from 'react';
import {EventNominationResponse, IJudgingSportsmenResponse} from 'store/api';

export type DrawerData = {
  nomination: EventNominationResponse | null;
  allSportsmens: any[];
  selectedApplications: IJudgingSportsmenResponse[];
  eventCategoryId: string;
};

export const initDrawerData: DrawerData = {
  nomination: null,
  allSportsmens: [],
  selectedApplications: [],
  eventCategoryId: '',
};

export type DrawerContextType = {
  drawerData: DrawerData;
  setDrawerData: React.Dispatch<React.SetStateAction<DrawerData>>;
};

export const DrawerContext = createContext<DrawerContextType>({
  drawerData: initDrawerData,
  setDrawerData: () => {},
});
