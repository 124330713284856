import {FC, memo} from 'react';
import {Button} from 'antd';

import styles from './style.module.css';

interface TableScoreButtonProps {
  value: string;
  onClick?: () => void;
}

export const TableScoreButton: FC<TableScoreButtonProps> = memo(
  ({value, onClick}) => {
    return (
      <Button type="text" className={styles.button} onClick={onClick}>
        {value || '0,0'}
      </Button>
    );
  },
);
