import React, {Dispatch, FC, SetStateAction, useContext, useState} from 'react';
import {
  Button,
  Card,
  Col,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
} from 'antd';
import {DownloadService} from '../../../../../../services';
import {useAppSelector, useLocalStorage} from '../../../../../../hooks';
import {DrawerContext} from '../../../context/DrawerContext';

interface ActionProtocolProps {
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const protocolTypes = ['Финал', 'Квалификация'];

export const ActionProtocol: FC<ActionProtocolProps> = ({setLoading}) => {
  const [protocolType, setProtocolType] = useState<string>(protocolTypes[0]);

  const [mainJudgeName, setMainJudgeName] = useLocalStorage(
    'mainJudgeName',
    '',
  );
  const [mainSecretaryName, setMainSecretaryName] = useLocalStorage(
    'mainSecretaryName',
    '',
  );

  const {drawerData} = useContext(DrawerContext);
  const judgingMode = useAppSelector(state => state.judge.judgingMode);

  const onProtocolTypeChange = (e: RadioChangeEvent) => {
    setProtocolType(e.target.value);
  };

  const createProtocolHandler = async () => {
    if (drawerData.nomination) {
      setLoading(true);

      const file = await DownloadService.getProtocolFile({
        nominationId: drawerData.nomination?.id,
        protocolType,
        mainJudgeName,
        mainSecretaryName,
        sportsmens: drawerData.selectedApplications,
        judgeCount: judgingMode,
      }).finally(() => {
        setLoading(false);
      });

      if (file) {
        const fileUrl = window.URL.createObjectURL(new Blob([file.data]));

        const link = document.createElement('a');
        link.href = fileUrl;

        const disposition = file.headers['content-disposition'];

        const fileName = disposition
          ? disposition.split(/;(.+)/)[1].split(/=(.+)/)[1].replace(/['"]/g, '')
          : 'statements';

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }

      setLoading(false);
    }
  };

  return (
    <Card title="Протокол номинации">
      <Row gutter={[8, 8]} style={{}}>
        <Col>
          <Space direction="vertical">
            <Radio.Group
              onChange={onProtocolTypeChange}
              value={protocolType}
              size="large">
              <Space direction="vertical">
                {protocolTypes.map(type => (
                  <Radio key={type} value={type}>
                    {type}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Space>
        </Col>

        <Col span={24}>
          <Input
            placeholder="Главный судья"
            value={mainJudgeName}
            onChange={e => setMainJudgeName(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <Input
            placeholder="Главный секретарь"
            value={mainSecretaryName}
            onChange={e => setMainSecretaryName(e.target.value)}
          />
        </Col>

        <Col>
          <Button onClick={createProtocolHandler}>Скачать</Button>
        </Col>
      </Row>
    </Card>
  );
};
