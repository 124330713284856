import React, {FC} from 'react';
import {Card, CardProps} from 'antd';

import styles from './mainCard.module.css';

export const MainCard: FC<CardProps> = ({title, children, ...props}) => (
  <Card
    title={title}
    headStyle={{
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 20,
      fontWeight: 700,
    }}
    bordered={false}
    className={styles.card}
    {...props}>
    {children}
  </Card>
);
