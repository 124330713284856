import {IJudgingSportsmenResponse} from 'store/api';

export const getAvgIspScore = (
  {isp1, isp2, isp3, isp4, isp5, isp6}: IJudgingSportsmenResponse,
  judgingMode: 4 | 6,
) => {
  if (!isp1 || !isp2 || !isp3 || !isp4) {
    return '';
  }

  const ispScores = [
    Number(isp1.replace(/,/g, '.')),
    Number(isp2.replace(/,/g, '.')),
    Number(isp3.replace(/,/g, '.')),
    Number(isp4.replace(/,/g, '.')),
  ];

  if (judgingMode === 6) {
    if (!isp5 || !isp6) {
      return '';
    }

    ispScores.push(Number(isp5.replace(/,/g, '.')));
    ispScores.push(Number(isp6.replace(/,/g, '.')));
  }

  ispScores.sort();

  const minValue = ispScores[ispScores.length / 2 - 1];
  const maxValue = ispScores[ispScores.length / 2];

  const avgIsp = (maxValue + minValue) / 2;

  return String(avgIsp.toFixed(2)).replace('.', ',');
};

export const getAvgArtScore = (
  {art1, art2, art3, art4, art5, art6}: IJudgingSportsmenResponse,
  judgingMode: 4 | 6,
) => {
  if (!art1 || !art2 || !art3 || !art4) {
    return '';
  }

  const artScores = [
    Number(art1.replace(/,/g, '.')),
    Number(art2.replace(/,/g, '.')),
    Number(art3.replace(/,/g, '.')),
    Number(art4.replace(/,/g, '.')),
  ];

  if (judgingMode === 6) {
    if (!art5 || !art6) {
      return '';
    }

    artScores.push(Number(art5.replace(/,/g, '.')));
    artScores.push(Number(art6.replace(/,/g, '.')));
  }

  artScores.sort();

  const minValue = artScores[artScores.length / 2 - 1];
  const maxValue = artScores[artScores.length / 2];

  const avgArt = (maxValue + minValue) / 2;

  return String(avgArt.toFixed(2)).replace('.', ',');
};

export const getAvgSlScore = (
  {sl}: IJudgingSportsmenResponse,
  ignoreEmpty: boolean = false,
) => {
  sl.replace(/,/g, '.');
  return !ignoreEmpty && !sl
    ? ''
    : String((Number(sl.replace(/,/g, '.')) / 2).toFixed(2)).replace('.', ',');
};

export const getAvgDeclineScore = ({element, line, pbs}: any) => {
  if (!element && !line && !pbs) {
    return '0,0';
  }

  return String(
    (
      (Number(element.replace(/,/g, '.')) +
        Number(line.replace(/,/g, '.')) +
        Number(pbs.replace(/,/g, '.'))) *
      -1
    ).toFixed(2),
  ).replace('.', ',');
};

export const getTableDataWithRanks = (
  applications: IJudgingSportsmenResponse[],
) => {
  let currentPlace = 0;

  const sortedApplications = applications
    .map<[IJudgingSportsmenResponse, number]>(
      (application, applicationIndex) => [application, applicationIndex],
    )
    .sort((a, b) => {
      const {avg_isp, avg_art, avg_sl, total} = a[0];
      const {
        avg_isp: avg_isp_b,
        avg_art: avg_art_b,
        avg_sl: avg_sl_b,
        total: total_b,
      } = b[0];

      const totalA = Number(total.replace(/,/g, '.'));
      const totalB = Number(total_b.replace(/,/g, '.'));

      if (totalA > totalB) {
        return -1;
      } else if (totalA < totalB) {
        return 1;
      } else {
        const avgIspA = Number(avg_isp.replace(/,/g, '.'));
        const avgIspB = Number(avg_isp_b.replace(/,/g, '.'));

        if (avgIspA > avgIspB) {
          return -1;
        } else if (avgIspA < avgIspB) {
          return 1;
        } else {
          const avgArtA = Number(avg_art.replace(/,/g, '.'));
          const avgArtB = Number(avg_art_b.replace(/,/g, '.'));

          if (avgArtA > avgArtB) {
            return -1;
          } else if (avgArtA < avgArtB) {
            return 1;
          } else {
            const avgSlA = Number(avg_sl.replace(/,/g, '.'));
            const avgSlB = Number(avg_sl_b.replace(/,/g, '.'));

            if (avgSlA > avgSlB) {
              return -1;
            } else if (avgSlA < avgSlB) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      }
    });

  const sortedApplicationsWithPlaces = sortedApplications
    .map<[IJudgingSportsmenResponse, number]>((item, index) => {
      if (index === 0) {
        currentPlace += 1;
      } else {
        const {avg_isp, avg_art, avg_sl, total} = item[0];
        const {
          avg_isp: avg_isp_prev,
          avg_art: avg_art_prev,
          avg_sl: avg_sl_prev,
          total: total_prev,
        } = sortedApplications[index - 1][0];

        if (
          !(
            total === total_prev &&
            avg_isp === avg_isp_prev &&
            avg_art === avg_art_prev &&
            avg_sl === avg_sl_prev
          )
        ) {
          currentPlace += 1;
        }
      }

      return [{...item[0], place: currentPlace}, item[1]];
    })
    .sort((a, b) => a[1] - b[1])
    .map(item => item[0]);

  return sortedApplicationsWithPlaces;
};
