import React, {FC} from 'react';
import {Button, ButtonProps} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';

import styles from './style.module.css';

interface DeleteButtonProps extends ButtonProps {
  deleteHandler: () => void;
}

export const DeleteButton: FC<DeleteButtonProps> = ({
  deleteHandler,
  ...props
}) => {
  return (
    <Button
      type="text"
      icon={<DeleteOutlined style={{fontSize: 20}} />}
      className={styles.button}
      danger
      onClick={deleteHandler}
      {...props}
    />
  );
};
