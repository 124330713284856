import {api} from './api';

export const confirmMailApi = api.injectEndpoints({
  endpoints: builder => ({
    sendConfirmLink: builder.mutation<any, any>({
      query: () => ({
        url: '/confirm-mail/resend-confirmation-link',
        method: 'POST',
      }),
    }),
    confirmMail: builder.mutation<any, string>({
      query: (token: string) => ({
        url: '/confirm-mail/confirm',
        method: 'POST',
        body: {token},
      }),
    }),
  }),
  overrideExisting: false,
});
