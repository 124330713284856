import {FC, PropsWithChildren, useState} from 'react';
import {Layout, Spin, Typography} from 'antd';

import {JudgingLayoutContext} from './JudgingLyaoutContext';
import styles from './style.module.css';

const {Title} = Typography;

export interface JudgingLayoutProps extends PropsWithChildren {
  title?: string;
}

export const JudgingLayout: FC<JudgingLayoutProps> = ({title, children}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Layout>
      <Spin spinning={isLoading}>
        <div className={styles.wrapper}>
          {title && <Title style={{userSelect: 'none'}}>{title}</Title>}
          <JudgingLayoutContext.Provider
            value={{
              isLoading,
              setIsLoading,
            }}>
            {children}
          </JudgingLayoutContext.Provider>
        </div>
      </Spin>
    </Layout>
  );
};
