import {FC} from 'react';
import {Flex, Typography} from 'antd';
import styles from './style.module.css';

const {Title} = Typography;

interface ScoreInputProps {
  title: string;
  inputClick: () => void;
  activeInput: boolean;
  value: string;
  style?: React.CSSProperties;
}

export const ScoreInput: FC<ScoreInputProps> = ({
  title,
  activeInput,
  value,
  inputClick,
  style,
}) => {
  return (
    <Flex
      align="center"
      justify="center"
      flex={1}
      style={{width: '100%', ...style}}
      onClick={inputClick}>
      <Title
        style={{
          padding: 0,
          margin: 0,
          marginRight: 10,
        }}
        level={4}>
        {title}
      </Title>
      <div
        className={styles.input}
        style={{
          borderColor: activeInput ? '#389e0d' : '#bfbfbf',
        }}>
        <p className={styles.inputText}>{value}</p>
      </div>
    </Flex>
  );
};
