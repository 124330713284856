import {FC, useState} from 'react';
import {
  Button,
  Flex,
  Form,
  Input,
  InputNumber,
  List,
  Select,
  Space,
  Typography,
} from 'antd';
import {
  EditTwoTone,
  DeleteTwoTone,
  CloseCircleTwoTone,
} from '@ant-design/icons';

import {CreateEventNomination} from 'store/api';

interface NominationsListItemProps {
  nomination: CreateEventNomination;
  removeNomination: () => void;
  editNomination: (nomination: CreateEventNomination) => void;
}

const IconText = ({label, text}: {label: string; text: string}) => (
  <Space>
    {label}
    <Typography.Text>{text}</Typography.Text>
  </Space>
);

export const NominationsListItem: FC<NominationsListItemProps> = ({
  nomination,
  removeNomination,
  editNomination,
}) => {
  const [changeNomination, setChangeNomination] = useState<boolean>(false);
  const [nominationForm] = Form.useForm<CreateEventNomination>();

  const setNumberInputMinValue = (field: string) => {
    !nominationForm.getFieldValue(field) &&
      nominationForm.setFieldValue(field, 1);
  };

  const onFinish = () => {
    removeNomination();
    editNomination(nominationForm.getFieldsValue());
    setChangeNomination(false);
  };

  if (changeNomination) {
    return (
      <List.Item
        extra={
          <CloseCircleTwoTone
            style={{fontSize: 16}}
            onClick={() => setChangeNomination(false)}
          />
        }>
        <Form
          labelAlign="right"
          layout="vertical"
          style={{
            width: '100%',
            backgroundColor: '#e6f4ff',
            padding: 8,
            borderRadius: 6,
          }}
          form={nominationForm}
          initialValues={nomination}>
          <Form.Item<CreateEventNomination> name="name">
            <Input placeholder="Название номинации" />
          </Form.Item>

          <Form.Item<CreateEventNomination> name="code">
            <Select
              options={[
                {value: 'IW', label: 'IW'},
                {value: 'MP', label: 'MP'},
              ]}
            />
          </Form.Item>

          <Form.Item<CreateEventNomination>
            name="min"
            label="Мин. кол-во участников">
            <InputNumber
              min={1}
              placeholder="Мин. кол-во участников"
              style={{width: '100%'}}
              onBlur={() => setNumberInputMinValue('min')}
            />
          </Form.Item>

          <Form.Item<CreateEventNomination> name="max">
            <InputNumber
              min={1}
              placeholder="Макс. кол-во участников"
              style={{width: '100%'}}
              onBlur={() => setNumberInputMinValue('max')}
            />
          </Form.Item>

          <Form.Item<CreateEventNomination> name="type">
            <Select
              options={[
                {value: 'individual', label: 'Индивидуальное выступление'},
                {value: 'group', label: 'Групповое выступление'},
              ]}
            />
          </Form.Item>

          <Form.Item<CreateEventNomination> name="gender">
            <Select
              options={[
                {value: 'man', label: 'Муж.'},
                {value: 'woman', label: 'Жен.'},
                {value: 'all', label: 'Смеш.'},
              ]}
            />
          </Form.Item>

          <Form.Item<CreateEventNomination> name="ageFrom">
            <InputNumber
              min={1}
              placeholder="Мин. возраст участников"
              style={{width: '100%'}}
              onBlur={() => setNumberInputMinValue('ageFrom')}
            />
          </Form.Item>

          <Form.Item<CreateEventNomination> name="ageTo">
            <InputNumber
              min={1}
              placeholder="Макс. возраст участников"
              style={{width: '100%'}}
              onBlur={() => setNumberInputMinValue('ageTo')}
            />
          </Form.Item>

          <Form.Item<CreateEventNomination> name="vendorCode">
            <Input placeholder="Код номинации" />
          </Form.Item>

          <Form.Item name="nom">
            <Button type="primary" htmlType="button" onClick={onFinish}>
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </List.Item>
    );
  }

  return (
    <List.Item
      actions={[
        <IconText label="code:" text={String(nomination.code)} />,
        <IconText label="min:" text={String(nomination.min)} />,
        <IconText label="max:" text={String(nomination.max)} />,
        <IconText label="ageFrom:" text={String(nomination.ageFrom)} />,
        <IconText label="ageTo:" text={String(nomination.ageTo)} />,
        <IconText label="vendorCode:" text={String(nomination.vendorCode)} />,
      ]}
      extra={
        <Flex gap={8}>
          <EditTwoTone
            style={{fontSize: 16}}
            onClick={() => {
              setChangeNomination(true);
            }}
          />
          <DeleteTwoTone style={{fontSize: 16}} onClick={removeNomination} />
        </Flex>
      }>
      <Typography.Title level={5}>{nomination.name}</Typography.Title>
    </List.Item>
  );
};
