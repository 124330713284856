import {FC, useCallback, useEffect, useState} from 'react';
import {Flex, Table, TableColumnsType, Typography} from 'antd';

import {
  EventStatisticNominationTableData,
  EventStatisticNominationTableRow,
} from 'store/api';
import {StatisticTableTitle} from '../StatisticTableTitle';
import {getNominationTableSummaryRow} from './getNominationTableSummaryRow';

const {Text} = Typography;

interface EventStatisticNominationTableProps {
  categories: string[];
  data: EventStatisticNominationTableData[];
}

export const EventStatisticNominationTable: FC<
  EventStatisticNominationTableProps
> = ({categories, data}) => {
  const [tableColumns, setTableColumns] = useState<
    TableColumnsType<EventStatisticNominationTableData>
  >([]);

  const renderValue = useCallback(
    (
      record: EventStatisticNominationTableData,
      categoryColumn: string,
      key: keyof EventStatisticNominationTableRow,
    ) => {
      const rankValue = record.data.find(
        rank => rank.category === categoryColumn,
      );

      if (!rankValue) {
        return 0;
      }

      return <Text strong>{rankValue[key]}</Text>;
    },
    [],
  );

  useEffect(() => {
    const columns: TableColumnsType<EventStatisticNominationTableData> =
      categories.map(category => ({
        title: category,
        dataIndex: category,
        key: category,
        align: 'center',
        render: (_, record) => renderValue(record, category, 'count'),
      }));

    setTableColumns([
      {
        title: 'Номинация',
        dataIndex: 'nomination',
        key: 'nomination',
      },
      {
        title: 'Всего',
        dataIndex: 'total',
        key: 'total',
        align: 'center',
        render: text => (
          <Text strong style={{fontSize: 16, color: '#1890ff'}}>
            {text}
          </Text>
        ),
      },
      ...columns,
    ]);
  }, [data]);

  return (
    <Flex vertical gap={8}>
      <StatisticTableTitle title="Номинации" />
      <Table
        dataSource={data}
        columns={tableColumns}
        rowKey={row => row.nomination}
        pagination={false}
        size="small"
        loading={tableColumns.length === 0}
        summary={data => getNominationTableSummaryRow(data)}
      />
    </Flex>
  );
};
