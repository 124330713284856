import React, {FC, ReactNode, useEffect, useState} from 'react';
import {
  CalendarOutlined,
  CompassOutlined,
  BellOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import {Button, Tag} from 'antd';
import {MainCard} from '../../../../components';
import {IEvent} from '../../../../models';
import {statementApi} from '../../../../store/api';
import {useNavigate} from 'react-router-dom';
import cn from 'classnames';

import styles from './style.module.css';

interface EventCardProps {
  event: IEvent;
}

export const EventCard: FC<EventCardProps> = ({event}) => {
  const [selectedEventId, setSelectedEventId] = useState<number | null>(null);
  const navigate = useNavigate();

  const {data: statement, isFetching: isStatementFetching} =
    statementApi.useGetStatementsQuery(selectedEventId, {
      skip: !selectedEventId,
      refetchOnMountOrArgChange: true,
    });

  const createStatementHandler = (eventId: number) => {
    setSelectedEventId(eventId);
  };

  useEffect(() => {
    if (statement) {
      if (statement.items.length > 0) {
        navigate(`/statement/${statement.items[0].id}`);
      } else {
        navigate('/statements/create', {
          state: {eventId: selectedEventId},
        });
      }
    }
  }, [statement]);

  const getEventDate = (event: IEvent): string => {
    const beginDate = dayjs(event.beginDate);
    const endDate = dayjs(event.endDate);

    if (beginDate.diff(endDate) === 0) {
      return `${beginDate.format('DD MMMM YYYY')}`;
    } else {
      return `${beginDate.format('DD')} - ${endDate.format('DD MMMM YYYY')}`;
    }
  };

  const getEventStatus = (deadline: Date): ReactNode => {
    if (dayjs() > dayjs(deadline)) {
      return (
        <Tag className={styles.tag} color="red">
          Прием заявок оконечен
        </Tag>
      );
    } else {
      return (
        <Tag className={styles.tag} color="green">
          Идет регистрация
        </Tag>
      );
    }
  };

  return (
    <MainCard
      bodyStyle={{
        padding: 0,
      }}
      className={styles.card}>
      <div className={styles.cardBody}>
        <img height={'100%'} width={300} alt="example" src={event.image} />

        <div className={styles.content}>
          <div className={styles.header}>
            {getEventStatus(event.deadlineRegistration)}
            <div className={styles.deadlineContainer}>
              <BellOutlined className={styles.deadlineIcon} />
              <span className={styles.deadlineText}>
                {dayjs(event.deadlineRegistration).format('DD MMMM YYYY')}
              </span>
            </div>
          </div>

          <h1 className={styles.title}>{event.title}</h1>

          <div className={styles.footer}>
            <div className={styles.infoContainer}>
              <div className={styles.dateContainer}>
                <CalendarOutlined className={styles.infoIcon} />
                <span className={styles.infoText}>{getEventDate(event)}</span>
              </div>

              <div
                className={cn(styles.dateContainer, styles.locationContainer)}>
                <CompassOutlined className={styles.infoIcon} />
                <span className={styles.infoText}>{event.location}</span>
              </div>
            </div>
          </div>
          {dayjs() < dayjs(event.deadlineRegistration) && (
            <Button
              loading={isStatementFetching}
              onClick={() => createStatementHandler(event.id)}
              className={styles.button}>
              Подать заявку
            </Button>
          )}
        </div>
      </div>
    </MainCard>
  );
};
