import {FC, useEffect, useState} from 'react';
import {Flex, Table, TableColumnsType} from 'antd';

import {EventStatisticCityTableData} from 'store/api';
import {StatisticTableTitle} from '../StatisticTableTitle';
import {getCityTableCategoryColumns} from './getCityTableCategoryColumns';
import {getCityTableSummaryRow} from './getCityTableSummaryRow';

interface EventStatisticCityTableProps {
  categories: string[];
  data: EventStatisticCityTableData[];
}

export const EventStatisticCityTable: FC<EventStatisticCityTableProps> = ({
  categories,
  data,
}) => {
  const [tableColumns, setTableColumns] = useState<
    TableColumnsType<EventStatisticCityTableData>
  >([]);

  useEffect(() => {
    const columns: TableColumnsType<EventStatisticCityTableData> =
      getCityTableCategoryColumns(categories);

    setTableColumns([
      {
        title: 'Город',
        dataIndex: 'city',
        key: 'city',
      },
      ...columns,
    ]);
  }, [data]);

  return (
    <Flex vertical gap={8}>
      <StatisticTableTitle title="Города" />
      <Table
        dataSource={data}
        columns={tableColumns}
        rowKey={row => row.city}
        pagination={false}
        size="small"
        loading={tableColumns.length === 0}
        summary={data => getCityTableSummaryRow(categories, data)}
      />
    </Flex>
  );
};
