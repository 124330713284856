import {Fragment, ReactNode} from 'react';
import {Table, Typography} from 'antd';

import {EventStatisticCityTableData} from 'store/api';

const {Text} = Typography;

const renderCell = (value: number, index: number) => {
  return (
    <Table.Summary.Cell index={index} align="center">
      <Text strong style={{fontSize: 16, color: '#1890ff'}}>
        {value}
      </Text>
    </Table.Summary.Cell>
  );
};

export const getCityTableSummaryRow = (
  categories: string[],
  data: readonly EventStatisticCityTableData[],
): ReactNode => {
  const summaryCategory: {
    [category: string]: {
      mans: number;
      womans: number;
      total: number;
      coach: number;
    };
  } = {};

  data.forEach(({data}) => {
    data.forEach(row => {
      if (!summaryCategory[row.category]) {
        summaryCategory[row.category] = {
          mans: row.man,
          womans: row.woman,
          total: row.totalSportsmensCount,
          coach: row.coachesCount,
        };
      } else {
        summaryCategory[row.category].mans += row.man;
        summaryCategory[row.category].womans += row.woman;
        summaryCategory[row.category].total += row.totalSportsmensCount;
        summaryCategory[row.category].coach += row.coachesCount;
      }
    });
  });

  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0}>
        <Text strong style={{fontSize: 16}}>
          Всего
        </Text>
      </Table.Summary.Cell>
      {Object.entries(summaryCategory).map(
        ([category, {mans, womans, total, coach}]) => (
          <Fragment key={category}>
            {renderCell(mans, categories.indexOf(category) + 1)}
            {renderCell(womans, categories.indexOf(category) + 2)}
            {renderCell(total, categories.indexOf(category) + 3)}
            {renderCell(coach, categories.indexOf(category) + 4)}
          </Fragment>
        ),
      )}
    </Table.Summary.Row>
  );
};
