import {api} from './api';
import {IEvent} from '../../models';
import {IResponse} from './types';

export interface TemplateCategoryResponse {
  id: number;
  age_from: number;
  age_to: number;
  name: string;
}

export interface TemplateNominationResponse {
  id: number;
  name: string;
  code: string;
  type: 'individual' | 'group';
  min: number;
  max: number;
  gender: 'woman' | 'man' | 'all';
  ageFrom: number | null;
  ageTo: number | null;
  vendorCode: string;
}

export interface TemplateResponse {
  categories: TemplateCategoryResponse[];
  nominations: TemplateNominationResponse[];
}

// interface TemplateCategoriesResponse {
//   id: number;
//   // nomination: TemplateNominationResponse;
//   category: TemplateCategoryResponse;
// }

export interface EventNominationResponse extends TemplateNominationResponse {
  categoryName: string;
}

export interface EventResponse {
  id: number;
  title: string;
  published: boolean;
  information: string;
  location: string;
  beginDate: string;
  endDate: string;
  deadlineRegistration: Date;
  state: 'active' | 'completed' | 'judging';
  image: string;
  nominations: EventNominationResponse[];
}

export interface CreateEventNomination {
  name: string;
  code: string;
  type: string;
  gender: string;
  min: number;
  max: number;
  ageFrom: number;
  ageTo: number;
  vendorCode: string;
}

export interface EventCategoryNominations {
  categoryName: string;
  nominations: CreateEventNomination[];
}

export interface CreateEventData {
  title: string;
  published: boolean;
  information: string;
  location: string;
  beginDate: Date;
  endDate: Date;
  startRegistration: Date;
  deadlineRegistration: Date;
  state: 'active' | 'completed' | 'judging' | 'canceled';
  image: string;
  categoriesNominations: EventCategoryNominations[];
}

export const eventApi = api.injectEndpoints({
  endpoints: builder => ({
    getEvents: builder.query<IResponse<IEvent[]>, IEvent['state'] | undefined>({
      query: state => ({
        url: '/event',
        params: {
          state: state,
        },
      }),
      providesTags: () => [{type: 'Event'}],
    }),
    getEvent: builder.query<EventResponse, number>({
      query: (eventId: number) => {
        return {
          url: `/event/${eventId}`,
        };
      },
      providesTags: () => [{type: 'Event'}],
    }),
    getTemplates: builder.query<TemplateResponse, void>({
      query: () => {
        return {
          url: '/event/templates',
        };
      },
    }),
    createEvent: builder.mutation<any, CreateEventData>({
      query: event => ({
        url: '/event/create',
        method: 'POST',
        body: event,
      }),
    }),
  }),
  overrideExisting: false,
});
