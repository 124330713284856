import {FC, useEffect, useState} from 'react';
import {Col, Input, Row, Typography, Table} from 'antd';
import {ColumnsType} from 'antd/es/table';

import {adminApi} from '../../../../store/api';
import {parseArraySportsmens} from '../../../../utils/parseArraySportsmens';
import {useDebounce} from '../../../../hooks';

const {Text} = Typography;
const {Search} = Input;

interface EventApplicationsProps {
  selectedEventId: string;
}

const columns: ColumnsType<any> = [
  {
    title: 'Тренер',
    dataIndex: 'fullname',
    key: 'fullname',
    render: (_, record) => {
      const fullCoachName = `${record.statement.user.firstname} ${record.statement.user.lastname} ${record.statement.user.middlename}`;

      return fullCoachName;
    },
  },
  {
    title: 'Категория',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Номинация',
    dataIndex: 'nomination',
    key: 'nomination',
  },
  {
    title: 'Спортсмены',
    render: (_, record) => {
      const sportsmens = parseArraySportsmens(record.applicationSportsmens);
      return <Text>{sportsmens}</Text>;
    },
  },
];

export const EventApplications: FC<EventApplicationsProps> = ({
  selectedEventId,
}) => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const {data: eventApplications, isFetching: fetchingEventApplications} =
    adminApi.useGetEventApplicationsQuery(
      {eventId: selectedEventId},
      {
        skip: !selectedEventId,
        refetchOnMountOrArgChange: true,
      },
    );
  const [
    searchApplications,
    {data: foundApplications, isFetching: fetchingSearch},
  ] = adminApi.useLazySearchEventApplicationsQuery();

  useEffect(() => {
    if (debouncedSearchTerm.length > 2) {
      onSearch();
    } else {
      setTableData(eventApplications?.items || []);
    }
  }, [debouncedSearchTerm]);

  const onSearch = () => {
    searchApplications({eventId: selectedEventId, q: debouncedSearchTerm});
  };

  useEffect(() => {
    if (eventApplications) {
      setTableData(eventApplications.items);
    }
  }, [eventApplications]);

  useEffect(() => {
    if (foundApplications) {
      setTableData(foundApplications.items);
    }
  }, [foundApplications]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <Search
          value={searchValue}
          size="large"
          allowClear
          placeholder="Поиск"
          onChange={e => setSearchValue(e.target.value)}
          onSearch={onSearch}
          enterButton
          disabled={fetchingEventApplications}
        />
      </Col>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={tableData}
          loading={fetchingEventApplications || fetchingSearch}
        />
      </Col>
    </Row>
  );
};
