import {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from 'react';
import {Alert, Input, InputRef, Modal, ModalProps} from 'antd';

import {IJudgingSportsmenResponse, judgingApi} from 'store/api';

interface ModalSendScoreProps extends ModalProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  judge: string;
  application: IJudgingSportsmenResponse;
  updateDataTable: (score: string) => void;
  scoreValue?: string;
}

export const ModalSendScore: FC<ModalSendScoreProps> = ({
  setOpen,
  judge,
  application,
  updateDataTable,
  scoreValue,
  ...props
}) => {
  const [score, setScore] = useState<string>('');
  const inputRef = useRef<InputRef>(null);

  const [sendScore, {isLoading, isSuccess}] =
    judgingApi.useArbitratorSendScoreMutation();

  useEffect(() => {
    if (props.open) {
      setTimeout(() => {
        inputRef.current!.focus();
      }, 500);

      inputRef.current?.focus();
      setScore(scoreValue ?? '');
    }
  }, [props.open]);

  const sendDecline = () => {
    sendScore({
      sportsmenId: application.id,
      judge,
      score,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      updateDataTable(score);
      setOpen(false);
    }
  }, [isSuccess]);

  const handleCancel = () => {
    setScore('');
    setOpen(false);
  };

  const changeScore = (value: string) => {
    if (value.length === 1) {
      if (score.length === 2) {
        value = '';
      } else {
        value = value + ',';
      }
    } else if (value.length > 3 || (value[1] !== ',' && score.length === 3)) {
      return;
    }

    if (value[0] === '0' && value[2] === '0') {
      return;
    }

    const rgx = /^[0-9]*\,?[0-9]*$/;

    const matchValue = value.match(rgx);

    if (matchValue) {
      setScore(matchValue[0]);
    }
  };

  return (
    <Modal
      title={`Оценка за ${judge}`}
      centered
      destroyOnClose
      onOk={() => sendDecline()}
      onCancel={handleCancel}
      confirmLoading={isLoading}
      cancelText={'Удалить'}
      okText={'Отправить'}
      okButtonProps={{size: 'large'}}
      cancelButtonProps={{
        size: 'large',
        danger: true,
        onClick: () => {
          setScore('');
          sendScore({
            sportsmenId: application.id,
            judge,
            score: '',
          });
        },
        loading: isLoading,
      }}
      {...props}>
      <Alert message={application.name} type="info" />
      <Input
        ref={inputRef}
        disabled={isLoading}
        size="large"
        value={score}
        onChange={e => changeScore(e.target.value)}
        style={{
          fontWeight: 'bold',
          fontSize: 24,
          marginTop: 12,
        }}
        onPressEnter={() => sendDecline()}
      />
    </Modal>
  );
};
