import {Dispatch, FC, SetStateAction} from 'react';
import {InputEnum, IScore} from '../../types';
import {ScoreInput} from './ScoreInput';
import styles from './style.module.css';
import {IJudgeAuthResponse} from '../../../../store/api';

interface InputsProps {
  judge: IJudgeAuthResponse;
  score: IScore;
  activeInput: InputEnum;
  setActiveInput: Dispatch<SetStateAction<InputEnum>>;
}

export const Inputs: FC<InputsProps> = ({
  judge,
  score,
  activeInput,
  setActiveInput,
}) => {
  return (
    <div className={styles.inputsWrapper}>
      <ScoreInput
        title={'Оценка'}
        inputClick={() => setActiveInput(InputEnum.score)}
        activeInput={activeInput === 'score'}
        value={score.score}
      />

      {judge.judge === 'sl' && (
        <ScoreInput
          title={'Сбавка'}
          style={{marginTop: 10}}
          inputClick={() => setActiveInput(InputEnum.decline)}
          activeInput={activeInput === 'decline'}
          value={score.decline}
        />
      )}
    </div>
  );
};
