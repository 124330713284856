import {api} from './api';
import {ISportsmen} from '../../models';
import {IResponse} from './types';

interface IPaginationResponse {
  page?: number;
  per_page?: number;
}

export interface ISportsmensListQueryParams extends IPaginationResponse {
  gender?: string;
  age_from?: number | null;
  age_to?: number | null;
  exclusion_ids?: number[];
}

export const sportsmenApi = api.injectEndpoints({
  endpoints: builder => ({
    getSportsmens: builder.query<
      IResponse<ISportsmen[]>,
      ISportsmensListQueryParams
    >({
      query: params => ({
        url: '/sportsmen',
        params: {
          ...params,
          ...(params?.exclusion_ids && {
            exclusion_ids: `[${params.exclusion_ids}]`,
          }),
        },
      }),
      providesTags: () => [{type: 'Sportsmen'}],
    }),
    createSportsmen: builder.mutation<ISportsmen, ISportsmen>({
      query: (sportsmen: ISportsmen) => ({
        url: '/sportsmen/create',
        method: 'POST',
        body: sportsmen,
      }),
      invalidatesTags: () => [{type: 'Sportsmen'}],
    }),
    updateSportsmen: builder.mutation<ISportsmen, ISportsmen>({
      query: (sportsmen: ISportsmen) => ({
        url: `/sportsmen/${sportsmen.id}`,
        method: 'PATCH',
        body: sportsmen,
      }),
      invalidatesTags: () => [{type: 'Sportsmen'}],
    }),
    deleteSportsmen: builder.mutation<ISportsmen, ISportsmen>({
      query: (sportsmen: ISportsmen) => ({
        url: `/sportsmen/${sportsmen.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Sportsmen'],
    }),
  }),
  overrideExisting: false,
});
