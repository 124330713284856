import {TableColumnsType, Typography} from 'antd';

import {
  EventStatisticCityTableData,
  EventStatisticCityTableRow,
} from 'store/api';

const {Text} = Typography;

const renderValue = (
  record: EventStatisticCityTableData,
  categoryColumn: string,
  key: keyof EventStatisticCityTableRow,
) => {
  const categoryValue = record.data.find(
    row => row.category === categoryColumn,
  );

  if (!categoryValue) {
    return 0;
  }

  return <Text strong>{categoryValue[key]}</Text>;
};

export const getCityTableCategoryColumns = (
  categories: string[],
): TableColumnsType<EventStatisticCityTableData> => {
  return categories.map(category => ({
    title: category,
    dataIndex: category,
    key: category,
    children: [
      {
        title: 'М',
        dataIndex: 'man',
        key: 'man',
        align: 'center',
        render: (_, record) => renderValue(record, category, 'man'),
      },
      {
        title: 'Ж',
        dataIndex: 'woman',
        key: 'woman',
        align: 'center',
        render: (_, record) => renderValue(record, category, 'woman'),
      },
      {
        title: 'Всего',
        dataIndex: 'totalSportsmensCount',
        key: 'totalSportsmensCount',
        align: 'center',
        render: (_, record) =>
          renderValue(record, category, 'totalSportsmensCount'),
      },
      {
        title: 'Тренеры',
        dataIndex: 'coachesCount',
        key: 'coachesCount',
        align: 'center',
        render: (_, record) => renderValue(record, category, 'coachesCount'),
      },
    ],
  }));
};
