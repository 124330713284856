import {useCallback} from 'react';
import {useAppDispatch, useAppSelector} from './hook';
import {judgingApi} from 'store/api';
import {setSelectedApplication} from 'store/reducers';

export type ToggleApplicationType = 'next' | 'prev';

type ToggleApplicationReturn = (type: ToggleApplicationType) => void;

export const useToggleApplication = (): ToggleApplicationReturn => {
  const dispatch = useAppDispatch();

  const selectedApplicationIndex = useAppSelector(
    state => state.judge.selectedApplicationIndex,
  );

  const spreadsheetId = useAppSelector(state => state.judge.spreadsheetId);

  const {data: applicationsResponse} =
    judgingApi.useGetAllJudgingApplicationsQuery();

  return useCallback(
    (type: ToggleApplicationType) => {
      if (!applicationsResponse) {
        return;
      }
      if (
        type === 'next' &&
        selectedApplicationIndex < applicationsResponse.length - 1
      ) {
        dispatch(
          setSelectedApplication({
            application: applicationsResponse[selectedApplicationIndex + 1],
            applicationIndex: selectedApplicationIndex + 1,
          }),
        );
      } else if (type === 'prev' && selectedApplicationIndex > 0) {
        dispatch(
          setSelectedApplication({
            application: applicationsResponse[selectedApplicationIndex - 1],
            applicationIndex: selectedApplicationIndex - 1,
          }),
        );
      }
    },
    [selectedApplicationIndex, spreadsheetId, applicationsResponse],
  );
};
