import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {Button, Flex, message} from 'antd';
import {useNavigate} from 'react-router-dom';
import {ApplicationItem} from '../../../../models';
import {
  CreateApplicationRequest,
  EventResponse,
  statementApi,
} from '../../../../store/api';
import {showErrorApplicationsModal} from '../showErrorApplicationsModal/showErrorApplicationsModal';

interface ApplicationsButtonProps {
  event: EventResponse;
  applications: ApplicationItem[];
  setLoading: Dispatch<SetStateAction<boolean>>;
  statementId?: number;
}

export const ApplicationsButton: FC<ApplicationsButtonProps> = ({
  event,
  applications,
  setLoading,
  statementId,
}) => {
  const navigate = useNavigate();

  const [saveStatement, {data: savedStatement, isLoading: creatingStatement}] =
    statementApi.useCreateStatementMutation();
  const [
    updateStatement,
    {data: updatedStatement, isLoading: updatingStatement},
  ] = statementApi.useUpdateStatementMutation();

  const createStatement = () => {
    const createApplications: CreateApplicationRequest[] = applications.map(
      app => ({
        id: app.id,
        nomination_id: app.nomination.id,
        relation_id: app.relationId,
        sportsmens_ids: app.sportsmens.map(sportsmen => sportsmen.id),
      }),
    );

    const statementData = {
      event: event.id,
      applications: createApplications,
    };

    statementId
      ? updateStatement({
          id: statementId,
          ...statementData,
        })
      : saveStatement(statementData);
  };

  useEffect(() => {
    if (
      (updatedStatement &&
        updatedStatement.applicationsWithError?.length === 0) ||
      (savedStatement && savedStatement?.applicationsWithError?.length === 0)
    ) {
      message.success('Заявка успешно сохранена');

      navigate('/statements');
    } else {
      showErrorApplicationsModal({
        errorApplications:
          updatedStatement?.applicationsWithError ??
          savedStatement?.applicationsWithError,
      });
    }
  }, [updatedStatement, savedStatement]);

  useEffect(() => {
    setLoading(creatingStatement || updatingStatement);
  }, [creatingStatement, updatingStatement]);

  return (
    <Flex justify="end">
      {/* <ErrorApplicationsModal
        errorApplications={
          savedStatement?.applicationsWithError ??
          updatedStatement?.applicationsWithError
        }
      /> */}

      <Button
        size="large"
        type="primary"
        disabled={applications.length === 0}
        onClick={createStatement}>
        Сохранить заявку
      </Button>
    </Flex>
  );
};
