import {api} from '../api';
import {IResponse} from '../types';
import {
  CreateStatementRequest,
  StatementResponse,
  UpdateStatementRequest,
} from './types';

export const statementApi = api.injectEndpoints({
  endpoints: builder => ({
    getStatements: builder.query<IResponse<StatementResponse[]>, any>({
      query: (eventId: number) => ({
        url: `/statement/my`,
        params: {
          event_id: eventId,
        },
      }),
      providesTags: () => [{type: 'Statement'}],
    }),
    getStatement: builder.query<StatementResponse, number>({
      query: (statementId: number) => {
        return {
          url: `/statement/${statementId}`,
        };
      },
      providesTags: () => [{type: 'Statement'}],
    }),
    createStatement: builder.mutation<
      StatementResponse,
      CreateStatementRequest
    >({
      query: statement => ({
        url: '/statement/create',
        method: 'POST',
        body: statement,
      }),
      invalidatesTags: () => [{type: 'Statement'}],
    }),
    updateStatement: builder.mutation<
      StatementResponse,
      UpdateStatementRequest
    >({
      query: statement => ({
        url: `/statement/${statement.id}`,
        method: 'PATCH',
        body: statement,
      }),
      invalidatesTags: () => [{type: 'Statement'}],
    }),
    deleteStatement: builder.mutation<StatementResponse, number>({
      query: statementId => ({
        url: `/statement/${statementId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Statement'],
    }),
  }),
  overrideExisting: false,
});
