import {Tag, TagProps, TagType} from 'antd';
import React, {FC} from 'react';
import styles from './style.module.css';

interface AvarageTagProps extends TagProps {
  value: string;
  type?: 'score' | 'total' | 'place';
}

export const AvarageTag: FC<AvarageTagProps> = ({
  value,
  type = 'score',
  ...props
}) => {
  const getColor = () => {
    if (type === 'total') {
      return 'green';
    } else if (type === 'place') {
      return 'volcano';
    }

    return 'blue';
  };

  return (
    <Tag color={getColor()} className={styles.tag} {...props}>
      {value}
    </Tag>
  );
};
