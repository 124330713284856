import {FC, useEffect, useState} from 'react';
import {JudgingLayout} from 'ui';
import {Card, Descriptions, Flex, Typography} from 'antd';
import {useAppDispatch, useAppSelector, useSocketHook} from '../../../hooks';
import {IJudgingSportsmenResponse} from '../../../store/api';
import {NumbersPad, Inputs} from './components';
import {InputEnum, IScore} from '../types';

import styles from './style.module.css';
import {setShowPreviousScoreData} from 'store/reducers';

const {Text, Paragraph} = Typography;

const initScore: IScore = {
  score: '',
  decline: '',
};

export const InputScoreOld: FC = () => {
  const [score, setScore] = useState<IScore>(initScore);
  const [activeInput, setActiveInput] = useState<InputEnum>(InputEnum.score);

  const dispatch = useAppDispatch();

  const application = useAppSelector(state => state.judge.selectedApplication);
  const judge = useAppSelector(state => state.judge.judge);
  const previousScoreData = useAppSelector(
    state => state.judge.previousScoreData,
  );

  const {connectJudge, isConnected} = useSocketHook();

  useEffect(() => {
    if (judge && !isConnected) {
      connectJudge({
        eventId: judge.eventId,
        brigade: judge.brigade,
      });
    }
  }, [judge, isConnected]);

  useEffect(() => {
    dispatch(setShowPreviousScoreData(null));
  }, []);

  return (
    <JudgingLayout>
      <Flex
        vertical
        style={{
          width: '100%',
        }}
        gap={8}>
        <Card
          style={{
            width: '100%',
          }}>
          <Descriptions size="small" layout="horizontal">
            <Descriptions.Item label="Судья">
              {judge?.judgeName}
            </Descriptions.Item>
            <Descriptions.Item label="Номинация">
              {application?.nomination.name}
            </Descriptions.Item>
          </Descriptions>
        </Card>

        {previousScoreData && (
          <Card size="small" style={{width: '100%'}}>
            <Card.Meta description="Оценки предыдущего выступления" />
            <Text ellipsis strong>
              {previousScoreData.name}
            </Text>
            <Descriptions column={5} size="small" layout="vertical">
              <Descriptions.Item label="Исп">
                <Text strong>{previousScoreData.avg_isp}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Арт">
                <Text strong>{previousScoreData.avg_art}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Сл">
                <Text strong>{previousScoreData.avg_sl}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Сб">
                <Text strong style={{color: 'red'}}>
                  {previousScoreData.avg_decline}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Итог">
                <Text strong>{previousScoreData.total}</Text>
              </Descriptions.Item>
            </Descriptions>
          </Card>
        )}

        {application && judge && (
          <Card style={{width: '100%'}}>
            <Card.Meta
              title={
                <Text style={{fontSize: 22}}>
                  {application?.application.name}
                </Text>
              }
            />
            <Flex vertical align="center" gap={16}>
              <Inputs
                judge={judge}
                score={score}
                activeInput={activeInput}
                setActiveInput={setActiveInput}
              />

              <div className={styles.buttonsContainer}>
                <NumbersPad
                  judge={judge.judge as keyof IJudgingSportsmenResponse}
                  activeInput={activeInput}
                  score={score}
                  setScore={setScore}
                  selectedApplication={application}
                />
              </div>
            </Flex>
          </Card>
        )}
      </Flex>
    </JudgingLayout>
  );
};
