import React, {FC} from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {Provider} from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import {PersistGate} from 'redux-persist/integration/react';
import {persistor, store} from './store/store';
import {
  Admin,
  ConfirmMail,
  CreateEvent,
  CreateStatement,
  Events,
  Groups,
  InputScore,
  Login,
  NewPassword,
  Register,
  ResetPassword,
  SelectCategory,
  SelectEvent,
  SelectJudge,
  SelectSportsmen,
  Sportsmens,
  Statement,
  Statements,
  ViewScore,
} from './pages';
import {useAuth} from './hooks';

import './index.css';
import {Arbitrator} from './pages/Juding/Arbitrator/Arbitrator';
import {UserRole} from './services';
import {
  InputScoreOld,
  SelectCategoryOld,
  SelectEventOld,
  SelectJudgeOld,
  SelectSportsmenOld,
} from './pages/JudingOld';
import {ArbitratorOld} from './pages/JudingOld/ArbitratorOld/ArbitratorOld';

dayjs.locale('ru');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const RolesAuthRoute: FC<{role?: UserRole}> = ({role}) => {
  const {user} = useAuth();

  if (!user) {
    return <Navigate to={'/login'} replace />;
  }

  if (role && !user?.roles.includes(role)) {
    return <Navigate to={'/events'} replace />;
  }

  return <Outlet />;
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <BrowserRouter>
          <Routes>
            <Route path={'login'} element={<Login />} />
            <Route path={'/register'} element={<Register />} />
            <Route path={'/reset-password'} element={<ResetPassword />} />
            <Route path={'/reset-password/:token'} element={<NewPassword />} />
            <Route path={'/confirm-mail'} element={<ConfirmMail />} />
            <Route path={'view'} element={<ViewScore />} />
            <Route path={'judging'} element={<Outlet />}>
              <Route index element={<Navigate to={'select-event'} replace />} />
              <Route path={'select-event'} element={<SelectEventOld />} />
              <Route path={'select-judge'} element={<SelectJudgeOld />} />
              <Route path={'select-category'} element={<SelectCategoryOld />} />
              <Route
                path={'select-sportsmen'}
                element={<SelectSportsmenOld />}
              />
              <Route path={'input-score'} element={<InputScoreOld />} />
              <Route path={'arbitrator'} element={<ArbitratorOld />} />
            </Route>

            <Route path={'judging/new'} element={<Outlet />}>
              <Route index element={<Navigate to={'select-event'} replace />} />
              <Route path={'select-event'} element={<SelectEvent />} />
              <Route path={'select-judge'} element={<SelectJudge />} />
              <Route path={'select-category'} element={<SelectCategory />} />
              <Route path={'select-sportsmen'} element={<SelectSportsmen />} />
              <Route path={'input-score'} element={<InputScore />} />
              <Route path={'arbitrator'} element={<Arbitrator />} />
            </Route>

            <Route path={'/'} element={<RolesAuthRoute />}>
              <Route path={'/'} element={<Navigate to={'/events'} replace />} />
              <Route path={'/events'} element={<Events />} />
              <Route path={'/events/create'} element={<CreateEvent />} />
              <Route path={'/sportsmens'} element={<Sportsmens />} />
              <Route path={'/groups'} element={<Groups />} />
              <Route path={'/statements'} element={<Statements />} />
              <Route
                path={'/statements/create'}
                element={<CreateStatement />}
              />
              <Route path={'/statement/:id'} element={<Statement />} />

              <Route
                path={'/'}
                element={<RolesAuthRoute role={UserRole.Admin} />}>
                <Route path={'/admin'} element={<Admin />} />
              </Route>
            </Route>

            <Route path={'*'} element={<Navigate to={'/events'} />} />
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
