import React, {FC} from 'react';
import {Button, Table} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {IGroupResponse} from '../../../../models';
import {parseArraySportsmens} from '../../../../utils/parseArraySportsmens';
import {DeleteButton} from '../../../../components';

import styles from '../../../../components/GroupsMiniTable/style.module.css';
import {useAppDispatch} from '../../../../hooks';
import {openModal} from '../../../../store/reducers/modalSlice';

const {Column} = Table;

interface GroupsTableProps {
  data: IGroupResponse[];
  deleteGroup: (groupId: IGroupResponse) => void;
}

export const GroupsTable: FC<GroupsTableProps> = ({data, deleteGroup}) => {
  const dispatch = useAppDispatch();

  return (
    <Table
      rowKey={(record: IGroupResponse) => record.id}
      rowClassName={styles.tableRow}
      size="small"
      dataSource={data}
      pagination={false}>
      <Column
        title={'Тип'}
        dataIndex={'type'}
        key={'type'}
        align="center"
        render={(text, record: IGroupResponse) => {
          return (
            <span
              style={{
                fontWeight: '600',
              }}>
              {record.type}
            </span>
          );
        }}
      />
      <Column
        title={'Состав'}
        dataIndex={'members'}
        key={'members'}
        render={(text, record: IGroupResponse) => {
          return (
            <span
              style={{
                fontWeight: '600',
              }}>
              {parseArraySportsmens(record.sportsmens)}
            </span>
          );
        }}
      />
      <Column
        title={'Действия'}
        key={'actions'}
        align="center"
        render={(text, record: IGroupResponse) => {
          return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Button
                icon={<EditOutlined style={{fontSize: 20}} />}
                type="link"
                onClick={() => {
                  dispatch(
                    openModal({
                      modal: 'groupModal',
                      modalData: {
                        groupId: record.id,
                      },
                    }),
                  );
                }}
              />
              <DeleteButton
                type="link"
                deleteHandler={() => deleteGroup(record)}
              />
            </div>
          );
        }}
      />
    </Table>
  );
};
