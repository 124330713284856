export const ranks = {
  no_rank: 'Нет разряда',
  youth_1: '1-й юношеский',
  youth_2: '2-й юношеский',
  youth_3: '3-й юношеский',
  big_1: '1-й взрослый',
  big_2: '2-й взрослый',
  big_3: '3-й взрослый',
  ms: 'МС',
  kms: 'КМС',
};

export const genders = {
  man: 'муж.',
  woman: 'жен.',
};
