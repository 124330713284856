import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface ModalState {
  sportsmenModal: boolean;
  updateSportsmenModal: boolean;
  groupModal: boolean;
  modalData?: any;
}

const initialState: ModalState = {
  sportsmenModal: false,
  updateSportsmenModal: false,
  groupModal: false,
  modalData: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{modal: keyof ModalState; modalData?: any}>,
    ) => {
      state[action.payload.modal] = true;
      state.modalData = action.payload.modalData;
    },
    closeModals: state => {
      for (const key of Object.keys(state) as Array<keyof ModalState>) {
        state[key] = false;
      }
      state.modalData = null;
    },
  },
  extraReducers: {},
});

export default modalSlice.reducer;

export const {openModal, closeModals} = modalSlice.actions;
