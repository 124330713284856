import React, {FC} from 'react';
import {List} from 'antd';
import {DeleteButton} from '../../../../components';
import {parseArraySportsmens} from '../../../../utils/parseArraySportsmens';
import {ApplicationItem} from '../../../../models';

interface ListApplicationsProps {
  applications: ApplicationItem[];
  deleteApplication: (nominationId: number, relationId: number) => void;
}

export const ListApplications: FC<ListApplicationsProps> = ({
  applications,
  deleteApplication,
}) => {
  return (
    <List
      size={'small'}
      bordered
      itemLayout="horizontal"
      dataSource={applications}
      renderItem={item => (
        <List.Item
          actions={[
            <DeleteButton
              deleteHandler={() =>
                deleteApplication(item.nomination.id, item.relationId)
              }
            />,
          ]}>
          <List.Item.Meta title={parseArraySportsmens(item.sportsmens)} />
        </List.Item>
      )}
    />
  );
};
