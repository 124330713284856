import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {Layout, Typography} from 'antd';
import {SideBar} from '../SideBar/SideBar';
import {CustomFooter} from '../CustomFooter/CustomFooter';
import {useAuth} from '../../hooks';

import styles from './style.module.css';
import {ConfirmMailOverlay} from './ConfirmMailOverlay';

const {Header, Content} = Layout;
const {Title} = Typography;

interface MainLayoutProps extends PropsWithChildren {
  title: string;
}

export const MainLayout: FC<MainLayoutProps> = ({title, children}) => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const {user} = useAuth();

  return (
    <Layout hasSider>
      {width <= 10 ? (
        <div
          style={{
            backgroundColor: '#feffe6',
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 9,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 20,
          }}>
          <Title
            level={2}
            style={{textAlign: 'center', fontSize: 24, color: '#262626'}}>
            К сожалению в данный момент мобильная версия сайта не доступна :(
          </Title>
        </div>
      ) : (
        <>
          <SideBar user={user} />
          <Layout className={styles.siteLayout}>
            <Header
              style={{
                backgroundColor: 'transparent',
                padding: 0,
                marginTop: 10,
              }}>
              <Title level={2}>{title}</Title>
            </Header>
            <Content style={{position: 'relative'}}>
              {!user?.mailConfirmed && <ConfirmMailOverlay />}
              {children}
            </Content>
            <CustomFooter />
          </Layout>
        </>
      )}
    </Layout>
  );
};
