import {FC, useState} from 'react';
import {Flex, Form, FormListFieldData, Input, Select} from 'antd';
import {CloseCircleTwoTone, EditTwoTone} from '@ant-design/icons';

import {TemplateCategoryResponse} from 'store/api';

interface AddEventSelectCategoryProps {
  categories: TemplateCategoryResponse[];
  field: FormListFieldData;
}

export const AddEventSelectCategory: FC<AddEventSelectCategoryProps> = ({
  field,
  categories,
}) => {
  const [editCategory, setEditCategory] = useState<boolean>(false);

  const onCancel = () => {
    setEditCategory(false);
  };

  return (
    <Flex align="center" style={{width: '100%'}}>
      <>
        <Form.Item noStyle preserve={false} name={[field.name, 'categoryName']}>
          {editCategory ? (
            <Input placeholder="Введите название категории" />
          ) : (
            <Select
              style={{width: '100%'}}
              placeholder="Выберите категорию"
              options={categories.map(({name}) => ({
                label: name,
                value: name,
              }))}
            />
          )}
        </Form.Item>

        {editCategory ? (
          <CloseCircleTwoTone
            style={{fontSize: 24, marginLeft: 8}}
            onClick={onCancel}
          />
        ) : (
          <EditTwoTone
            style={{fontSize: 24, marginLeft: 8}}
            onClick={() => setEditCategory(true)}
          />
        )}
      </>
    </Flex>
  );
};
