import {createAsyncThunk} from '@reduxjs/toolkit';
import {AuthService, IAuthData} from '../../services/auth.service';
import {ILoginData, IRegisterData} from '../../pages/Auth/auth.interface';
import {message} from 'antd';

export const login = createAsyncThunk<IAuthData, ILoginData>(
  'auth/login',
  async ({email, password}, thunkAPI) => {
    try {
      const response = await AuthService.login(email, password);

      if (response.user) {
        message.success(
          'Добро пожаловать ' + response.user.firstname.toUpperCase(),
        );
      }
      return response;
    } catch (err: any) {
      if (!err.response) {
        message.error('Ошибка сервера');
        return thunkAPI.rejectWithValue('Ошибка сервера');
      }

      message.error(err.response.data.message);
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const register = createAsyncThunk<IAuthData, IRegisterData>(
  'auth/register',
  async (data, thunkAPI) => {
    try {
      const response = await AuthService.register(data);

      if (response.user) {
        message.success(
          'Добро пожаловать ' + response.user.firstname.toUpperCase(),
        );
      }
      return response;
    } catch (err: any) {
      if (!err.response) {
        message.error('Ошибка сервера');
        return thunkAPI.rejectWithValue('Ошибка сервера');
      }

      message.error(err.response.data.message);
      return thunkAPI.rejectWithValue(err.response.data.message);
    }
  },
);

export const forgotPassword = createAsyncThunk<boolean, string>(
  'forgot-password',
  async (data, thunkAPI) => {
    try {
      const response = await AuthService.forgotPassword(data);

      if (response) {
        message.success('Проверьте Вашу электронную почту');
      }
      return response;
    } catch (err: any) {
      if (!err.response) {
        message.error('Ошибка сервера');
        return thunkAPI.rejectWithValue('Ошибка сервера');
      }

      message.error(err.response.data.message);
      return thunkAPI.rejectWithValue(err.response.data.message);
    }
  },
);

export const resetPassword = createAsyncThunk<boolean, any>(
  'reset-password',
  async (data, thunkAPI) => {
    try {
      const response = await AuthService.resetPassword(data);

      if (response) {
        message.success('Пароль успешно изменен');
      }
      return response;
    } catch (err: any) {
      if (!err.response) {
        message.error('Ошибка сервера');
        return thunkAPI.rejectWithValue('Ошибка сервера');
      }

      message.error(err.response.data.message);
      return thunkAPI.rejectWithValue(err.response.data.message);
    }
  },
);
