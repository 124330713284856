import {Modal, ModalFuncProps} from 'antd';
import {InfoCircleTwoTone} from '@ant-design/icons';

import {ErrorApplicationItem} from './ErrorApplicationItem/ErrorApplicationItem';
import {IApplicationError} from 'models';

interface ErrorApplicationsModalProps {
  errorApplications?: IApplicationError[];
}

export const showErrorApplicationsModal = ({
  errorApplications,
}: ErrorApplicationsModalProps) => {
  const config: ModalFuncProps = {
    title: 'Заявки с ошибками, которые НЕ были созданы',
    centered: true,
    width: '60%',
    footer: null,
    closable: true,
    icon: <InfoCircleTwoTone />,
    content: errorApplications?.map((application, index) => (
      <ErrorApplicationItem
        key={application.relationId}
        application={application}
        index={index + 1}
      />
    )),
  };

  if (errorApplications && errorApplications.length > 0) {
    Modal.info(config);
  }
};
