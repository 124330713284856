import {IUser} from '../../models/IUser';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface UserState {
  user: IUser;
  accessToken: string;
  isLoading: boolean;
  error: string;
}

interface UserWithToken {
  user: IUser;
  accessToken: string;
}

const initialState: UserState = {
  user: {
    id: 0,
    firstname: '',
    lastname: '',
    middlename: '',
    email: '',
  },
  isLoading: false,
  accessToken: '',
  error: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser: (state, action: PayloadAction<UserWithToken>) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
    },
  },
  extraReducers: {},
});

export default userSlice.reducer;
