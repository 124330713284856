import {FC, useCallback, useEffect, useState} from 'react';
import {Flex, Table, TableColumnsType, Typography} from 'antd';

import {
  EventStatisticRankTableData,
  EventStatisticRankTableRow,
} from 'store/api';
import {StatisticTableTitle} from '../StatisticTableTitle';

const {Text} = Typography;

interface EventStatisticRankTableProps {
  categories: string[];
  data: EventStatisticRankTableData[];
}

export const EventStatisticRankTable: FC<EventStatisticRankTableProps> = ({
  categories,
  data,
}) => {
  const [tableColumns, setTableColumns] = useState<
    TableColumnsType<EventStatisticRankTableData>
  >([]);

  const renderValue = useCallback(
    (
      record: EventStatisticRankTableData,
      categoryColumn: string,
      key: keyof EventStatisticRankTableRow,
    ) => {
      const rankValue = record.data.find(
        rank => rank.category === categoryColumn,
      );

      if (!rankValue) {
        return 0;
      }

      return <Text strong>{rankValue[key]}</Text>;
    },
    [],
  );

  useEffect(() => {
    const columns: TableColumnsType<EventStatisticRankTableData> =
      categories.map(category => ({
        title: category,
        dataIndex: category,
        key: category,
        children: [
          {
            title: 'М',
            dataIndex: 'man',
            key: 'man',
            align: 'center',
            render: (_, record) => renderValue(record, category, 'man'),
          },
          {
            title: 'Ж',
            dataIndex: 'woman',
            key: 'woman',
            align: 'center',
            render: (_, record) => renderValue(record, category, 'woman'),
          },
        ],
      }));

    setTableColumns([
      {
        title: 'Разряд',
        dataIndex: 'rank',
        key: 'rank',
        render: (_, record) => <Text>{record.rankLabel}</Text>,
      },
      {
        title: 'Всего',
        dataIndex: 'total',
        key: 'total',
        align: 'center',
        render: text => (
          <Text strong style={{fontSize: 16, color: '#1890ff'}}>
            {text}
          </Text>
        ),
      },
      ...columns,
    ]);
  }, [data]);

  return (
    <Flex vertical gap={8}>
      <StatisticTableTitle title="Разряды" />
      <Table
        dataSource={data}
        columns={tableColumns}
        rowKey={row => row.rank}
        pagination={false}
        size="small"
        loading={tableColumns.length === 0}
      />
    </Flex>
  );
};
