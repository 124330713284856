import React, {useEffect, useState} from 'react';
import {groupApi} from '../../../../store/api';
import {AddGroupModal} from '../AddGroupModal/AddGroupModal';
import {IGroupResponse} from '../../../../models';
import {Header} from '../Header/Header';
import {GroupsTable} from '../GroupsTable/GroupsTable';
import {parseArraySportsmens} from '../../../../utils/parseArraySportsmens';
import {showDeleteConfirm} from '../../../../components/ConfirmDeleteModal/ConfirmDeleteModal';

export const GroupList = ({setLoading}: any) => {
  const [filteredGroups, setFilteredGroups] = useState<IGroupResponse[] | []>(
    [],
  );

  const {data: groups, isFetching} = groupApi.useGetGroupsQuery('', {
    refetchOnMountOrArgChange: true,
  });
  const [deleteGroup] = groupApi.useDeleteGroupMutation();

  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (groups) {
      setFilteredGroups(groups.items);
    }
  }, [groups]);

  const deleteGroupHandler = (group: IGroupResponse) => {
    const content = (
      <>
        <p>
          Тип: <span style={{fontWeight: 600}}>{group.type}</span>
        </p>
        <p>
          Состав:{' '}
          <span style={{fontWeight: 600}}>
            {parseArraySportsmens(group.sportsmens)}
          </span>
        </p>
      </>
    );

    showDeleteConfirm(
      'Вы уверены что хотите удалить группу ?',
      () => deleteGroup(group.id),
      content,
    );
  };

  return (
    <>
      <AddGroupModal />
      <Header
        groups={groups?.items || []}
        setFilteredGroups={setFilteredGroups}
      />
      <GroupsTable data={filteredGroups} deleteGroup={deleteGroupHandler} />
    </>
  );
};
