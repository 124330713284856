import React, {useEffect} from 'react';
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Select,
} from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/ru_RU';
import 'dayjs/locale/ru';
import {ranks} from '../../../../enums';
import {ISportsmen} from '../../../../models';
import {sportsmenApi} from '../../../../store/api';

import styles from './style.module.css';
import {useAppDispatch, useAppSelector} from '../../../../hooks';
import {closeModals} from '../../../../store/reducers/modalSlice';

export const UpdateSportsmenModal = () => {
  const {updateSportsmenModal, modalData: sportsmen} = useAppSelector(
    state => state.modal,
  );

  const dispatch = useAppDispatch();

  const [updateSportsmen, {isLoading, isSuccess}] =
    sportsmenApi.useUpdateSportsmenMutation();

  const onFinish = async (values: any) => {
    const newSportsmen: ISportsmen = {
      ...values,
      id: sportsmen.id,
      birthdate: Number(values['birthdate'].format('X')),
    };
    await updateSportsmen(newSportsmen);
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Спортсмен успешно отредактирован');
      dispatch(closeModals());
    }
  }, [isSuccess]);

  if (!sportsmen) {
    return null;
  }

  return (
    <Modal
      title={`Редактирование: ${
        sportsmen.lastname
      } ${sportsmen.firstname.charAt(0)}. ${sportsmen.middlename.charAt(0)}.`}
      centered={true}
      open={updateSportsmenModal}
      destroyOnClose={true}
      onOk={onFinish}
      footer={null}
      onCancel={() => {
        dispatch(closeModals());
      }}>
      <Form
        preserve={false}
        initialValues={{
          ...sportsmen,
          birthdate: sportsmen?.birthdate && dayjs(sportsmen.birthdate),
        }}
        disabled={false}
        name="editSportsmen"
        onFinish={onFinish}
        autoComplete="off">
        <Form.Item
          name="lastname"
          validateFirst={true}
          rules={[
            {required: true, min: 3, message: 'Обязательное поле'},
            {
              pattern: new RegExp(/^[а-я]+$/i),
              message: 'Введите корректное значение',
            },
          ]}>
          <Input placeholder={'Фамилия'} className={styles.input} />
        </Form.Item>
        <Form.Item
          name="firstname"
          validateFirst={true}
          rules={[
            {required: true, min: 3, message: 'Обязательное поле'},
            {
              pattern: new RegExp(/^[а-я]+$/i),
              message: 'Введите корректное значение',
            },
          ]}>
          <Input placeholder={'Имя'} className={styles.input} />
        </Form.Item>
        <Form.Item
          name="middlename"
          validateFirst={true}
          rules={[
            {required: true, min: 3, message: 'Обязательное поле'},
            {
              pattern: new RegExp(/^[а-я]+$/i),
              message: 'Введите корректное значение',
            },
          ]}>
          <Input placeholder={'Отчество'} className={styles.input} />
        </Form.Item>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Form.Item
            name="birthdate"
            rules={[{required: true, message: 'Обязательное поле'}]}>
            <DatePicker
              inputReadOnly
              locale={locale}
              format="DD.MM.YYYY"
              placeholder="01.01.2000"
              style={{minWidth: 200}}
              className={styles.input}
            />
          </Form.Item>

          <Form.Item
            name="gender"
            label="Пол"
            rules={[{required: true, message: 'Обязательное поле'}]}>
            <Radio.Group>
              <Radio value="woman">Женский</Radio>
              <Radio value="man">Мужской</Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <Form.Item
          name="rank"
          label="Разряд"
          rules={[{required: true, message: 'Обязательное поле'}]}>
          <Select
            options={Object.entries(ranks).map(rank => ({
              label: rank[1],
              value: rank[0],
            }))}
          />
        </Form.Item>

        <div style={{display: 'flex', justifyContent: 'space-around'}}>
          <Button
            size="large"
            onClick={e => {
              e.preventDefault();
              dispatch(closeModals());
            }}
            type="text"
            htmlType="submit">
            Отменить
          </Button>

          <Form.Item>
            <Button
              loading={isLoading}
              size="large"
              type="primary"
              htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
