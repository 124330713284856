import {NewScore} from '@pages/Juding/Arbitrator/types';
import {
  getAvgArtScore,
  getAvgDeclineScore,
  getAvgIspScore,
  getAvgSlScore,
} from './getPlaces';
import {IJudgingSportsmenResponse} from 'store/api';

interface getNewApplicationsScoresParams {
  newScore: Omit<NewScore, 'spreadsheetId' | 'categoryId' | 'nomination'>;
  dataTable: IJudgingSportsmenResponse[];
  judgingMode: 4 | 6;
}

export const getNewApplicationsScores: (
  params: getNewApplicationsScoresParams,
) => IJudgingSportsmenResponse[] = ({newScore, dataTable, judgingMode}) => {
  const {judgeType, applicationId, judges, score, element} = newScore;

  if (judgeType === 'viewed') {
    return dataTable.map(application => {
      if (application.id === applicationId) {
        return {...application, viewed: '1'};
      } else {
        return application;
      }
    });
  } else {
    const editedDataTable = dataTable.map(app => {
      if (app.id === applicationId) {
        let foundApplication = app;
        judges.forEach(judge => {
          foundApplication = {
            ...app,
            [judge]: score,
            ...(element && {element}),
          };
        });

        const {avg_isp, avg_art, avg_sl, avg_decline} = foundApplication;

        foundApplication.avg_isp =
          judgeType === 'isp'
            ? getAvgIspScore(foundApplication, judgingMode)
            : avg_isp;
        foundApplication.avg_art =
          judgeType === 'art'
            ? getAvgArtScore(foundApplication, judgingMode)
            : avg_art;
        foundApplication.avg_sl =
          judgeType === 'sl' ? getAvgSlScore(foundApplication) : avg_sl;
        foundApplication.avg_decline =
          judgeType === 'arb' || judgeType === 'sl'
            ? getAvgDeclineScore(foundApplication)
            : avg_decline;

        const avgSummary =
          Number(foundApplication.avg_isp.replace(/,/g, '.')) +
          Number(foundApplication.avg_art.replace(/,/g, '.')) +
          Number(foundApplication.avg_sl.replace(/,/g, '.')) +
          Number(foundApplication.avg_decline.replace(/,/g, '.'));

        foundApplication.total =
          avgSummary === 0 ? '' : avgSummary.toFixed(2).replace('.', ',');

        return foundApplication;
      } else {
        return app;
      }
    });

    return editedDataTable;
  }
};

interface RecalculateAVGByJudgesCountParams {
  dataTable: IJudgingSportsmenResponse[];
  judgingMode: 4 | 6;
}

export const calculateAVGByJudgesCount = ({
  dataTable,
  judgingMode,
}: RecalculateAVGByJudgesCountParams) => {
  const newDataTable = dataTable.map(app => {
    const application = {...app};
    application.avg_isp = getAvgIspScore(application, judgingMode);
    application.avg_art = getAvgArtScore(application, judgingMode);
    application.avg_sl = getAvgSlScore(application);
    application.avg_decline = getAvgDeclineScore(application);

    const avgSummary =
      Number(application.avg_isp.replace(/,/g, '.')) +
      Number(application.avg_art.replace(/,/g, '.')) +
      Number(application.avg_sl.replace(/,/g, '.')) +
      Number(application.avg_decline.replace(/,/g, '.'));

    application.total =
      avgSummary === 0 ? '' : avgSummary.toFixed(2).replace('.', ',');

    return application;
  });

  return newDataTable;
};
