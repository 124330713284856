import React, {FC, useEffect, useState} from 'react';
import {Button, Form, Input} from 'antd';
import classNames from 'classnames';
import {useNavigate, Link} from 'react-router-dom';

import {MainCard} from '../../../components';
import {useAppDispatch, useAuth} from '../../../hooks';
import {login} from '../../../store/auth/auth.actions';
import {ILoginData} from '../auth.interface';

import styles from './login.module.css';
import {AuthLayout} from '../../../ui';

export const Login: FC = () => {
  const [isLoginForm, setIsLoginForm] = useState<boolean>(true);
  const {isLoading, user} = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (values: ILoginData) => {
    dispatch(login(values));
  };

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  return (
    <AuthLayout>
      <div>
        <MainCard title={isLoginForm ? 'Авторизация' : 'Регистрация'}>
          {user && <h1>{user.firstname}</h1>}
          <Form
            disabled={isLoading}
            name="login"
            onFinish={onFinish}
            onFinishFailed={() => {}}
            autoComplete="off"
            className={styles.form}>
            <Form.Item
              name="email"
              className={styles.form_input_email}
              rules={[
                {required: true, type: 'email', message: 'Некорректный Email'},
              ]}>
              <Input placeholder={'Email'} className={styles.input} />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{required: true, message: 'Введите пароль'}]}>
              <Input.Password
                className={classNames(styles.input, styles.password)}
                placeholder={'Password'}
              />
            </Form.Item>

            <Form.Item>
              <Button
                className={styles.button}
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={isLoading}>
                Войти
              </Button>
            </Form.Item>
          </Form>

          <Link
            style={{fontSize: 16}}
            onClick={() => setIsLoginForm(false)}
            to={'/reset-password'}>
            Забыли пароль?
          </Link>
        </MainCard>
        <div style={{marginTop: 30, display: 'flex', justifyContent: 'center'}}>
          <Link
            style={{fontSize: 16}}
            onClick={() => setIsLoginForm(false)}
            to={'/register'}>
            Зарегистрироваться
          </Link>
        </div>
        <div style={{marginTop: 30, display: 'flex', justifyContent: 'center'}}>
          <Link
            style={{fontSize: 16}}
            onClick={() => setIsLoginForm(false)}
            to={'/judging'}>
            Судейство
          </Link>
        </div>
      </div>
    </AuthLayout>
  );
};
