import {useEffect, useState} from 'react';
import classNames from 'classnames';
import Marquee from 'react-fast-marquee';
import {Typography} from 'antd';

import {IViewScore} from '../../models/IScore';
import {useSocketHook} from 'hooks';

import style from './style.module.css';

const {Paragraph} = Typography;

export const ViewScore = () => {
  const [viewScore, setViewScore] = useState<IViewScore>({
    nomination: '',
    city: '',
    name: '',
    avg_isp: '',
    avg_art: '',
    avg_sl: '',
    avg_decline: '',
    total: '',
    place: '',
  });
  const [imageVisible, setImageVisible] = useState(true);

  const {socket, connect} = useSocketHook();

  useEffect(() => {
    connect();
  }, []);

  useEffect(() => {
    console.log(socket);
    if (socket) {
      socket.on('getViewScore', newViewScore => {
        console.log(newViewScore);
        setViewScore(newViewScore);
        setImageVisible(false);
      });
      socket.on('showImage', () => {
        setImageVisible(true);
      });
    }
  }, [socket]);

  if (imageVisible) {
    return (
      <img
        style={{height: '99vh', width: '100%'}}
        src={require('../../assets/img/ViewScoreImage.png')}
        alt="background"
      />
    );
  }

  if (!viewScore.total) {
    return null;
  }

  return (
    <div className={style.page}>
      <div key={viewScore.name} className={style.animatedContainer}>
        <div className={classNames(style.headerContainer)}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}>
            <Paragraph
              style={{margin: 0}}
              ellipsis={{rows: 1}}
              className={classNames([style.text, style.nomination])}>
              {viewScore.nomination?.toUpperCase()}
            </Paragraph>
            <Paragraph
              style={{margin: 0}}
              ellipsis={{rows: 1}}
              className={classNames([style.text, style.address])}>
              {viewScore.city?.toUpperCase()}
            </Paragraph>
          </div>
          {viewScore.name.split(',').length > 1 ? (
            <Marquee key={viewScore.name} speed={150} delay={3}>
              <div className={style.nameContainer}>
                <span className={style.name}>{viewScore.name}</span>
              </div>
            </Marquee>
          ) : (
            <Paragraph
              style={{margin: 0}}
              ellipsis={{rows: 2}}
              className={classNames([style.name])}>
              {viewScore.name}
            </Paragraph>
          )}
        </div>
        <div key={viewScore.name} className={style.test}>
          <div className={style.scoresContainer}>
            <div className={style.scoreContainer}>
              <span>E</span>
              <span className={style.scoreText}>{viewScore.avg_isp}</span>
            </div>
            <div className={style.scoreContainer}>
              <span>A</span>
              <span className={style.scoreText}>{viewScore.avg_art}</span>
            </div>
            {viewScore.avg_sl && (
              <div className={style.scoreContainer}>
                <span>D</span>
                <span className={style.scoreText}>{viewScore.avg_sl}</span>
              </div>
            )}
            <div className={style.scoreContainer}>
              <span>DD</span>
              <span
                className={classNames([
                  style.scoreText,
                  viewScore.avg_decline !== '0,00' && style.declineText,
                ])}>
                {viewScore.avg_decline}
              </span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              marginTop: 10,
            }}>
            <div
              className={classNames(
                style.scoreContainer,
                style.finalScoreContainer,
              )}>
              <span>SCORE</span>
              <span className={classNames(style.scoreText, style.finalScore)}>
                {viewScore.total}
              </span>
            </div>
            <div
              className={classNames(
                style.scoreContainer,
                style.finalScoreContainer,
              )}>
              <span>PLACE</span>
              <span className={classNames(style.scoreText, style.finalScore)}>
                {viewScore.place}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
