import React, {useState, useEffect, FC} from 'react';
import {message, Select, Spin} from 'antd';
import {AuthService} from '../../../../../services/auth.service';
import classNames from 'classnames';
import {useDebounce} from '../../../../../hooks';

import styles from './selectCity.module.css';

interface IOption {
  value: string;
  city: string;
  area?: string;
  region: string;
}

interface SelectCityProps {
  onSelect: (values: any) => void;
}

export const SelectCity: FC<SelectCityProps> = ({onSelect}) => {
  const [value, setValue] = useState<string>('');
  const [selected, setSelected] = useState<boolean>(false);
  const [citiesList, setCitiesList] = useState<IOption[]>([]);
  const [isLoadingCities, setIsLoadingCities] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const getCities = async (q: string = '') => {
    setCitiesList([]);
    setIsLoadingCities(true);
    setDropdownOpen(true);
    try {
      const response = await AuthService.getCities(q);

      if (response.data) {
        const newList = response.data.response.items.map((item: any) => ({
          value: item.id,
          city: item.title,
          area: item.area,
          region: item.region,
          label: item.city,
        }));

        setCitiesList(newList);
        setDropdownOpen(true);
      }
    } catch (e: any) {
      message.error(e.message);
    } finally {
      setIsLoadingCities(false);
    }
  };

  const debouncedSearchTerm = useDebounce(value, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length > 2) {
      if (!selected) {
        getCities(debouncedSearchTerm);
      }
    } else {
      onSelect({location: undefined});
      setCitiesList([]);
    }
  }, [debouncedSearchTerm]);

  return (
    <Select
      optionLabelProp="children"
      size="large"
      showSearch
      style={{fontSize: 36, fontWeight: 'bold', width: '100%'}}
      open={dropdownOpen}
      placeholder="Выберите город"
      notFoundContent={isLoadingCities ? <Spin size="small" /> : null}
      onSearch={v => {
        setValue(v);
        setSelected(false);
      }}
      filterOption={false}
      allowClear
      onSelect={(v: any) => {
        const item = citiesList.find(item => item.value === v) ?? {
          city: '',
          region: '',
        };
        onSelect({city: item.city, region: item.region ?? item.city});
        setSelected(true);
        setValue(item.city);
        setDropdownOpen(false);
      }}>
      {citiesList.map(item => (
        <Select.Option key={item.value} value={item.value}>
          <div className={classNames(styles['optionContainer'])}>
            <p
              style={{
                padding: 0,
                margin: 0,
                fontSize: 18,
                fontWeight: 500,
                color: 'black',
              }}>
              {item.city}
            </p>
            <p style={{padding: 0, margin: 0, fontSize: 14}}>{item.area}</p>
            <p style={{padding: 0, margin: 0, fontSize: 14}}>{item.region}</p>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};
