import React, {FC, useEffect} from 'react';
import {Form, Input, Button} from 'antd';
import {Link, useNavigate} from 'react-router-dom';

import {AuthLayout} from '../../ui';
import {MainCard} from '../../components';
import {useAppDispatch, useAuth} from '../../hooks';
import {forgotPassword} from '../../store/auth/auth.actions';

import styles from './style.module.css';

export const ResetPassword: FC = () => {
  const {isLoading, user} = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    dispatch(forgotPassword(values.email));
  };

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  return (
    <AuthLayout>
      <MainCard title={'Сброс пароля'}>
        <Form
          form={form}
          disabled={isLoading}
          name="login"
          onFinish={onFinish}
          autoComplete="off"
          className={styles.form}>
          <p>Укажите почту для сброса пароля</p>
          <Form.Item
            name="email"
            className={styles.form_input_email}
            rules={[
              {required: true, type: 'email', message: 'Некорректный Email'},
            ]}>
            <Input placeholder={'Email'} className={styles.input} />
          </Form.Item>
          <Form.Item>
            <Button
              className={styles.button}
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}>
              Отправить
            </Button>
          </Form.Item>

          <div
            style={{display: 'flex', justifyContent: 'center', fontSize: 16}}>
            <Link to={'/login'}>Вход</Link>
          </div>
        </Form>
      </MainCard>
    </AuthLayout>
  );
};
