import React, {FC} from 'react';
import {Table} from 'antd';
import {IGroupResponse} from '../../models';
import {parseArraySportsmens} from '../../utils/parseArraySportsmens';

import styles from './style.module.css';

const {Column} = Table;

interface GroupsMiniTableProps {
  data: IGroupResponse[];
  rowHandler: (group: IGroupResponse) => void;
  loading: boolean;
}

export const GroupsMiniTable: FC<GroupsMiniTableProps> = ({
  data,
  rowHandler,
  loading,
}) => {
  return (
    <Table
      rowKey={(record: IGroupResponse) => record.id}
      onRow={(record: IGroupResponse) => {
        return {
          onClick: () => rowHandler(record),
        };
      }}
      rowClassName={styles.tableRow}
      size="small"
      dataSource={data}
      loading={loading}
      pagination={false}>
      <Column
        title={'Тип'}
        dataIndex={'type'}
        key={'type'}
        render={(text, record: IGroupResponse) => {
          return (
            <span
              style={{
                fontWeight: '600',
              }}>
              {record.type}
            </span>
          );
        }}
      />
      <Column
        title={'Состав'}
        dataIndex={'members'}
        key={'members'}
        render={(text, record: IGroupResponse) => {
          return (
            <span
              style={{
                fontWeight: '600',
              }}>
              {parseArraySportsmens(record.sportsmens)}
            </span>
          );
        }}
      />
    </Table>
  );
};
