import {combineReducers} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';
import {api} from '../api';
import {judgingApi} from '../api/judging.api';
import authReducer from '../auth/auth.slice';
import userReducer from './userSlice';
import modalReducer from './modalSlice';
import judgeReducer from './judgeSlice';

const judgePersistConfig = {
  key: 'judge',
  storage,
  whitelist: ['judge', 'judgingMode', 'spreadsheetId'],
};

export const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [judgingApi.reducerPath]: judgingApi.reducer,
  auth: authReducer,
  user: userReducer,
  modal: modalReducer,
  judge: persistReducer(judgePersistConfig, judgeReducer),
});
