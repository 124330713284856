import {IAuthData} from '../../services/auth.service';
import {createSlice} from '@reduxjs/toolkit';
import {login, register, resetPassword, forgotPassword} from './auth.actions';

interface IAuthInitialState extends IAuthData {
  isLoading: boolean;
  newPasswordSet: boolean;
}

const initialState: IAuthInitialState = {
  user: null,
  accessToken: '',
  isLoading: false,
  newPasswordSet: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      state.user = null;
      state.accessToken = '';
      state.isLoading = false;
      localStorage.clear();
    },
    setNewPassword: state => {
      state.newPasswordSet = false;
    },
    setMailConfirmed: state => {
      if (state.user) {
        state.user = {...state.user, mailConfirmed: true};
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, {payload}) => {
        state.isLoading = false;
        state.user = payload.user;
        state.accessToken = payload.accessToken;
      })
      .addCase(login.rejected, state => {
        state.isLoading = false;
      })
      .addCase(register.pending, state => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, {payload}) => {
        state.isLoading = false;
        state.user = payload.user;
        state.accessToken = payload.accessToken;
      })
      .addCase(register.rejected, state => {
        state.isLoading = false;
      })
      .addCase(forgotPassword.pending, state => {
        state.isLoading = true;
        state.newPasswordSet = false;
      })
      .addCase(forgotPassword.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(forgotPassword.rejected, state => {
        state.isLoading = false;
      })
      .addCase(resetPassword.pending, state => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, state => {
        state.isLoading = false;
        state.newPasswordSet = true;
      })
      .addCase(resetPassword.rejected, state => {
        state.isLoading = false;
      });
  },
});

export const {logout, setNewPassword, setMailConfirmed} = authSlice.actions;
export default authSlice.reducer;
