import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {Table, Typography} from 'antd';
import {groupApi} from '../../../../store/api';
import {IGroupParams} from '../types';
import {IGroupResponse, ISportsmen} from '../../../../models';
import type {ColumnsType} from 'antd/es/table';
import {parseArraySportsmens} from '../../../../utils/parseArraySportsmens';

const {Text} = Typography;

interface GroupsTableProps {
  groupParams: IGroupParams | null;
  setSelectedData: Dispatch<
    SetStateAction<{relationId: number; sportsmens: ISportsmen[]}[]>
  >;
}

const groupColumns: ColumnsType<IGroupResponse> = [
  {
    title: 'Состав группы',
    key: 'members',
    render: record => <Text>{parseArraySportsmens(record.sportsmens)}</Text>,
  },
];

export const GroupsTable: FC<GroupsTableProps> = ({
  groupParams,
  setSelectedData,
}) => {
  const [filteredGroups, setFilteredGroups] = useState<IGroupResponse[]>([]);

  const {data: groups, isFetching: isGroupsFetching} =
    groupApi.useGetGroupsQuery(groupParams, {
      refetchOnMountOrArgChange: true,
      skip: !groupParams,
    });

  useEffect(() => {
    if (groups) {
      setFilteredGroups(groups.items);
    }
  }, [groups]);

  return (
    <Table
      bordered
      pagination={false}
      loading={isGroupsFetching}
      rowSelection={{
        onChange: (_, selectedRows: IGroupResponse[]) => {
          const newSelectedData = selectedRows.map(row => ({
            relationId: row.id,
            sportsmens: row.sportsmens,
          }));

          setSelectedData(newSelectedData);
        },
      }}
      dataSource={filteredGroups}
      columns={groupColumns}
      rowKey={record => record.id}
    />
  );
};
