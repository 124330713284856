import {FC} from 'react';
import {Flex, Table, TableColumnsType, Typography} from 'antd';

import {StatisticTableTitle} from '../StatisticTableTitle';
import {EventStatisticCoachTableData} from 'store/api';

const {Text} = Typography;

interface EventStatisticCoachTableProps {
  data: EventStatisticCoachTableData[];
}

const columns: TableColumnsType<EventStatisticCoachTableData> = [
  {
    title: 'Тренер',
    dataIndex: 'coach',
    key: 'coach',
  },
  {
    title: 'Регион',
    dataIndex: 'region',
    key: 'region',
  },
  {
    title: 'Город',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'Выступлений',
    dataIndex: 'applicationsCount',
    key: 'applicationsCount',
    align: 'center',
    render: text => (
      <Text strong style={{fontSize: 16, color: '#1890ff'}}>
        {text}
      </Text>
    ),
  },
  {
    title: 'Спортсменов',
    dataIndex: 'sportsmensCount',
    key: 'sportsmensCount',
    align: 'center',
    render: text => (
      <Text strong style={{fontSize: 16, color: '#1890ff'}}>
        {text}
      </Text>
    ),
  },
];

export const EventStatisticCoachTable: FC<EventStatisticCoachTableProps> = ({
  data,
}) => {
  return (
    <Flex vertical gap={8}>
      <StatisticTableTitle title="Тренеры" />
      <Table
        dataSource={data}
        columns={columns}
        rowKey={row => row.coach}
        pagination={false}
        size="small"
      />
    </Flex>
  );
};
