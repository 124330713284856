import {ISportsmen} from '../models';

export const parseArraySportsmens = (
  sportsmens?: ISportsmen[],
  short?: boolean,
): string[] => {
  if (!sportsmens) {
    return [''];
  }

  return sportsmens.map((sportsmen, index) => {
    const {lastname, firstname} = sportsmen;

    const firstnameResult = short ? firstname[0] + '.' : firstname;

    return `${lastname} ${firstnameResult}${
      index + 1 !== sportsmens.length ? ', ' : ''
    }`;
  });
};
