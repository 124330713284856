import {isRejectedWithValue} from '@reduxjs/toolkit';
import type {MiddlewareAPI, Middleware} from '@reduxjs/toolkit';
import {message} from 'antd';

/**
 * Log a warning and show a toast!
 */
export const errorHandlerMiddleware: Middleware =
  (api: MiddlewareAPI) => next => action => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      if (
        action.payload?.status !== 404 &&
        action.payload.response?.status !== 404
      ) {
        if (action.payload.data?.message) {
          if (typeof action.payload.data?.message === 'string') {
            message.error(action.payload.data?.message);
          } else {
            message.error(action.payload.data?.message[0]);
          }
        }
      }
    }

    return next(action);
  };
