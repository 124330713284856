import {FC} from 'react';
import {Button} from 'antd';
import {LeftCircleOutlined} from '@ant-design/icons';

import styles from './style.module.css';

interface DeleteScoreButtonProps {
  disabled: boolean;
  setScoreValue: (value: string) => void;
  editableValue: string;
}

export const DeleteScoreButton: FC<DeleteScoreButtonProps> = ({
  disabled,
  setScoreValue,
  editableValue,
}) => {
  const buttonDeleteHandler = () => {
    if (editableValue.length === 2) {
      setScoreValue('');
    } else {
      setScoreValue(editableValue.slice(0, 2));
    }
  };

  return (
    <Button
      disabled={disabled}
      onClick={buttonDeleteHandler}
      className={styles.button}>
      <LeftCircleOutlined style={{color: '#ffa940', fontSize: '2.5rem'}} />
    </Button>
  );
};
