import {ColumnsType} from 'antd/es/table';
import {TableScoreButton} from '../TableScoreButton';
import {AvarageTag} from '../AvarageTag';
import {ViewButton} from '../ViewButton';
import {JudgeType} from '../../types';
import {OnScoreHandlerParams} from './types';
import {IJudgingSportsmenResponse} from 'store/api';

interface ApplicationsTableColumnsProps {
  judgeMode: 4 | 6;
  onScoreHandler: (params: OnScoreHandlerParams) => void;
}

export const ApplicationsTableColumns = ({
  judgeMode,
  onScoreHandler,
}: ApplicationsTableColumnsProps): ColumnsType<IJudgingSportsmenResponse> => {
  const arrayJudges = Array(judgeMode).fill('');

  const onPressDecline = (
    application: IJudgingSportsmenResponse,
    declineName: string,
    scoreValue: string,
  ) => {
    onScoreHandler({
      application,
      scoreValue,
      judge: declineName,
      judgeType: 'arb',
    });
  };

  const renderJudgesColumns = (
    judgeType: JudgeType,
  ): ColumnsType<IJudgingSportsmenResponse> => {
    return [
      ...arrayJudges.map((_, index) => ({
        title: `${judgeType}${index + 1}`,
        dataIndex: `${judgeType}${index + 1}`,
        key: `${judgeType}${index + 1}`,
        render: (value: string, record: IJudgingSportsmenResponse) => (
          <TableScoreButton
            value={value}
            onClick={() =>
              onScoreHandler({
                application: record,
                judge: `${judgeType}${index + 1}`,
                scoreValue: value,
                judgeType,
              })
            }
          />
        ),
      })),
      {
        title: 'AVG',
        dataIndex: `avg_${judgeType}`,
        key: `avg_${judgeType}`,
        align: 'center',
        render: (value: string) => <AvarageTag value={value} />,
      },
    ];
  };

  const columns: ColumnsType<IJudgingSportsmenResponse> = [
    {
      title: 'ФИО',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Исполнение',
      children: renderJudgesColumns('isp'),
    },
    {
      title: 'Артистичность',
      children: renderJudgesColumns('art'),
    },
    {
      title: 'Сложность',
      children: [
        {
          title: 'sl',
          dataIndex: 'sl',
          key: 'sl',
          align: 'center',
          render: (value, record) => (
            <TableScoreButton
              value={value}
              onClick={() =>
                onScoreHandler({
                  application: record,
                  scoreValue: value,
                  judge: 'sl',
                  judgeType: 'sl',
                })
              }
            />
          ),
        },
        {
          title: 'AVG',
          dataIndex: 'avg_sl',
          key: 'avg_sl',
          align: 'center',
          render: value => <AvarageTag value={value} />,
        },
      ],
    },
    {
      title: 'Сбавки',
      children: [
        {
          title: 'element',
          dataIndex: 'element',
          key: 'element',
          align: 'center',
          render: (value, record) => (
            <TableScoreButton
              value={value}
              onClick={() => onPressDecline(record, 'element', value)}
            />
          ),
        },
        {
          title: 'line',
          dataIndex: 'line',
          key: 'line',
          align: 'center',
          render: (value, record) => (
            <TableScoreButton
              value={value}
              onClick={() => onPressDecline(record, 'line', value)}
            />
          ),
        },
        {
          title: 'pbs',
          dataIndex: 'pbs',
          key: 'pbs',
          align: 'center',
          render: (value, record) => (
            <TableScoreButton
              value={value}
              onClick={() => onPressDecline(record, 'pbs', value)}
            />
          ),
        },
        {
          title: 'AVG',
          dataIndex: 'avg_decline',
          key: 'avg_decline',
          align: 'center',
          render: value => <AvarageTag value={value} />,
        },
      ],
    },
    {
      children: [
        {
          title: 'TOTAL',
          dataIndex: 'total',
          key: 'total',
          align: 'center',
          render: value => (
            <AvarageTag
              value={value}
              type="total"
              style={{
                fontSize: 20,
                fontWeight: 'bold',
              }}
            />
          ),
        },
        {
          title: 'PLACE',
          dataIndex: 'place',
          key: 'place',
          align: 'center',
          sorter: (a, b) => a.place - b.place,
          render: value => (
            <AvarageTag
              value={value}
              type="place"
              style={{
                fontSize: 20,
                fontWeight: 'bold',
              }}
            />
          ),
        },
      ],
    },
    {
      title: '',
      key: 'viewed',
      dataIndex: 'viewed',
      align: 'center',
      render: (_, record) => <ViewButton application={record} />,
    },
  ];

  return columns;
};
