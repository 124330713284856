import {Dispatch, FC, SetStateAction, useContext, useEffect} from 'react';
import {Button, Card, Flex, Input, Typography} from 'antd';
import {useLocalStorage} from '../../../../../../hooks';
import {
  CreateDiplomRequestSportsmen,
  judgingApi,
} from '../../../../../../store/api';
import {DrawerContext} from 'pages/Juding/Arbitrator/context';

interface ActionDiplomProps {
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export const ActionDiplom: FC<ActionDiplomProps> = ({setLoading}) => {
  const [diplomTemplateId, setDiplomTemplateId] = useLocalStorage(
    'diplomTemplateId',
    '',
  );

  const {drawerData} = useContext(DrawerContext);

  const [createDiplomQuery, {data: diplomResponseData, isLoading, reset}] =
    judgingApi.useCreateDiplomMutation();

  const [
    createDiplomQueryV2,
    {data: diplomResponseDataV2, isLoading: isLoadingV2, reset: resetV2},
  ] = judgingApi.useCreateDiplomV2Mutation();

  const createDiplomHandler = () => {
    const data: CreateDiplomRequestSportsmen[] = [];

    const nomination = drawerData.nomination;

    if (nomination) {
      drawerData.selectedApplications.forEach(app => {
        const sportsmensNames = app.name.split(',');

        sportsmensNames.forEach((name: string) => {
          data.push({
            place: app.place,
            nomination: nomination.name,
            sportsmen: name.trim(),
            category: nomination.categoryName,
          });
        });
      });
    }

    createDiplomQuery({diplomTemplateId, sportsmens: data});
  };

  const createDiplomHandlerV2 = () => {
    const data: CreateDiplomRequestSportsmen[] = [];

    const nomination = drawerData.nomination;

    if (nomination) {
      drawerData.selectedApplications.forEach(app => {
        const sportsmensNames = app.name.split(',');

        sportsmensNames.forEach((name: string) => {
          data.push({
            place: app.place,
            nomination: nomination.name,
            sportsmen: name.trim(),
            category: nomination.categoryName,
          });
        });
      });
    }

    createDiplomQueryV2({diplomTemplateId, sportsmens: data});
  };

  useEffect(() => {
    reset();
    resetV2();
  }, [drawerData]);

  useEffect(() => {
    setLoading(isLoading || isLoadingV2);
  }, [isLoading, isLoadingV2]);

  return (
    <Card title="Печать грамот">
      <Flex vertical gap={12}>
        <div>
          <Typography.Text strong>ID шаблона</Typography.Text>
          <Input
            value={diplomTemplateId}
            onChange={e => setDiplomTemplateId(e.target.value)}
            placeholder="ID шаблона"
          />
        </div>

        <Button
          onClick={createDiplomHandler}
          disabled={drawerData.selectedApplications.length === 0}>
          Печать
        </Button>

        {diplomResponseData && (
          <Button
            type="link"
            onClick={() => {}}
            href={`https://docs.google.com/document/d/${diplomResponseData?.diplomId}`}
            target="_blank">
            Грамоты
          </Button>
        )}

        <Button
          onClick={createDiplomHandlerV2}
          disabled={drawerData.selectedApplications.length === 0}>
          Печать (Beta)
        </Button>

        {diplomResponseDataV2 && (
          <Button
            type="link"
            onClick={() => {}}
            href={`https://docs.google.com/document/d/${diplomResponseDataV2?.diplomId}`}
            target="_blank">
            Грамоты
          </Button>
        )}
      </Flex>
    </Card>
  );
};
