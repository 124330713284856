import {FC, useContext, useEffect, useState} from 'react';
import {Card, Flex, Layout, Select, Switch, Typography} from 'antd';
import {
  WifiOutlined,
  PauseCircleTwoTone,
  SyncOutlined,
  FileTwoTone,
} from '@ant-design/icons';

import {useAppDispatch, useAppSelector, useSocketHook} from 'hooks';
import {EventNominationResponse, judgingApi} from 'store/api';
import {ActionsDrawer} from '../ActionsDrawer/ActionsDrawer';
import {changeJudgingMode, getJudgeState} from 'store/reducers';
import {JudgingLayoutContext} from 'ui';
import {DrawerContext} from '../../context';
import {CentrifugeService} from 'services';

const {Header} = Layout;
const {Text} = Typography;

interface ArbitratorPageHeaderProps {}

export const ArbitratorPageHeader: FC<ArbitratorPageHeaderProps> = () => {
  const [selectedNominationId, setSelectedNominationId] = useState<number>(0);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const {setDrawerData} = useContext(DrawerContext);
  const {setIsLoading} = useContext(JudgingLayoutContext);

  const dispatch = useAppDispatch();
  const {connect, socket} = useSocketHook();
  const {judge, judgingMode} = useAppSelector(getJudgeState);

  const [getApplications, {isFetching: isApplicationsFetching}] =
    judgingApi.useLazyGetJudgingApplicationsQuery();

  const {data: nominationsResponse, isFetching: isNominationsFetching} =
    judgingApi.useGetJudgingNominationsQuery();

  const changeNomination = (nomination: EventNominationResponse) => {
    setSelectedNominationId(nomination.id);

    getApplications(nomination.id);

    setDrawerData(prev => ({
      ...prev,
      nomination,
    }));
  };

  useEffect(() => {
    setIsLoading(isNominationsFetching || isApplicationsFetching);
  }, [isNominationsFetching, isApplicationsFetching]);

  useEffect(() => {
    CentrifugeService.connectCentrifuge(
      () => setIsConnected(true),
      () => setIsConnected(false),
    );

    connect();

    return () => {
      CentrifugeService.disconnectCentrifuge();
    };
  }, []);

  return (
    <>
      <ActionsDrawer open={openDrawer} onClose={() => setOpenDrawer(false)} />
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}>
        <Card size="small">
          <Flex align="center" gap={12}>
            <WifiOutlined
              style={{
                color: isConnected ? '#73d13d' : '#ff7875',
                fontSize: 30,
              }}
            />

            <Switch
              checked={judgingMode === 6}
              checkedChildren="6"
              unCheckedChildren="4"
              onChange={() => {
                dispatch(changeJudgingMode(judgingMode === 6 ? 4 : 6));
              }}
            />

            <PauseCircleTwoTone
              onClick={() => {
                socket?.emit('setShowImage', {
                  eventId: judge?.eventId,
                  brigade: judge?.brigade,
                });
              }}
              style={{fontSize: 25}}
            />

            <SyncOutlined
              onClick={() => getApplications(selectedNominationId)}
              style={{fontSize: 25, color: '#1890ff'}}
            />

            <FileTwoTone
              onClick={() => setOpenDrawer(true)}
              style={{
                fontSize: 25,
              }}
            />
          </Flex>
        </Card>
        <Select<number, EventNominationResponse>
          placeholder="Выберите номинацию"
          size="large"
          loading={isApplicationsFetching}
          value={selectedNominationId ? selectedNominationId : undefined}
          style={{flex: 1, marginLeft: 10}}
          onChange={(_, i) => changeNomination(i as EventNominationResponse)}
          options={nominationsResponse?.nominations.map(nomination => ({
            value: nomination.id,
            label: nomination.name,
            ...nomination,
          }))}
          optionRender={option => (
            <Flex vertical>
              <Text>{option.label}</Text>
              <Text strong>{option.data.categoryName}</Text>
            </Flex>
          )}
        />
      </Header>
    </>
  );
};
