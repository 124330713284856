import {FC, useContext, useEffect, useState} from 'react';
import {Button, Card, Collapse, Flex, Typography} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';

import {GroupApplication} from '@pages/Juding/Arbitrator/types';
import {useAppDispatch} from 'hooks';
import {JudgingApplicationResponse, judgingApi} from 'store/api';
import {setSelectedApplication} from 'store/reducers';
import {JudgingLayoutContext} from 'ui';
import {groupJudgingApplications} from 'utils';

import styles from './style.module.css';

const {Panel} = Collapse;
const {Text} = Typography;

interface ApplicationsListProps {}

export const ApplicationsList: FC<ApplicationsListProps> = ({}) => {
  const [categoriesData, setCategoriesData] = useState<GroupApplication[]>([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {setIsLoading} = useContext(JudgingLayoutContext);

  const {data: applicationsResponse, isFetching} =
    judgingApi.useGetAllJudgingApplicationsQuery(undefined, {
      refetchOnMountOrArgChange: !location.state?.notRefresh,
    });

  useEffect(() => {
    if (applicationsResponse) {
      const formattedApplications: GroupApplication[] =
        groupJudgingApplications(applicationsResponse);
      setCategoriesData(formattedApplications);
    }
  }, [applicationsResponse]);

  const buttonHandler = (
    application: JudgingApplicationResponse,
    applicationIndex: number,
  ) => {
    dispatch(setSelectedApplication({application, applicationIndex}));

    navigate('/judging/new/input-score');
  };

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  return (
    <Flex vertical style={{width: '100%'}} gap={16}>
      {categoriesData.map(sp => (
        <Card size={'small'} key={sp.category} title={sp.category}>
          {sp.nominations.map(nom => (
            <Collapse key={nom.nomination.id} ghost>
              <Panel key={nom.nomination.id} header={nom.nomination.name}>
                <Flex vertical gap={12}>
                  {nom.applications.map(spp => (
                    <Button
                      block
                      style={{whiteSpace: 'normal', height: 'auto'}}
                      onClick={() =>
                        buttonHandler(
                          {
                            application: spp,
                            nomination: nom.nomination,
                          },
                          spp.originalIndex,
                        )
                      }
                      key={spp.id}
                      size="large">
                      <Text className={styles.buttonText}>{spp.name}</Text>
                    </Button>
                  ))}
                </Flex>
              </Panel>
            </Collapse>
          ))}
        </Card>
      ))}
    </Flex>
  );
};
