import React, {FC} from 'react';
import {Typography} from 'antd';
import {ListApplications} from '../ListApplications/ListApplications';
import {ApplicationItem, IApplication} from '../../../../models';

const {Text} = Typography;

interface ListFoundApplicationsProps {
  applications: ApplicationItem[];
  deleteApplication: (
    categoryName: string,
    nominationName: string,
    relationId: number,
  ) => void;
}

export const ListFoundApplications: FC<ListFoundApplicationsProps> = ({
  applications,
  deleteApplication,
}) => {
  return applications.length > 0 ? (
    <ListApplications
      applications={applications}
      deleteApplication={() => {}}
    />
  ) : (
    <Text style={{fontSize: 20}} type="secondary">
      Заявок с таким спортсменом не найдено
    </Text>
  );
};
