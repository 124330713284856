import React, {FC, useEffect, useState} from 'react';
import {
  Button,
  Divider,
  Layout,
  List,
  Menu,
  MenuProps,
  Modal,
  Typography,
} from 'antd';
import {logout} from '../../store/auth/auth.slice';

import styles from './sidebar.module.css';
import {IAuthData, UserRole} from '../../services';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAppDispatch} from '../../hooks';

const {Sider} = Layout;
const {Title} = Typography;

interface SideBarProps {
  user: IAuthData['user'];
}

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
  {
    label: 'Соревнования',
    key: '/events',
  },
  {
    label: 'Спортсмены',
    key: '/sportsmens',
  },
  {
    label: 'Заявки',
    key: '/statements',
  },
];

const adminItems: MenuItem[] = [
  {
    label: 'Статистика',
    key: '/admin',
  },
];

export const SideBar: FC<SideBarProps> = ({user}) => {
  const [current, setCurrent] = useState('events');
  const [showUpdatesModal, setShowUpdatesModal] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const activePath = useLocation();

  const onClick: MenuProps['onClick'] = e => {
    navigate(e.key);
  };

  useEffect(() => {
    setCurrent(activePath.pathname);
  }, [activePath]);

  return (
    <Sider
      theme="light"
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
      }}>
      <div className={styles.logo}>
        <Title level={4}>
          {`${user?.lastname} ${user?.firstname} ${user?.middlename}`}
        </Title>
      </div>
      <Modal
        onCancel={() => setShowUpdatesModal(false)}
        open={showUpdatesModal}
        destroyOnClose
        footer={null}
        width={600}>
        <List
          header={<Divider>Обновление 13.10.2023</Divider>}
          dataSource={[
            '- Добавлена возможность редактирования групп (да, раньше ее не было 🫣)',
            '- Изменена форма создания/редактирования группы',
            '- Изменена страница подачи/редактирования заявки',
            '- Исправления и улучшения в системе судейства',
          ]}
          renderItem={item => <List.Item>{item}</List.Item>}
        />
      </Modal>

      <Menu
        style={{fontWeight: 600}}
        onClick={onClick}
        mode="inline"
        selectedKeys={[current]}
        items={
          user?.roles?.includes(UserRole.Admin)
            ? [...items, ...adminItems]
            : items
        }
      />
      <div
        style={{
          width: '100%',
          marginTop: 40,
          display: 'flex',
          justifyContent: 'center',
          paddingLeft: 5,
          paddingRight: 5,
        }}>
        <Button
          style={{
            fontWeight: 600,
            fontSize: 16,
            width: '100%',
            height: 40,
            borderRadius: 8,
          }}
          type="text"
          danger
          onClick={() => {
            dispatch(logout());
          }}>
          Выйти
        </Button>

        <Button
          style={{position: 'absolute', bottom: 20}}
          type={'link'}
          onClick={() => setShowUpdatesModal(true)}>
          Что нового?
        </Button>
      </div>
    </Sider>
  );
};
