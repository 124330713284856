import React, {FC, useEffect, useState} from 'react';
import {
  Collapse,
  Divider,
  Drawer,
  Input,
  Segmented,
  Skeleton,
  Table,
  Typography,
} from 'antd';
import {useDebounce} from '../../../../hooks';
import {EventNominationResponse, statementApi} from '../../../../store/api';
import {parseArraySportsmens} from '../../../../utils/parseArraySportsmens';

const {Title} = Typography;
const {Panel} = Collapse;
const {Search} = Input;
const {Column} = Table;

interface ViewStatementProps {
  statementId: number;
  closeDrawer: () => void;
}

interface Categories {
  category: string;
  applications: {
    key: number;
    nomination: string;
    sportsmens: string[];
  }[];
}

type arType = {
  nominations: EventNominationResponse[];
  category: string;
}[];

export const ViewStatement: FC<ViewStatementProps> = ({
  statementId,
  closeDrawer,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [segmentedValue, setSegmentedValue] = useState<string | number>(
    'sportsmens',
  );
  const [categories, setCategories] = useState<arType>([]);

  const {data: statement, isFetching: isStatementFetching} =
    statementApi.useGetStatementQuery(statementId, {
      skip: !statementId,
    });

  const debouncedSearchTerm = useDebounce(searchValue, 300);

  useEffect(() => {
    if (debouncedSearchTerm.length > 2) {
      onSearch();
    } else {
    }
  }, [debouncedSearchTerm]);

  const onSearch = () => {};

  const collapseData: Categories[] = [];

  // statement?.applications.forEach((application, applicationIndex) => {
  //   const categoryIndex = collapseData.findIndex(
  //     item => item.category === application.category,
  //   );

  //   if (categoryIndex > -1) {
  //     collapseData[categoryIndex].applications.push({
  //       key: applicationIndex,
  //       nomination: application.nomination,
  //       sportsmens: parseArraySportsmens(application.applicationSportsmens),
  //     });
  //   } else {
  //     collapseData.push({
  //       category: application.category,
  //       applications: [
  //         {
  //           key: applicationIndex,
  //           nomination: application.nomination,
  //           sportsmens: parseArraySportsmens(application.applicationSportsmens),
  //         },
  //       ],
  //     });
  //   }
  // });

  // collapseData.forEach(category => {
  //   category.applications.sort((a, b) => {
  //     if (a.nomination > b.nomination) {
  //       return -1;
  //     } else if (a.nomination < b.nomination) {
  //       return 1;
  //     } else {
  //       return 0;
  //     }
  //   });
  // });

  return (
    <Drawer
      width={640}
      placement="right"
      closable={false}
      onClose={closeDrawer}
      open={Boolean(statementId)}>
      <Skeleton active loading={isStatementFetching}>
        <Title level={3} ellipsis={{rows: 2}}>
          {statement?.event.title}
        </Title>
        <Divider />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <Segmented
            size={'large'}
            options={[
              {
                label: 'Спортсмены',
                value: 'sportsmens',
              },
              {label: 'Судьи', value: 'judge', disabled: true},
              {label: 'Расчет', value: 'cost', disabled: true},
            ]}
            value={segmentedValue}
            onChange={setSegmentedValue}
          />
          <Search
            size="large"
            style={{width: '45%'}}
            placeholder={'Поиск по спортсменам'}
            onChange={e => setSearchValue(e.target.value)}
            onSearch={onSearch}
            enterButton
            allowClear
          />
        </div>
      </Skeleton>
      <div style={{marginTop: 20}}>
        <Skeleton active loading={isStatementFetching}>
          <Collapse accordion>
            {collapseData.map(item => (
              <Panel header={item.category} key={item.category}>
                <Table
                  dataSource={item.applications}
                  bordered
                  pagination={false}
                  size={'small'}>
                  <Column
                    title="Номинация"
                    dataIndex={'nomination'}
                    key={'nom'}
                  />
                  <Column
                    title="Спортсмены"
                    dataIndex={'sportsmens'}
                    key={'sportsmens'}
                  />
                </Table>
              </Panel>
            ))}
          </Collapse>
        </Skeleton>
      </div>
    </Drawer>
  );
};
