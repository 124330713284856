import {FC, useContext, useState} from 'react';
import {Descriptions, Drawer, Flex, Spin} from 'antd';

import {DrawerContext} from '../../context';
import {ActionDiplom, ActionProtocol} from './ActionsBlock';

interface ActionsDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const ActionsDrawer: FC<ActionsDrawerProps> = ({open, onClose}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {drawerData} = useContext(DrawerContext);

  return (
    <Drawer
      title="Печать"
      placement="left"
      onClose={onClose}
      open={open}
      closable={false}
      width={'30%'}>
      <Spin spinning={loading}>
        <Flex vertical gap={12}>
          <Descriptions
            layout="vertical"
            items={[
              {
                key: '1',
                label: 'Категория',
                children: drawerData.nomination?.categoryName,
              },
              {
                key: '2',
                label: 'Номинация',
                children: drawerData.nomination?.name,
              },
            ]}
          />

          <ActionDiplom setLoading={setLoading} />
          <ActionProtocol setLoading={setLoading} />
        </Flex>
      </Spin>
    </Drawer>
  );
};
