import {api} from '../api';
import {IResponse} from '../types';
import {EventStatisticResponse} from './types';

export const adminApi = api.injectEndpoints({
  endpoints: builder => ({
    getEventStatistic: builder.query<
      EventStatisticResponse,
      {eventId: string | null}
    >({
      query: ({eventId}) => ({
        url: '/admin/statements',
        params: {
          ...(eventId && {event_id: eventId}),
        },
      }),
      providesTags: () => [{type: 'Admin'}],
    }),
    getEventApplications: builder.query<IResponse<any[]>, {eventId: string}>({
      query: ({eventId}) => ({
        url: `/admin/event/${eventId}/applications`,
        params: {event_id: eventId},
      }),
      providesTags: () => [{type: 'Admin'}],
    }),
    searchEventApplications: builder.query<
      IResponse<any[]>,
      {eventId: string; q: string}
    >({
      query: ({eventId, q}) => ({
        url: `/admin/search-applications`,
        params: {event_id: eventId, q},
      }),
      providesTags: () => [{type: 'Admin'}],
    }),
    getStatementsFile: builder.query({
      query: () => ({
        url: '/admin/statement/16',
      }),
    }),
  }),
  overrideExisting: false,
});
