import React, {FC} from 'react';
import {Table, TableProps} from 'antd';
import {ISportsmen} from '../../models';
import dayjs from 'dayjs';
import cn from 'classnames';

import styles from './style.module.css';

const {Column} = Table;

interface SportsmensMiniTableProps extends TableProps<ISportsmen> {
  data: ISportsmen[];
  rowHandler: (sportsmen: ISportsmen) => void;
  disableRow?: boolean;
}

export const SportsmensMiniTable: FC<SportsmensMiniTableProps> = ({
  data,
  rowHandler,
  loading,
  disableRow,
  ...props
}) => {
  return (
    <Table
      rowKey={(record: ISportsmen) => record.id}
      onRow={(record: ISportsmen) => {
        return {
          onClick: () => rowHandler(record),
        };
      }}
      rowClassName={cn(styles.tableRow, disableRow && styles.disabledRow)}
      size={'small'}
      dataSource={data}
      loading={loading}
      {...props}>
      <Column
        title={'ФИО'}
        dataIndex={'name'}
        key={'name'}
        render={(text, record: ISportsmen) => {
          return (
            <span
              className={
                styles.sportsmen
              }>{`${record.lastname} ${record.firstname}`}</span>
          );
        }}
      />
      <Column
        title={'Год'}
        dataIndex={'birthdate'}
        key={'birthdate'}
        width={80}
        render={(text, record: ISportsmen) =>
          dayjs(record.birthdate).format('YYYY')
        }
      />
    </Table>
  );
};
