import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {Table, Typography} from 'antd';
import {ISportsmensListQueryParams, sportsmenApi} from '../../../../store/api';
import {IParams} from '../types';
import {ApplicationItem, IApplication, ISportsmen} from '../../../../models';
import type {ColumnsType} from 'antd/es/table';
import {parseArraySportsmens} from '../../../../utils/parseArraySportsmens';

const {Text} = Typography;

interface SportsmensTableProps {
  params: ISportsmensListQueryParams;
  setSelectedData: Dispatch<
    SetStateAction<{relationId: number; sportsmens: ISportsmen[]}[]>
  >;
}

const columns: ColumnsType<ISportsmen> = [
  {
    title: 'ФИО',
    key: 'name',
    render: record => <Text>{parseArraySportsmens([record])}</Text>,
  },
  {
    title: 'Год',
    dataIndex: 'birthdate',
    key: 'year',
    render: record => <Text>{new Date(record).getFullYear()}</Text>,
  },
];

export const SportsmensTable: FC<SportsmensTableProps> = ({
  params,
  setSelectedData,
}) => {
  const {data: sportsmens, isFetching: isSportsmensFetching} =
    sportsmenApi.useGetSportsmensQuery(params, {
      skip: !params,
    });

  return (
    <Table
      bordered
      pagination={false}
      loading={isSportsmensFetching}
      rowSelection={{
        onChange: (_, selectedRows: ISportsmen[]) => {
          const newSelectedData = selectedRows.map(row => ({
            relationId: row.id,
            sportsmens: [row],
          }));

          setSelectedData(newSelectedData);
        },
      }}
      dataSource={sportsmens?.items}
      columns={columns}
      rowKey={record => record.id}
    />
  );
};
