import {FC} from 'react';
import {Button} from 'antd';
import {EyeOutlined} from '@ant-design/icons';
import {IJudgingSportsmenResponse, judgingApi} from 'store/api';

interface ViewButtonProps {
  application: IJudgingSportsmenResponse;
}

export const ViewButton: FC<ViewButtonProps> = ({application}) => {
  const [sendViewScore, {}] = judgingApi.useSendViewScoreMutation();
  const viewButtonHandler = () => {
    sendViewScore({
      nomination: '',
      ...application,
    });
  };

  return (
    <Button
      style={{backgroundColor: application.viewed ? '#4FAF59' : ''}}
      icon={<EyeOutlined style={{color: application.viewed ? 'white' : ''}} />}
      onClick={viewButtonHandler}
    />
  );
};
