import {FC, useContext, useEffect, useMemo, useState} from 'react';
import {Table, TableProps} from 'antd';

import {ApplicationsTableColumns} from './ApplicationsTableColumns';
import {ModalSendScore} from '../ModalSendScore';
import {
  calculateAVGByJudgesCount,
  getNewApplicationsScores,
} from 'utils/getNewApplicationsScores';
import {OnScoreHandlerParams} from './types';
import {JudgeType} from '@pages/Juding/Arbitrator/types';
import {getTableDataWithRanks} from 'utils';
import {DrawerContext} from '../../context';
import {IJudgingSportsmenResponse} from 'store/api';
import {useAppSelector} from 'hooks';

interface ApplicationsTableProps {
  applications: IJudgingSportsmenResponse[];
}

export const ApplicationsTable: FC<ApplicationsTableProps> = ({
  applications,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [judge, setJudge] = useState<string>('');
  const [judgeType, setJudgeType] = useState<JudgeType | null>(null);
  const [scoreValue, setScoreValue] = useState<string>('');
  const [selectedApplication, setSelectedApplication] =
    useState<IJudgingSportsmenResponse>();
  const [tableData, setTableData] =
    useState<IJudgingSportsmenResponse[]>(applications);

  const {setDrawerData, drawerData} = useContext(DrawerContext);
  const judgingMode = useAppSelector(state => state.judge.judgingMode);

  const setSelectedApplications = (
    applications: IJudgingSportsmenResponse[],
  ) => {
    setDrawerData(prevState => ({
      ...prevState,
      selectedApplications: applications,
    }));
  };

  const onScoreHandler = ({
    application,
    judge,
    scoreValue,
    judgeType,
  }: OnScoreHandlerParams) => {
    setOpen(true);
    setJudge(judge);
    setJudgeType(judgeType);
    setScoreValue(scoreValue);
    setSelectedApplication(application);
  };

  const updateDataTable = (score: string) => {
    if (!selectedApplication || !judgeType) {
      return;
    }

    const newApplications = getNewApplicationsScores({
      dataTable: tableData,
      judgingMode,
      newScore: {
        score: score,
        judges: [judge],
        applicationId: selectedApplication.id,
        judgeType: judgeType,
        element: null,
      },
    });

    const newApplicationsWithRanks = getTableDataWithRanks(newApplications);

    setTableData(newApplicationsWithRanks);
  };

  useEffect(() => {
    const newApplications = calculateAVGByJudgesCount({
      dataTable: applications,
      judgingMode,
    });

    const applicationsWithRanks = getTableDataWithRanks(newApplications);

    setTableData(applicationsWithRanks);
    setSelectedApplications([]);
  }, [applications]);

  useEffect(() => {
    const newApplications = calculateAVGByJudgesCount({
      dataTable: applications,
      judgingMode,
    });

    const newApplicationsWithRanks = getTableDataWithRanks(newApplications);

    setTableData(newApplicationsWithRanks);
  }, [judgingMode]);

  const columns = useMemo(
    () => ApplicationsTableColumns({judgeMode: judgingMode, onScoreHandler}),
    [judgingMode],
  );

  const onChange: TableProps<IJudgingSportsmenResponse>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra,
  ) => {
    if (extra.action === 'sort') {
      setSelectedApplications([]);
    }
  };

  return (
    <>
      {selectedApplication && (
        <ModalSendScore
          open={open}
          setOpen={setOpen}
          application={selectedApplication}
          judge={judge}
          scoreValue={scoreValue}
          updateDataTable={updateDataTable}
        />
      )}

      <Table
        dataSource={tableData}
        rowKey={record => record.id}
        columns={columns}
        pagination={false}
        scroll={{
          x: '100%',
        }}
        style={{width: '100%'}}
        bordered
        size={'small'}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: drawerData.selectedApplications.map(app => app.id),
          onChange: (_, v) => setSelectedApplications(v),
        }}
        onChange={onChange}
      />
    </>
  );
};
