import React, {FC, useEffect} from 'react';
import {Button, message, Typography} from 'antd';
import {confirmMailApi} from '../../store/api';
import {useAppDispatch} from '../../hooks';
import {setMailConfirmed} from '../../store/auth/auth.slice';
import {getFetchError} from '../../utils/getFetchError';

import styles from './style.module.css';

const {Title} = Typography;

export const ConfirmMailOverlay: FC = () => {
  const [sendLink, {error, isSuccess}] =
    confirmMailApi.useSendConfirmLinkMutation();

  const buttonHandler = (): void => {
    sendLink('');
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (error && getFetchError(error)?.status !== 500) {
      dispatch(setMailConfirmed());
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      message.success('Сообщение отправлено!');
    }
  }, [isSuccess]);

  return (
    <div className={styles.blurLayout}>
      <div className={styles.overlay}>
        <div className={styles.textContainer}>
          <Title style={{color: 'white'}}>
            Чтобы продолжить необходимо подтвердить почту
          </Title>
          <p>
            На указанный вами электронный адрес почты, было отправлено письмо с
            ссылкой для подтверждения
          </p>
        </div>
        <div className={styles.buttonBlock}>
          <p className={styles.buttonBlockText}>Не пришло письмо? </p>
          <Button className={styles.button} onClick={buttonHandler}>
            Отправить повторно!
          </Button>
        </div>
      </div>
    </div>
  );
};
