import {FC} from 'react';

import {JudgingLayout} from 'ui';
import {SpreadSheetsList} from './components';

export const SelectCategory: FC = () => {
  return (
    <JudgingLayout title={'Выберите категорию'}>
      <SpreadSheetsList />
    </JudgingLayout>
  );
};
