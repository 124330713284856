import React, {FC, useState} from 'react';
import {Button} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {DownloadService} from '../../../../services';

interface DownloadStatementButtonProps {
  selectedEventId: string;
}

export const DownloadStatementButton: FC<DownloadStatementButtonProps> = ({
  selectedEventId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const downloadHandler = async () => {
    setLoading(true);
    const file = await DownloadService.getStatementsFile(
      selectedEventId,
    ).finally(() => {
      setLoading(false);
    });

    if (file) {
      const fileUrl = window.URL.createObjectURL(new Blob([file.data]));

      const link = document.createElement('a');
      link.href = fileUrl;

      const disposition = file.headers['content-disposition'];

      const fileName = disposition
        ? disposition.split(/;(.+)/)[1].split(/=(.+)/)[1].replace(/['"]/g, '')
        : 'statements';

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }
    setLoading(false);
  };

  return (
    <Button
      type="primary"
      shape="round"
      icon={<DownloadOutlined />}
      size={'large'}
      style={{marginTop: 20}}
      onClick={downloadHandler}
      loading={loading}>
      Скачать заявки
    </Button>
  );
};
