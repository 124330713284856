import {FC} from 'react';
import {Button, Modal, Typography} from 'antd';
import {
  ExclamationCircleOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';

import {ToggleApplicationType, useToggleApplication} from 'hooks';

const {Paragraph} = Typography;
const {confirm} = Modal;

interface PrevNextButtonsProps {
  disabled: boolean;
  score: string;
}

export const PrevNextButtons: FC<PrevNextButtonsProps> = ({
  disabled,
  score,
}) => {
  const navigate = useNavigate();

  const toggleApplication = useToggleApplication();

  const prevNextButtonHandler = (handlerType: ToggleApplicationType) => {
    if (score.length === 0) {
      showDeleteConfirm(handlerType);
    } else {
      toggleApplication(handlerType);
    }
  };

  const showDeleteConfirm = (handlerType: ToggleApplicationType) => {
    confirm({
      title: 'Вы не отправили оценку. Все равно переключить?',
      icon: <ExclamationCircleFilled />,
      okText: 'ДА',
      okType: 'danger',
      cancelText: 'НЕТ',
      closable: true,
      onOk() {
        toggleApplication(handlerType);
      },
      centered: true,
      okButtonProps: {
        size: 'large',
      },
      cancelButtonProps: {
        size: 'large',
      },
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        width: '90%',
      }}>
      {disabled ? (
        <div
          style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <ExclamationCircleOutlined style={{color: '#fa8c16', fontSize: 20}} />
          <Paragraph style={{margin: 0, marginLeft: 5}}>
            Пожалуйста отправьте оценку
          </Paragraph>
        </div>
      ) : (
        <>
          <Button
            disabled={disabled}
            style={{borderWidth: 0}}
            onClick={() => prevNextButtonHandler('prev')}
            size="large">
            ПРЕД
          </Button>
          <Button
            onClick={() => {
              navigate('/judging/new/select-sportsmen', {
                state: {notRefresh: true},
              });
            }}
            type="link"
            style={{fontSize: 18, fontWeight: 600}}>
            К списку
          </Button>
          <Button
            disabled={disabled}
            style={{borderWidth: 0}}
            onClick={() => prevNextButtonHandler('next')}
            size="large">
            СЛЕД
          </Button>
        </>
      )}
    </div>
  );
};
