import React, {FC, useEffect} from 'react';
import {Button, Form, Input, Radio} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import {MainCard} from '../../../components';
import {useAppDispatch, useAuth} from '../../../hooks';
import {register} from '../../../store/auth/auth.actions';
import {IRegisterData} from '../auth.interface';
import {SelectCity} from './components/selectCity/selectCity';

import styles from './register.module.css';
import {AuthLayout} from '../../../ui';

export const Register: FC = () => {
  const {isLoading, user} = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const onFinish = (values: IRegisterData) => {
    dispatch(register(values));
  };

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  return (
    <AuthLayout>
      <MainCard title={'Регистрация'}>
        <Form
          form={form}
          disabled={isLoading}
          name="login"
          onFinish={onFinish}
          autoComplete="off"
          className={styles.form}>
          <Form.Item
            name="email"
            rules={[
              {required: true, type: 'email', message: 'Некорректный Email'},
            ]}>
            <Input
              placeholder={'Email'}
              size="large"
              style={{fontWeight: 'bold'}}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                min: 6,
                message: 'Минимальная длина пароля 6 символов',
              },
            ]}>
            <Input.Password
              size="large"
              placeholder={'Пароль'}
              style={{fontWeight: 'bold'}}
            />
          </Form.Item>
          <Form.Item
            name="lastname"
            rules={[{required: true, message: 'Обязательно для заполнения'}]}>
            <Input
              placeholder={'Фамилия'}
              size="large"
              style={{fontWeight: 'bold'}}
            />
          </Form.Item>
          <Form.Item
            name="firstname"
            rules={[{required: true, message: 'Обязательно для заполнения'}]}>
            <Input
              placeholder={'Имя'}
              size="large"
              style={{fontWeight: 'bold'}}
            />
          </Form.Item>
          <Form.Item
            name="middlename"
            className={styles.form_input_email}
            rules={[{required: true, message: 'Обязательно для заполнения'}]}>
            <Input
              placeholder={'Отчество'}
              size="large"
              style={{fontWeight: 'bold'}}
            />
          </Form.Item>
          <Form.Item
            name="gender"
            label="Пол"
            rules={[{required: true, message: 'Необходимо выбрать пол'}]}>
            <Radio.Group>
              <Radio value="woman">Женский</Radio>
              <Radio value="man">Мужской</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            className={styles.form_input_email}
            name="city"
            rules={[{required: true, message: 'Обязательно для заполнения'}]}>
            <SelectCity onSelect={form.setFieldsValue} />
          </Form.Item>
          {/*TODO вроде можно как-то через массивы добавить свойство*/}
          <Form.Item style={{height: 0}} name="region">
            <Input style={{display: 'none'}} />
          </Form.Item>
          <Form.Item style={{display: 'flex', justifyContent: 'center'}}>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}>
              Зарегистрироваться
            </Button>
          </Form.Item>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Link to={'/login'}>Войти</Link>
          </div>
        </Form>
      </MainCard>
    </AuthLayout>
  );
};
