import dayjs from 'dayjs';
import {Button, Table} from 'antd';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import React, {useEffect, useState} from 'react';
import {sportsmenApi} from '../../../../store/api';
import {AddSportsmenModal, UpdateSportsmenModal, Header} from '../index';
import {ISportsmen} from '../../../../models';
import {genders, ranks} from '../../../../enums';
import {useAppDispatch} from '../../../../hooks';
import {openModal} from '../../../../store/reducers/modalSlice';
import {showDeleteConfirm} from '../../../../components/ConfirmDeleteModal/ConfirmDeleteModal';

import styles from './style.module.css';

const {Column} = Table;

export const SportsmensTable = ({setLoading}: any) => {
  const [filteredSportsmens, setFilteredSportsmens] = useState<
    ISportsmen[] | []
  >([]);

  const dispatch = useAppDispatch();

  const {data: sportsmens, isFetching: sportsmensLoading} =
    sportsmenApi.useGetSportsmensQuery({}, {refetchOnMountOrArgChange: true});
  const [deleteSportsmen, {isLoading: deleteLoading}] =
    sportsmenApi.useDeleteSportsmenMutation();

  useEffect(() => {
    if (sportsmens) {
      setFilteredSportsmens(sportsmens.items);
    }
  }, [sportsmens]);

  useEffect(() => {
    const loading = sportsmensLoading || deleteLoading;
    setLoading(loading);
  }, [sportsmensLoading, deleteLoading]);

  const deleteSportsmenHandler = (sportsmen: ISportsmen) => {
    const content = (
      <p>
        {`${sportsmen.lastname} ${sportsmen.firstname} ${sportsmen.middlename}`}
      </p>
    );

    showDeleteConfirm(
      'Вы уверены что хотите удалить спортсмена ?',
      () => deleteSportsmen(sportsmen),
      content,
    );
  };

  return (
    <>
      <AddSportsmenModal />
      <UpdateSportsmenModal />
      <Header
        sportsmens={sportsmens?.items || []}
        setFilteredSportsmens={setFilteredSportsmens}
      />
      <Table
        size={'small'}
        rowClassName={styles.tableRow}
        dataSource={filteredSportsmens}
        rowKey={(record: ISportsmen) => record.id}
        pagination={false}
        sticky={true}>
        <Column
          title={'ФИО'}
          dataIndex={'name'}
          key={'name'}
          render={(text, record: ISportsmen) => {
            return (
              <span
                style={{
                  fontWeight: '600',
                }}>{`${record.lastname} ${record.firstname} ${record.middlename}`}</span>
            );
          }}
          width={200}
        />
        <Column
          title={'Возраст'}
          dataIndex={'birthdate'}
          key={'birthdate'}
          width={50}
          align="center"
          render={(text, record: ISportsmen) =>
            dayjs(record.birthdate).format('YYYY')
          }
        />
        <Column
          title={'Пол'}
          dataIndex={'gender'}
          key={'gender'}
          width={50}
          align="center"
          render={(text: keyof typeof genders) => {
            return <p style={{padding: 0, margin: 0}}>{genders[text]}</p>;
          }}
        />
        <Column
          title={'Разряд'}
          dataIndex={'rank'}
          key={'rank'}
          width={50}
          align="center"
          render={(text: keyof typeof ranks) => {
            return <p style={{padding: 0, margin: 0}}>{ranks[text]}</p>;
          }}
        />
        <Column
          title={'Действия'}
          key={'actions'}
          width={60}
          align="center"
          render={(text, record: ISportsmen) => {
            return (
              <div>
                <Button
                  icon={<EditOutlined style={{fontSize: 20}} />}
                  type="link"
                  onClick={() => {
                    dispatch(
                      openModal({
                        modal: 'updateSportsmenModal',
                        modalData: record,
                      }),
                    );
                  }}
                />
                <Button
                  icon={<DeleteOutlined style={{fontSize: 20}} />}
                  danger
                  type="link"
                  onClick={() => {
                    deleteSportsmenHandler(record);
                  }}
                />
              </div>
            );
          }}
        />
      </Table>
    </>
  );
};
