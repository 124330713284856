import {useContext, useEffect, useState} from 'react';

import {DrawerContext} from '../pages/Juding/Arbitrator/context';
import {IJudgingSportsmenResponse, judgingApi} from 'store/api';
import {CentrifugeService} from 'services';
import {useAppSelector} from './hook';
import {
  getNewApplicationsScores,
  calculateAVGByJudgesCount,
} from 'utils/getNewApplicationsScores';
import {JudgeType, NewScore} from '@pages/Juding/Arbitrator/types';
import {getTableDataWithRanks} from 'utils';

export const useUpdateScoresTable = () => {
  const [applications, setApplications] = useState<IJudgingSportsmenResponse[]>(
    [],
  );

  const {judge, judgingMode} = useAppSelector(state => state.judge);

  const {drawerData, setDrawerData} = useContext(DrawerContext);
  const {data: applicationsResponse} =
    judgingApi.useGetJudgingApplicationsQuery(drawerData.nomination?.id || 0);

  const updateDataTable = (
    score: string,
    judge: string,
    judgeType?: JudgeType,
    selectedApplication?: IJudgingSportsmenResponse,
  ) => {
    if (!selectedApplication || !judgeType) {
      return;
    }

    const newApplications = getNewApplicationsScores({
      dataTable: applications,
      judgingMode: judgingMode,
      newScore: {
        score: score,
        judges: [judge],
        applicationId: selectedApplication.id,
        judgeType: judgeType,
        element: null,
      },
    });

    const newApplicationsWithRanks = getTableDataWithRanks(newApplications);

    setApplications(newApplicationsWithRanks);
  };

  useEffect(() => {
    const newApplications = calculateAVGByJudgesCount({
      dataTable: applications,
      judgingMode,
    });

    const newApplicationsWithRanks = getTableDataWithRanks(newApplications);

    setApplications(newApplicationsWithRanks);
  }, [judgingMode]);

  useEffect(() => {
    if (applicationsResponse) {
      const newApplications = calculateAVGByJudgesCount({
        dataTable: applicationsResponse.apps,
        judgingMode,
      });

      const applicationsWithRanks = getTableDataWithRanks(newApplications);

      setApplications(applicationsWithRanks);
      setDrawerData(prevState => ({
        ...prevState,
        selectedApplications: [],
      }));
    }
  }, [applicationsResponse]);

  useEffect(() => {
    const selectedNominationId = drawerData.nomination?.id;
    const channel = `arbitrator:event_${judge?.eventId}_brigade_${judge?.brigade}_nomination_${selectedNominationId}`;
    if (selectedNominationId) {
      CentrifugeService.getSubscription(channel, (data: NewScore) => {
        // TODO это очень не очень
        setApplications(prevState => {
          const newApplications = getNewApplicationsScores({
            dataTable: prevState,
            newScore: data,
            judgingMode,
          });

          const newApplicationsWithRanks =
            getTableDataWithRanks(newApplications);

          return newApplicationsWithRanks;
        });
      });
    }

    return () => {
      CentrifugeService.unsubscribe(channel);
    };
  }, [drawerData.nomination]);

  return {applications, updateDataTable};
};
