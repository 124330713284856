import React from 'react';
import {Layout, Tooltip} from 'antd';

const {Footer} = Layout;

export const CustomFooter = () => {
  return (
    <Footer
      style={{
        textAlign: 'center',
        backgroundColor: 'transparent',
      }}>
      <Tooltip title="Разработчик" color={'gold'}>
        <a
          style={{fontSize: 18}}
          target={'_blank'}
          href={'https://t.me/ha_s_ko'}>
          🙃
        </a>
      </Tooltip>
    </Footer>
  );
};
