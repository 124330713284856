import {FC} from 'react';

import {JudgingLayout} from 'ui';
import {ApplicationsList} from './components/ApplicationsList';

export const SelectSportsmenOld: FC = () => {
  return (
    <JudgingLayout title={'Выберите спортсмена'}>
      <ApplicationsList />
    </JudgingLayout>
  );
};
