import {ReactNode} from 'react';
import {Table, Typography} from 'antd';

import {EventStatisticNominationTableData} from 'store/api';

const {Text} = Typography;

export const getNominationTableSummaryRow = (
  data: readonly EventStatisticNominationTableData[],
): ReactNode => {
  const summaryCategory: {
    [category: string]: number;
  } = {};
  let totalCount = 0;

  data.forEach(({data}) => {
    data.forEach(row => {
      totalCount += row.count;
      if (!summaryCategory[row.category]) {
        summaryCategory[row.category] = row.count;
      } else {
        summaryCategory[row.category] += row.count;
      }
    });
  });

  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0}>
        <Text strong style={{fontSize: 16}}>
          Всего
        </Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1} align="center">
        <Text strong style={{fontSize: 16, color: '#1890ff'}}>
          {totalCount}
        </Text>
      </Table.Summary.Cell>
      {Object.entries(summaryCategory).map(([category, count], index) => (
        <Table.Summary.Cell index={index + 2} align="center">
          <Text strong style={{fontSize: 16, color: '#1890ff'}}>
            {count}
          </Text>
        </Table.Summary.Cell>
      ))}
    </Table.Summary.Row>
  );
};
