import {FC, useContext, useEffect} from 'react';
import {Card, Col, Row, Space} from 'antd';
import {useNavigate} from 'react-router-dom';

import {useAppDispatch} from 'hooks';
import {IJudgingCategoryResponse, judgingApi} from 'store/api';
import {setSpreadsheetId} from 'store/reducers';
import {JudgingLayoutContext} from 'ui';

interface SpreadSheetsListProps {}

export const SpreadSheetsList: FC<SpreadSheetsListProps> = ({}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {setIsLoading} = useContext(JudgingLayoutContext);
  const {data, isFetching} = judgingApi.useGetEventCategoriesQuery();

  useEffect(() => {
    if (data?.length === 1) {
      dispatch(setSpreadsheetId(data[0].id));

      navigate('/judging/select-sportsmen', {
        state: {categoriesLength: data.length},
      });
    }
  }, [data]);

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  const buttonHandler = (category: IJudgingCategoryResponse) => {
    dispatch(setSpreadsheetId(category.id));

    navigate('/judging/select-sportsmen');
  };

  return (
    <Space
      direction="vertical"
      size="small"
      style={{width: '100%', padding: 16}}>
      {data?.map(category => (
        <Row key={category.id} justify="end" style={{width: '100%'}}>
          <Col style={{width: '100%'}}>
            <Card
              style={{cursor: 'pointer'}}
              onClick={() => buttonHandler(category)}>
              {category.name}
            </Card>
          </Col>
        </Row>
      ))}
    </Space>
  );
};
