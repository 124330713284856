import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from '../store';

const API_URL = `${process.env.REACT_APP_BASE_URL}/api`;

export const api = createApi({
  reducerPath: 'api',
  tagTypes: ['Sportsmen', 'Event', 'Statement', 'Group', 'Judge', 'Admin'],
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, {getState}) => {
      const token = (getState() as RootState).auth.accessToken;

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: builder => ({}),
});
