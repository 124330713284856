import {api} from './api';
import {IGroup, IGroupResponse} from '../../models';
import {IResponse} from './types';

export const groupApi = api.injectEndpoints({
  endpoints: builder => ({
    getGroups: builder.query<IResponse<IGroupResponse[]>, any>({
      query: params => ({
        url: '/group',
        params: params,
      }),
      providesTags: () => [{type: 'Group'}],
    }),
    getGroup: builder.query<IGroupResponse, number | undefined>({
      query: groupId => ({
        url: `/group/${groupId}`,
      }),
    }),
    createGroup: builder.mutation<IGroup, IGroup>({
      query: (group: IGroup) => ({
        url: '/group',
        method: 'POST',
        body: group,
      }),
      invalidatesTags: () => [{type: 'Group'}],
    }),
    updateGroup: builder.mutation<IGroup, IGroupResponse>({
      query: group => ({
        url: `/group/${group.id}`,
        method: 'PATCH',
        body: group,
      }),
      invalidatesTags: () => [{type: 'Group'}],
    }),
    deleteGroup: builder.mutation<IGroupResponse, number>({
      query: (groupId: number) => ({
        url: `/group/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Group'],
    }),
  }),
  overrideExisting: false,
});
