import React, {useState} from 'react';
import {Col, Row, Segmented} from 'antd';
import {eventApi} from '../../store/api';
import {MainLayout} from '../../ui';
import {EventCard} from './components/EventCard/EventCard';
import {IEvent} from '../../models';

export const Events: React.FC = () => {
  const [eventsState, setEventsState] = useState<IEvent['state']>('active');
  const {data} = eventApi.useGetEventsQuery(eventsState, {
    refetchOnFocus: true,
  });

  return (
    <MainLayout title={'Соревнования'}>
      <Segmented
        block
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
        }}
        size="large"
        options={[
          {label: 'Текущие', value: 'active'},
          {label: 'Завершенные', value: 'completed'},
        ]}
        value={eventsState}
        onChange={v => {
          setEventsState(v as IEvent['state']);
        }}
      />
      <Row style={{marginTop: 20}}>
        {data?.items.map(event => (
          <Col span={24} key={event.id}>
            <EventCard event={event} />
          </Col>
        ))}
      </Row>
    </MainLayout>
  );
};
