import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {MainLayout} from '../../ui';
import {Select, SelectProps, Tabs, TabsProps} from 'antd';
import {eventApi} from '../../store/api';
import {
  DownloadStatementButton,
  EventStatistic,
  EventApplications,
} from './components';

const initSelectOptions: SelectProps['options'] = [
  {value: '', label: 'Выберите соревнование'},
];

const getTabsItems = (
  selectedEventId: string,
  setSelectedEventId: Dispatch<SetStateAction<string>>,
): TabsProps['items'] => [
  {
    key: 'statistic',
    label: 'Статистика',
    children: (
      <EventStatistic
        selectedEventId={selectedEventId}
        setSelectedEventId={setSelectedEventId}
      />
    ),
  },
  {
    key: 'applications',
    label: 'Заявки',
    children: <EventApplications selectedEventId={selectedEventId} />,
  },
];

export const Admin: FC = () => {
  const [eventsOptions, setEventsOptions] = useState(initSelectOptions);
  const [selectedEvent, setSelectedEvent] = useState<string>('');

  const {data} = eventApi.useGetEventsQuery(undefined);

  const handleChange = (value: string) => {
    setSelectedEvent(value);
  };

  useEffect(() => {
    if (data) {
      const selectData = data.items.map(event => ({
        value: event.id,
        label: event.title,
      }));

      setEventsOptions([...initSelectOptions, ...selectData]);
    }
  }, [data]);

  return (
    <MainLayout title={'Соревнования'}>
      <Select
        defaultValue={'Выберите соревнование'}
        style={{width: '100%'}}
        onChange={handleChange}
        options={eventsOptions}
        value={selectedEvent}
        size="large"
      />

      {selectedEvent && (
        <>
          <DownloadStatementButton selectedEventId={selectedEvent} />

          <Tabs
            defaultActiveKey="1"
            items={getTabsItems(selectedEvent, setSelectedEvent)}
            onChange={() => {}}
          />
        </>
      )}
    </MainLayout>
  );
};
