import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {Button, message} from 'antd';

import {StopOutlined, CheckCircleOutlined} from '@ant-design/icons';
import {JudgingApplicationResponse, judgingApi} from 'store/api';
import {useSuccessSendScore} from 'hooks';
import {IScore} from '@pages/Juding/types';
import {JudgingLayoutContext} from 'ui';

import styles from './style.module.css';

interface SendScoreButtonProps {
  selectedApplication: JudgingApplicationResponse;
  score: IScore;
  buttonsDisabled: boolean;
  setDisableToggle: Dispatch<SetStateAction<boolean>>;
}

export const SendScoreButton: FC<SendScoreButtonProps> = ({
  selectedApplication,
  score,
  buttonsDisabled,
  setDisableToggle,
}) => {
  const {setIsLoading} = useContext(JudgingLayoutContext);
  const update = useSuccessSendScore(score);

  const [sendScore, {isLoading, isSuccess}] = judgingApi.useSendScoreMutation();

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  const buttonSendHandler = () => {
    sendScore({
      nominationId: selectedApplication.nomination.id,
      applicationId: selectedApplication.application.id,
      score: score.score,
      element: score.decline,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Оценка отправлена!');
      setDisableToggle(false);

      update();
    }
  }, [isSuccess]);

  const activeButton = useMemo(() => {
    return (
      score['score']?.length === 3 &&
      score['decline']?.length !== 2 &&
      score['decline']?.length !== 1
    );
  }, [score]);

  return (
    <Button
      className={styles.button}
      disabled={buttonsDisabled || !activeButton}
      onClick={buttonSendHandler}>
      {!buttonsDisabled ? (
        <CheckCircleOutlined
          style={{
            color: '#389e0d',
            fontSize: '2.5rem',
          }}
        />
      ) : (
        <StopOutlined style={{color: '#ff4d4f', fontSize: '2.5rem'}} />
      )}
    </Button>
  );
};
