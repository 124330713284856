import {useAppDispatch, useAppSelector} from './hook';
import {judgingApi} from 'store/api';
import {IScore} from '@pages/Juding/types';
import {useCallback} from 'react';
import {useToggleApplication} from './useToggleApplication';

export const useSuccessSendScore = (score: IScore) => {
  const dispatch = useAppDispatch();

  const applicationIndex = useAppSelector(
    state => state.judge.selectedApplicationIndex,
  );
  const judge = useAppSelector(state => state.judge.judge);

  const {data: applicationsResponse} =
    judgingApi.useGetAllJudgingApplicationsQuery();
  const toggleApplication = useToggleApplication();

  return useCallback(() => {
    if (!applicationsResponse || !judge?.judge) {
      return;
    }

    toggleApplication('next');

    dispatch(
      judgingApi.util.updateQueryData(
        'getAllJudgingApplications',
        undefined,
        data => {
          const application = data[applicationIndex];
          application.application = {
            ...application.application,
            [judge.judge]: score.score,
          };
        },
      ),
    );
  }, [dispatch, score, applicationIndex]);
};
