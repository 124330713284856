import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {Input} from 'antd';
import {openModal} from '../../../../store/reducers/modalSlice';
import {useAppDispatch, useDebounce} from '../../../../hooks';
import {ISportsmen} from '../../../../models';

import styles from './style.module.css';

const {Search} = Input;

interface HeaderProps {
  sportsmens: ISportsmen[];
  setFilteredSportsmens: Dispatch<SetStateAction<ISportsmen[]>>;
}

export const Header: FC<HeaderProps> = ({
  sportsmens,
  setFilteredSportsmens,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const dispatch = useAppDispatch();

  const debouncedSearchTerm = useDebounce(searchValue, 300);

  useEffect(() => {
    if (debouncedSearchTerm.length > 2) {
      onSearch();
    } else {
      setFilteredSportsmens(sportsmens);
    }
  }, [debouncedSearchTerm]);

  const onSearch = () => {
    if (sportsmens) {
      const searchResult = sportsmens.filter(sportsmen => {
        const {firstname, lastname, middlename} = sportsmen;
        return (
          `${lastname} ${firstname} ${middlename}`
            .toLowerCase()
            .search(searchValue.toLowerCase()) !== -1 && sportsmen
        );
      });

      setFilteredSportsmens(searchResult);
    }
  };

  return (
    <div className={styles.header}>
      <button
        className={styles.button}
        onClick={() => {
          dispatch(openModal({modal: 'sportsmenModal'}));
        }}>
        <span className={styles.buttonText}>Добавить</span>
      </button>
      <Search
        value={searchValue}
        size="large"
        allowClear
        className={styles.search}
        placeholder="Поиск спортсмена"
        onChange={e => setSearchValue(e.target.value)}
        onSearch={onSearch}
        enterButton
      />
    </div>
  );
};
