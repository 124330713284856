import {FC} from 'react';
import {Card, FormListFieldData} from 'antd';
import {DeleteTwoTone} from '@ant-design/icons';

import {eventApi} from 'store/api';
import {EventNominationField} from '@pages/CreateEvent/types';
import {AddEventSelectCategory} from './AddEventSelectCategory';
import {NominationsList} from './NominationsList';

interface AddEventCategoryCardProps {
  field: FormListFieldData;
  remove: (index: number | number[]) => void;
  formCategories: EventNominationField[];
}

export const AddEventCategoryCard: FC<AddEventCategoryCardProps> = ({
  field,
  formCategories,
  remove,
}) => {
  const {data} = eventApi.useGetTemplatesQuery();

  if (!data) {
    return null;
  }

  return (
    <Card
      size="small"
      title={`Категория ${field.name + 1}`}
      extra={
        <DeleteTwoTone
          style={{fontSize: 16}}
          onClick={() => {
            remove(field.name);
          }}
        />
      }>
      <AddEventSelectCategory categories={data.categories} field={field} />

      <NominationsList
        nominations={data.nominations}
        formNominations={formCategories[field.name]?.nominations}
        field={field}
      />
    </Card>
  );
};
