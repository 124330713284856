import React, {FC} from 'react';
import {Typography} from 'antd';
import {Navigate, useLocation} from 'react-router-dom';
import {eventApi} from '../../../store/api';
import {MainLayout} from '../../../ui';
import {ApplicationsCard} from '../Components';

const {Title} = Typography;

export const CreateStatement: FC = () => {
  const location = useLocation();

  const {data: event} = eventApi.useGetEventQuery(location.state?.eventId);

  if (!location.state) {
    return <Navigate to={'/'} replace />;
  }

  return (
    <MainLayout title={'Создание заявки'}>
      <Title level={5}>{event?.title}</Title>
      {event && <ApplicationsCard event={event} />}
    </MainLayout>
  );
};
