import React, {FC, useState} from 'react';
import {Button, Card, Spin} from 'antd';
import {ApplicationItem} from '../../../../models';
import {
  SearchApplications,
  Applications,
  ListFoundApplications,
} from '../index';
import {useDebounce} from '../../../../hooks';
import {EventResponse, StatementResponse} from '../../../../store/api';

interface ApplicationsCardProps {
  event: EventResponse;
  statement?: StatementResponse;
}

export const ApplicationsCard: FC<ApplicationsCardProps> = ({
  event,
  statement,
}) => {
  const [applicationsData, setApplicationsData] = useState<ApplicationItem[]>(
    [],
  );
  const [foundedApplicationsData, setFoundedApplicationsData] = useState<
    ApplicationItem[]
  >([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const debouncedSearchTerm = useDebounce(searchValue, 300);

  const onSearch = (applications: ApplicationItem[]) => {
    if (searchValue.length === 0) {
      return;
    }
    if (applications.length === 0) {
      setSearchValue('');
    }

    const searchResult: ApplicationItem[] = [];
    applications.forEach(application => {
      const foundedSportsmen = application.sportsmens.find(sportsmen => {
        const {firstname, lastname} = sportsmen;
        return (
          `${lastname} ${firstname}`
            .toLowerCase()
            .search(searchValue.toLowerCase()) !== -1 && sportsmen
        );
      });

      if (foundedSportsmen) {
        searchResult.push(application);
      }
    });

    setFoundedApplicationsData(searchResult);
  };

  const deleteApplicationHandler = (
    categoryName: string,
    nominationName: string,
    relationId: number,
  ) => {
    // const newApplications = applicationsData.filter(
    //   app =>
    //     app.category !== categoryName ||
    //     app.nomination !== nominationName ||
    //     app.relation_id !== relationId,
    // );
    // setApplicationsData(newApplications);
    // onSearch(newApplications);
  };

  // useEffect(() => {
  //   if (statement) {
  //     // setApplicationsData(statement.applications);
  //   }
  // }, [statement]);

  return (
    <Card
      style={{width: 800}}
      bordered={false}
      title={
        ''
        // <SearchApplications
        //   value={searchValue}
        //   disabled={isLoading}
        //   setSearchValue={setSearchValue}
        //   onSearch={() => onSearch(applicationsData)}
        //   setFoundedApplicationsData={() => {}}
        //   // setFoundedApplicationsData={setFoundedApplicationsData}
        //   debouncedSearchTerm={debouncedSearchTerm}
        // />
      }>
      <Spin spinning={isLoading} size={'large'}>
        {debouncedSearchTerm ? null : (
          // <ListFoundApplications
          // applications={foundedApplicationsData}
          // deleteApplication={deleteApplicationHandler}
          // />
          <Applications
            event={event}
            statement={statement}
            setLoading={setIsLoading}
          />
        )}
      </Spin>
    </Card>
  );
};
