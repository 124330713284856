import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {Input} from 'antd';
import {SearchProps} from 'antd/es/input';
import {IApplication} from '../../../../models';

const {Search} = Input;

interface SearchApplicationsProps extends SearchProps {
  debouncedSearchTerm: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  onSearch: () => void;
  setFoundedApplicationsData: Dispatch<SetStateAction<IApplication[]>>;
}

export const SearchApplications: FC<SearchApplicationsProps> = ({
  debouncedSearchTerm,
  setSearchValue,
  onSearch,
  setFoundedApplicationsData,
  ...props
}) => {
  useEffect(() => {
    if (debouncedSearchTerm.length > 2) {
      onSearch();
    } else {
      setFoundedApplicationsData([]);
    }
  }, [debouncedSearchTerm]);

  const searchHandler = () => {
    onSearch();
  };

  return (
    <Search
      size="large"
      style={{width: '40%'}}
      placeholder={'Поиск по заявке'}
      onChange={e => setSearchValue(e.target.value)}
      onSearch={searchHandler}
      enterButton
      allowClear
      {...props}
    />
  );
};
