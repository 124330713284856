import React, {FC, useEffect} from 'react';
import {Form, Input, Button} from 'antd';
import {Link, useNavigate, useParams} from 'react-router-dom';

import {MainCard} from '../../../components';
import {useAppDispatch, useAuth} from '../../../hooks';
import {resetPassword} from '../../../store/auth/auth.actions';

import styles from './style.module.css';
import classNames from 'classnames';
import {setNewPassword} from '../../../store/auth/auth.slice';
import {AuthLayout} from '../../../ui';

export const NewPassword: FC = () => {
  const {isLoading, user, newPasswordSet} = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const paramsData = useParams();

  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    const data = {
      token: paramsData.token,
      password: values.password,
      password_confirm: values.password_confirm,
    };

    dispatch(resetPassword(data));
  };

  useEffect(() => {
    if (newPasswordSet) {
      setTimeout(() => navigate('login'), 3000);
      dispatch(setNewPassword());
    }
  }, [newPasswordSet]);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  return (
    <AuthLayout>
      <MainCard title={'Новый пароль'}>
        <Form
          form={form}
          disabled={isLoading}
          name="login"
          onFinish={onFinish}
          autoComplete="off"
          className={styles.form}>
          <Form.Item
            name="password"
            rules={[{required: true, message: 'Введите пароль'}]}>
            <Input.Password
              className={classNames(styles.input, styles.password)}
              placeholder={'Пароль'}
            />
          </Form.Item>
          <Form.Item
            name="password_confirm"
            rules={[{required: true, message: 'Введите пароль'}]}>
            <Input.Password
              className={classNames(styles.input, styles.password)}
              placeholder={'Пароль еще раз'}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className={styles.button}
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}>
              Отправить
            </Button>
          </Form.Item>

          <div
            style={{display: 'flex', justifyContent: 'center', fontSize: 16}}>
            <Link to={'/login'}>Вход</Link>
          </div>
        </Form>
      </MainCard>
    </AuthLayout>
  );
};
