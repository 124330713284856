import {IResponse} from './types';
import {IEvent} from '../../models';
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from '../store';
import {EventNominationResponse} from './event.api';

interface IJudgeResponse {
  id: number;
  judgeName: string;
  brigade: number;
}

export interface IJudgeAuthResponse {
  id: number;
  judge: string;
  judgeName: string;
  brigade: number;
  firstname: string;
  lastname: string;
  token: string;
  eventId: number;
}

export interface IJudgingCategoryResponse {
  id: string;
  name: string;
}

export interface IJudgingSportsmenResponse {
  id: number;
  name: string;
  city: string;
  isp1: string;
  isp2: string;
  isp3: string;
  isp4: string;
  isp5: string;
  isp6: string;
  avg_isp: string;
  art1: string;
  art2: string;
  art3: string;
  art4: string;
  art5: string;
  art6: string;
  avg_art: string;
  sl: string;
  avg_sl: string;
  element: string;
  line: string;
  pbs: string;
  avg_decline: string;
  total: string;

  place: number;
  viewed: string;
}

export interface JudgingNominationResponse {
  id: number;
  name: string;
}

export interface JudgingCategoryResponse {
  id: number;
  name: string;
}

export interface JudgingApplicationResponse {
  nomination: EventNominationResponse;
  application: IJudgingSportsmenResponse;
}

export interface ArbitratorSendScoreResponse {
  judge: string;
  sportsmenId: number;
  score: string;
}

export interface SendScoreRequest {
  nominationId: number;
  applicationId: number;
  score: string;
  element: string;
}

export interface CreateDiplomRequestSportsmen {
  nomination: string;
  place: number;
  sportsmen: string;
  category: string;
}

export interface CreateDiplomRequest {
  diplomTemplateId: string;
  sportsmens: CreateDiplomRequestSportsmen[];
}

export interface ViewScoreRequest {
  nomination: string;
  id: number;
  city: string;
  name: string;
  avg_isp: string;
  avg_art: string;
  avg_sl: string;
  avg_decline: string;
  total: string;
  place: number;
}

const API_URL = `${process.env.REACT_APP_BASE_URL}/api`;

export const judgingApi = createApi({
  reducerPath: 'judgingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, {getState}) => {
      const token = (getState() as RootState).judge.judge?.token;

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: builder => ({
    getEventsToJudge: builder.query<IResponse<IEvent[]>, any>({
      query: () => ({
        url: '/judging/events',
      }),
    }),
    getJudges: builder.query<IJudgeResponse[][], number>({
      query: (eventId: number) => ({
        url: `/judging/judges/${eventId}`,
      }),
    }),
    getEventCategories: builder.query<IJudgingCategoryResponse[], void>({
      query: () => ({
        url: '/judging/categories',
      }),
    }),
    getJudgingNominations: builder.query<
      {
        nominations: EventNominationResponse[];
      },
      void
    >({
      query: () => ({
        url: '/judging/judging-nominations',
      }),
    }),
    getJudgingApplications: builder.query<
      {
        apps: IJudgingSportsmenResponse[];
      },
      number
    >({
      query: nominationId => ({
        url: '/judging/sportsmens',
        params: {
          nomination_id: nominationId,
        },
      }),
    }),
    getAllJudgingApplications: builder.query<
      JudgingApplicationResponse[],
      void
    >({
      query: () => ({
        url: '/judging/sportsmens-nominations',
      }),
    }),
    loginJudge: builder.mutation<
      IJudgeAuthResponse,
      {judgeId: number; password: string}
    >({
      query: judgeAuthData => ({
        url: '/judging/login',
        method: 'POST',
        body: judgeAuthData,
      }),
    }),
    sendScore: builder.mutation<any, SendScoreRequest>({
      query: scoreData => ({
        url: '/judging/send-score',
        method: 'POST',
        body: scoreData,
      }),
    }),
    sendScoreV2: builder.mutation<any, SendScoreRequest>({
      query: scoreData => ({
        url: '/judging/send-score/v2',
        method: 'POST',
        body: scoreData,
      }),
    }),
    arbitratorSendScore: builder.mutation<any, ArbitratorSendScoreResponse>({
      query: scoreData => ({
        url: '/judging/arbitrator-send-score',
        method: 'POST',
        body: scoreData,
      }),
    }),
    sendViewScore: builder.mutation<any, ViewScoreRequest>({
      query: viewScoreData => ({
        url: '/judging/send-view-score',
        method: 'POST',
        body: viewScoreData,
      }),
    }),
    createDiplom: builder.mutation<{diplomId: string}, CreateDiplomRequest>({
      query: createDiplomData => ({
        url: '/judging/create-diplom',
        method: 'POST',
        body: createDiplomData,
      }),
    }),
    createDiplomV2: builder.mutation<{diplomId: string}, CreateDiplomRequest>({
      query: createDiplomData => ({
        url: '/judging/v2/create-diplom',
        method: 'POST',
        body: createDiplomData,
      }),
    }),
  }),
});
