import React, {FC, PropsWithChildren} from 'react';
import {Layout} from 'antd';
import {CustomFooter} from '../CustomFooter/CustomFooter';

import styles from './style.module.css';

const {Content} = Layout;

export const AuthLayout: FC<PropsWithChildren> = ({children}) => {
  return (
    <Layout style={{minHeight: '100vh'}}>
      <Content className={styles.content}>{children}</Content>
      <CustomFooter />
    </Layout>
  );
};
