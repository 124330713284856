import {FC} from 'react';
import {Flex, Form, FormListFieldData, List, Select} from 'antd';

import {CreateEventNomination, TemplateNominationResponse} from 'store/api';
import {NominationsListItem} from './NominationsListItem';

interface NominationsListProps {
  field: FormListFieldData;
  nominations: TemplateNominationResponse[];
  formNominations: CreateEventNomination[];
}

export const NominationsList: FC<NominationsListProps> = ({
  field,
  nominations,
  formNominations,
}) => {
  return (
    <Form.List name={[field.name, 'nominations']}>
      {(nominationsFields, {add: addListItem, remove: removeNomination}) => (
        <Flex vertical gap={'small'}>
          {nominationsFields.length > 0 && (
            <List
              itemLayout="vertical"
              dataSource={formNominations}
              renderItem={(item, index) => (
                <NominationsListItem
                  nomination={item}
                  removeNomination={() => removeNomination(index)}
                  editNomination={nomination => addListItem(nomination, index)}
                />
              )}
            />
          )}
          <Select
            style={{
              width: '100%',
            }}
            onSelect={v => addListItem(nominations.find(nom => nom.id === v))}
            placeholder="Выберите номинацию"
            options={nominations.map(item => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Flex>
      )}
    </Form.List>
  );
};
