import {Form, Input, InputRef, Modal} from 'antd';
import {FC, useEffect, useRef} from 'react';
import {judgingApi} from '../../../../../store/api';
import {useAppDispatch} from '../../../../../hooks';
import {useNavigate} from 'react-router-dom';
import {setJudge} from '../../../../../store/reducers/judgeSlice';

interface JudgePasswordModalProps {
  title: string;
  open: boolean;
  judgeId: number;
  closeModal: () => void;
}

export const JudgePasswordModal: FC<JudgePasswordModalProps> = ({
  title,
  open,
  judgeId,
  closeModal,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const inputRef = useRef<InputRef>(null);
  const [form] = Form.useForm();

  const [loginQuery, {data: judgeAuthResponse, isLoading}] =
    judgingApi.useLoginJudgeMutation();

  const onFinish = async () => {
    const password = form.getFieldValue('password');
    await loginQuery({judgeId: judgeId, password});
  };

  useEffect(() => {
    if (judgeAuthResponse) {
      dispatch(setJudge(judgeAuthResponse));

      if (judgeAuthResponse.judge === 'arb') {
        navigate('/judging/new/arbitrator');
      } else {
        navigate('/judging/new/select-sportsmen');
      }
    }
  }, [judgeAuthResponse]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Modal
      title={title}
      centered
      destroyOnClose
      open={open}
      onOk={onFinish}
      onCancel={closeModal}
      confirmLoading={isLoading}
      cancelText={'Отмена'}
      okText={'Войти'}
      okButtonProps={{size: 'large'}}
      cancelButtonProps={{size: 'large'}}>
      <Form
        form={form}
        preserve={false}
        disabled={isLoading}
        name="judgeLogin"
        autoComplete="off">
        <Form.Item name="password">
          <Input ref={inputRef} placeholder={'Пароль'} size="large" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
