import {FC} from 'react';
import {Button, Flex, Form} from 'antd';

import {AddEventCategoryCard} from '../AddEventCategoryCard';
import {EventNominationField} from '@pages/CreateEvent/types';

interface AddEventCategoriesListProps {
  nominations: EventNominationField[];
}

export const AddEventCategoriesList: FC<AddEventCategoriesListProps> = ({
  nominations,
}) => {
  return (
    <Form.List name="nominations">
      {(fields, {add, remove}) => (
        <Flex
          vertical
          gap={'middle'}
          style={{
            width: '50%',
          }}>
          {fields.map(field => (
            <AddEventCategoryCard
              key={field.key}
              formCategories={nominations}
              field={field}
              remove={remove}
            />
          ))}

          <Button type="dashed" onClick={() => add()} block>
            + Добавить категорию
          </Button>
        </Flex>
      )}
    </Form.List>
  );
};
