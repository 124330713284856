import {Card, Flex, Statistic} from 'antd';
import {FC} from 'react';

interface EventStatisticTotalProps {
  applicationsCount: number;
  uniqueSportsmensCount: number;
}

export const EventStatisticTotal: FC<EventStatisticTotalProps> = ({
  applicationsCount,
  uniqueSportsmensCount,
}) => {
  return (
    <Flex gap={16}>
      <Card>
        <Statistic
          title="Всего выступлений"
          value={applicationsCount}
          valueStyle={{color: '#1890ff'}}
        />
      </Card>
      <Card>
        <Statistic
          title="Всего спортсменов"
          value={uniqueSportsmensCount}
          valueStyle={{color: '#1890ff'}}
        />
      </Card>
    </Flex>
  );
};
