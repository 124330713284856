import {
  ApplicationModified,
  GroupApplication,
} from '@pages/Juding/Arbitrator/types';
import {EventNominationResponse, JudgingApplicationResponse} from 'store/api';

export const groupJudgingApplications = (
  applications: JudgingApplicationResponse[],
): GroupApplication[] => {
  const apps: GroupApplication[] = [];
  const appsCategories: {
    [key: string]: {
      nomination: EventNominationResponse;
      applications: ApplicationModified[];
    }[];
  } = {};

  applications.forEach((applicationItem, index) => {
    const {nomination, application} = applicationItem;
    const currentApplicationWithIndex = {
      ...application,
      originalIndex: index,
    };

    if (!appsCategories[nomination.categoryName]) {
      appsCategories[nomination.categoryName] = [
        {
          nomination,
          applications: [currentApplicationWithIndex],
        },
      ];
    } else {
      const categoryData = appsCategories[nomination.categoryName];

      const categoryNomination = categoryData.find(
        item => item.nomination.id === nomination.id,
      );

      if (!categoryNomination) {
        categoryData.push({
          nomination,
          applications: [currentApplicationWithIndex],
        });
      } else {
        categoryNomination.applications.push(currentApplicationWithIndex);
      }
    }
  });

  Object.entries(appsCategories).forEach(([key, value]) => {
    apps.push({
      category: key,
      nominations: value,
    });
  });
  return apps;
};
