import React, {FC, useEffect} from 'react';
import {Typography} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import {MainLayout} from '../../../ui';
import {eventApi, statementApi} from '../../../store/api';
import {skipToken} from '@reduxjs/toolkit/query';
import {ApplicationsCard} from '../Components';

const {Title} = Typography;

export const Statement: FC = () => {
  const navigate = useNavigate();
  const paramsData = useParams();

  const {data: statementData, isError} = statementApi.useGetStatementQuery(
    Number(paramsData.id) || skipToken,
  );
  const {data: event} = eventApi.useGetEventQuery(
    statementData?.event.id || skipToken,
  );

  useEffect(() => {
    if (isError) {
      navigate('/statements');
    }
  }, [isError]);

  return (
    <MainLayout title={'Редактирование заявки'}>
      <Title level={5}>{event?.title}</Title>
      {event && <ApplicationsCard event={event} statement={statementData} />}
    </MainLayout>
  );
};
