import {FC, useEffect, useState} from 'react';
import {Segmented, Card} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import {JudgingLayout} from 'ui';
import {judgingApi} from '../../../store/api';
import {JudgePasswordModal} from './components';

interface IBrigade {
  value: number;
  label: string;
}

export const SelectJudgeOld: FC = () => {
  const [brigades, setBrigades] = useState<IBrigade[]>([]);
  const [selectedBrigade, setSelectedBrigade] = useState<number>(0);
  const [selectedJudge, setSelectedJudge] = useState<any | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;

  const {data} = judgingApi.useGetJudgesQuery(locationState?.eventId, {
    skip: !locationState?.eventId,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (!locationState) {
      navigate('/judging/select-event');
    }
  }, [locationState]);

  useEffect(() => {
    if (data) {
      const mappedBrigades: IBrigade[] = data.map((_, index) => ({
        label: `Бригада ${index + 1}`,
        value: index,
      }));
      setBrigades(mappedBrigades);
      setSelectedBrigade(mappedBrigades[0]?.value);
    }
  }, [data]);

  return (
    <JudgingLayout title={'Выберите судью'}>
      {selectedJudge && (
        <JudgePasswordModal
          judgeId={selectedJudge.id}
          open={selectedJudge}
          title={
            brigades[selectedBrigade]?.label + ' | ' + selectedJudge.judgeName
          }
          closeModal={() => setSelectedJudge(null)}
        />
      )}
      <Segmented
        size="large"
        defaultValue={selectedBrigade}
        options={brigades}
        onChange={value => setSelectedBrigade(value as number)}
      />
      {data && (
        <div style={{marginTop: 10}}>
          {data[selectedBrigade]?.map(judge => (
            <Card
              key={judge.id}
              style={{
                cursor: 'pointer',
                marginBottom: 10,
                minWidth: 200,
              }}
              bodyStyle={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                fontWeight: '500',
              }}
              onClick={() => setSelectedJudge(judge)}>
              {judge.judgeName}
            </Card>
          ))}
        </div>
      )}
    </JudgingLayout>
  );
};
