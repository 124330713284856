import React, {FC, useEffect, useState} from 'react';
import {Modal, Typography} from 'antd';
import {IGroupParams} from '../types';
import {SportsmensTable} from './SportsmensTable';
import {GroupsTable} from './GroupsTable';
import {ApplicationItem, ISportsmen} from '../../../../models';
import {EventNominationResponse, ISportsmensListQueryParams} from 'store/api';

import styles from './style.module.css';

interface SportsmensGroupsModalProps {
  close: () => void;
  nomination: EventNominationResponse;
  addApplications: (newApplications: ApplicationItem[]) => void;
  relationsIds: number[];
}

export const SportsmensGroupsModal: FC<SportsmensGroupsModalProps> = ({
  close,
  nomination,
  addApplications,
  relationsIds,
}) => {
  const [requestSportsmensParams, setRequestSportsmensParams] =
    useState<ISportsmensListQueryParams | null>(null);
  const [requestGroupsParams, setRequestGroupsParams] =
    useState<IGroupParams | null>(null);
  const [selectedData, setSelectedData] = useState<
    {relationId: number; sportsmens: ISportsmen[]}[]
  >([]);

  const changeRequestParams = (nomination: EventNominationResponse) => {
    if (nomination.type === 'individual') {
      setRequestSportsmensParams({
        gender: nomination.gender,
        age_from: nomination.ageFrom,
        age_to: nomination.ageTo,
        ...(relationsIds.length > 0 && {exclusion_ids: relationsIds}),
      });
      setRequestGroupsParams(null);
    } else {
      setRequestGroupsParams({
        type: nomination.code,
      });
      setRequestSportsmensParams(null);
    }
  };

  useEffect(() => {
    changeRequestParams(nomination);
  }, [nomination]);

  const okHandler = () => {
    const newApplications: ApplicationItem[] = selectedData.map(data => ({
      nomination: nomination,
      relationId: data.relationId,
      sportsmens: data.sportsmens,
    }));

    addApplications(newApplications);
    close();
  };

  return (
    <Modal
      title={
        nomination.type === 'individual'
          ? 'Выберите спортсменов'
          : 'Выберите группы'
      }
      centered
      open={true}
      onOk={okHandler}
      onCancel={close}
      okText={'Добавить'}
      okButtonProps={{
        size: 'large',
      }}
      cancelText={'Отмена'}
      cancelButtonProps={{
        size: 'large',
        type: 'text',
      }}>
      <Typography>{`Категория: ${nomination.categoryName}`}</Typography>
      <Typography>{`Номинация: ${nomination.name}`}</Typography>

      {requestSportsmensParams && (
        <SportsmensTable
          setSelectedData={setSelectedData}
          params={requestSportsmensParams}
        />
      )}

      {requestGroupsParams && (
        <GroupsTable
          setSelectedData={setSelectedData}
          groupParams={requestGroupsParams}
        />
      )}
    </Modal>
  );
};
