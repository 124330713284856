import React, {FC, useEffect, useState} from 'react';
import {Button, Form, List, message, Modal, Select} from 'antd';
import {groupApi} from '../../../../store/api';
import {IGroup, ISportsmen} from '../../../../models';
import {useAppDispatch, useAppSelector} from '../../../../hooks';
import {closeModals} from '../../../../store/reducers/modalSlice';
import {DeleteButton} from '../../../../components';
import {AddGroupModalSportsmensTable} from './AddGroupModalSportsmensTable';

import styles from './style.module.css';

const groupTypeOptions = [
  {label: 'Смешанная пара', value: 'MP', min: 2, max: 2},
  {label: 'Трио', value: 'TR', min: 3, max: 3},
  {label: 'Группа', value: 'GR', min: 5, max: 5},
  {label: 'Танцевальная гимнастика', value: 'AD', min: 5, max: 8},
  {
    label: 'Гимнастическая платформа',
    value: 'AS',
    min: 5,
    max: 8,
  },
  {
    label: 'Показательные выступления',
    value: 'SHOW',
    min: 1,
    max: 20,
  },
];

export const AddGroupModal: FC = () => {
  const [groupSportsmens, setGroupSportsmens] = useState<ISportsmen[]>([]);
  const [groupTitle, setGroupTitle] = useState<string>('');
  const [selectedGroupType, setSelectedGroupType] = useState<string | null>(
    null,
  );
  const [groupLimits, setGroupLimits] = useState<{
    min: number;
    max: number;
  } | null>(null);

  const {groupModal, modalData} = useAppSelector(state => state.modal);
  const dispatch = useAppDispatch();

  const {data: currentGroup, isSuccess: isSuccessGetGroup} =
    groupApi.useGetGroupQuery(modalData?.groupId, {
      refetchOnMountOrArgChange: true,
      skip: !modalData?.groupId,
    });
  const [createGroup, {isSuccess}] = groupApi.useCreateGroupMutation();
  const [updateGroup, {isSuccess: isSuccessUpdateGroup}] =
    groupApi.useUpdateGroupMutation();

  const saveGroupHandler = async () => {
    const newGroup: IGroup = {
      title: groupTitle,
      type: selectedGroupType || '',
      count: groupSportsmens.length,
      sportsmensIds: groupSportsmens.map(sportsmen => sportsmen.id),
      sportsmens: groupSportsmens,
    };

    currentGroup
      ? await updateGroup({id: currentGroup.id, ...newGroup})
      : await createGroup(newGroup);
  };

  useEffect(() => {
    if (isSuccess || isSuccessUpdateGroup) {
      message.success(
        isSuccessUpdateGroup
          ? 'Группа успешно обновлена'
          : 'Группа успешно создана',
      );
      dispatch(closeModals());
    }
  }, [isSuccess, isSuccessUpdateGroup]);

  useEffect(() => {
    if (groupModal) {
      setGroupSportsmens([]);
      setSelectedGroupType(null);
      setGroupLimits(null);
    }
  }, [groupModal]);

  useEffect(() => {
    if (currentGroup) {
      setGroupSportsmens(currentGroup.sportsmens);

      const currentTypeOption = groupTypeOptions.find(
        option => option.value === currentGroup.type,
      );
      if (currentTypeOption) {
        setSelectedGroupType(currentTypeOption.value);
        setGroupLimits({
          min: currentTypeOption.min,
          max: currentTypeOption.max,
        });
      }
    }
  }, [currentGroup, isSuccessGetGroup]);

  useEffect(() => {
    const groupName =
      (selectedGroupType ? selectedGroupType : 'Название группы') +
      groupSportsmens.reduce((result, groupSportsmen) => {
        result += ` ${groupSportsmen.lastname} ${groupSportsmen.firstname[0]}. ${groupSportsmen.middlename[0]}.`;
        return result;
      }, '');
    setGroupTitle(groupName);
  }, [groupSportsmens]);

  const addSportsmen = (sportsmen: ISportsmen) => {
    setGroupSportsmens([...groupSportsmens, sportsmen]);
  };

  const deleteSportsmen = (sportsmen: ISportsmen) => {
    setGroupSportsmens(
      groupSportsmens.filter(
        groupSportsmen => groupSportsmen.id !== sportsmen.id,
      ),
    );
  };

  return (
    <Modal
      title={groupTitle}
      centered={true}
      open={groupModal}
      destroyOnClose={true}
      onOk={() => {}}
      footer={null}
      width={'50%'}
      wrapClassName={styles.blurWrap}
      onCancel={() => {
        dispatch(closeModals());
      }}>
      <Form labelCol={{span: 3}} wrapperCol={{span: 24}}>
        <Form.Item name="groupType" label="Тип группы">
          <>
            <Select
              size={'large'}
              placeholder="Выберите тип группы"
              options={groupTypeOptions}
              value={selectedGroupType}
              onChange={(value, option: any) => {
                setSelectedGroupType(value);
                setGroupSportsmens([]);
                setGroupTitle(value);

                setGroupLimits({min: option.min, max: option.max});
              }}
            />
            {groupLimits && `min: ${groupLimits.min} max: ${groupLimits.max}`}
          </>
        </Form.Item>

        <Form.Item name="sportsmens" label="Спортсмены">
          <AddGroupModalSportsmensTable
            groupSportsmens={groupSportsmens}
            rowHandler={addSportsmen}
            disableTable={
              groupSportsmens.length === groupLimits?.max ||
              !Boolean(selectedGroupType)
            }
          />
        </Form.Item>

        <Form.Item name="members" label="Состав">
          <List
            bordered
            size={'small'}
            itemLayout="horizontal"
            dataSource={groupSportsmens}
            renderItem={item => (
              <List.Item
                actions={[
                  <DeleteButton deleteHandler={() => deleteSportsmen(item)} />,
                ]}>
                <List.Item.Meta
                  title={`${item.lastname} ${item.firstname} ${item.middlename}`}
                  description={String(item.birthdate)}
                />
              </List.Item>
            )}
          />
        </Form.Item>
      </Form>

      <Button
        disabled={
          !Boolean(selectedGroupType) ||
          groupSportsmens.length < Number(groupLimits?.min)
        }
        onClick={saveGroupHandler}
        type="primary"
        block>
        Сохранить
      </Button>
    </Modal>
  );
};
