import {FC} from 'react';

import styles from './style.module.css';
import {parseArraySportsmens} from 'utils';
import {IApplicationError} from 'models';

interface ErrorApplicationItemProps {
  application: IApplicationError;
  index: number;
}

export const ErrorApplicationItem: FC<ErrorApplicationItemProps> = ({
  application,
  index,
}) => {
  return (
    <div className={styles.application}>
      <p className={styles.applicationNumber}>{index}</p>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div className={styles.applicationInfoContainer}>
            <div className={styles.applicationContainer}>
              Категория:
              <span className={styles.applicationInfo}>
                {application.nomination.categoryName}
              </span>
              <div className={styles.nominationContainer}>
                Номинация:
                <span className={styles.applicationInfo}>
                  {application.nomination.name}
                </span>
              </div>
            </div>

            <div className={styles.sportsmensContainer}>
              Состав:
              <span className={styles.applicationInfo}>
                {parseArraySportsmens(application.sportsmens)}
              </span>
            </div>
          </div>
        </div>

        <div style={{padding: 10, marginLeft: 15}}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 400,
              color: '#595959',
            }}>
            Ошибки:
            <div style={{marginLeft: 10, color: '#ff4d4f', fontWeight: 500}}>
              {application.error_messages.map((error, index) => (
                <p key={index}>{error}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
