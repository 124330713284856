import {FC} from 'react';
import {Button, Flex, Typography} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';

const {Title} = Typography;

interface StatisticTableTitleProps {
  title: string;
  onDownload?: () => void;
}

export const StatisticTableTitle: FC<StatisticTableTitleProps> = ({
  title,
  onDownload,
}) => {
  return (
    <Flex align="center" gap={8}>
      <Title level={3} style={{margin: 0}}>
        {title}
      </Title>
      <Button icon={<DownloadOutlined />} size="small" onClick={onDownload} />
    </Flex>
  );
};
