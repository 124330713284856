import React, {FC} from 'react';
import {Button, Card, Typography} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {ApplicationItem} from '../../../../models';
import {ListApplications} from '../ListApplications/ListApplications';
import {EventNominationResponse} from 'store/api';

const {Text} = Typography;

interface NominationCardProps {
  nomination: EventNominationResponse;
  addApplicationButtonHandler: () => void;
  applications: ApplicationItem[];
  deleteApplication: (nominationId: number, relationId: number) => void;
}

export const NominationCard: FC<NominationCardProps> = ({
  nomination,
  addApplicationButtonHandler,
  applications,
  deleteApplication,
}) => {
  return (
    <Card
      key={nomination.name}
      style={{marginBottom: 10}}
      title={<Text>{nomination.name}</Text>}
      extra={
        <Button
          type="primary"
          shape="circle"
          icon={<PlusOutlined />}
          onClick={addApplicationButtonHandler}
        />
      }>
      {applications.length > 0 ? (
        <ListApplications
          applications={applications}
          deleteApplication={deleteApplication}
        />
      ) : (
        <Text type="secondary">Здесь будет список участников</Text>
      )}
    </Card>
  );
};
