import {useState} from 'react';

import {ApplicationsTable, ArbitratorPageHeader} from './components';
import {JudgingLayout} from 'ui';
import {DrawerContext, DrawerData, initDrawerData} from './context';

export const Arbitrator = () => {
  const [drawerData, setDrawerData] = useState<DrawerData>(initDrawerData);

  return (
    <JudgingLayout>
      <DrawerContext.Provider value={{drawerData, setDrawerData}}>
        <ArbitratorPageHeader />

        <ApplicationsTable />
      </DrawerContext.Provider>
    </JudgingLayout>
  );
};
