import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {Button} from 'antd';

import {InputEnum, IScore} from '../../../types';
import {IJudgingSportsmenResponse, JudgingApplicationResponse} from 'store/api';
import {DeleteScoreButton} from './DeleteScoreButton';
import {SendScoreButton} from './SendScoreButton';
import {PrevNextButtons} from './PrevNextButtons';

import styles from './style.module.css';

const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9];

interface NumbersPadProps {
  score: IScore;
  activeInput: InputEnum;
  setScore: Dispatch<SetStateAction<IScore>>;
  judge: keyof IJudgingSportsmenResponse;
  selectedApplication: JudgingApplicationResponse;
}

export const NumbersPad: FC<NumbersPadProps> = ({
  activeInput,
  score,
  setScore,
  judge,
  selectedApplication,
}) => {
  const [disableToggle, setDisableToggle] = useState<boolean>(false);
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);

  const setScoreValue = (value: string) => {
    const newScoreValues = {...score, [activeInput]: value};
    setScore(newScoreValues);

    if (
      Object.values(newScoreValues).filter(value => value.length === 0)
        .length === 2
    ) {
      setDisableToggle(false);
    }
  };

  const buttonScoreHandler = (buttonScore: number) => {
    if (score[activeInput].length === 0) {
      setScoreValue(String(buttonScore) + ',');
    } else if (score[activeInput].length === 2) {
      setScoreValue(score[activeInput] + String(buttonScore));
    }

    setDisableToggle(true);
  };

  useEffect(() => {
    if (selectedApplication && judge) {
      if (judge in selectedApplication.application) {
        const applicationScore = String(selectedApplication.application[judge]);
        const applicationDecline =
          judge === 'sl' ? selectedApplication.application.element : '';

        setScore({
          score: applicationScore,
          decline: applicationDecline,
        });

        if (applicationScore.length > 0) {
          setButtonsDisabled(true);
        } else {
          setButtonsDisabled(false);
        }
      }
    } else {
      setScore({
        score: '',
        decline: '',
      });
    }
  }, [selectedApplication]);

  return (
    <>
      {buttons.map(btn => (
        <Button
          disabled={buttonsDisabled}
          key={btn}
          onClick={() => buttonScoreHandler(btn)}
          className={styles.button}>
          {btn}
        </Button>
      ))}
      <DeleteScoreButton
        disabled={buttonsDisabled}
        setScoreValue={setScoreValue}
        editableValue={score[activeInput]}
      />
      <Button
        disabled={buttonsDisabled}
        onClick={() => buttonScoreHandler(0)}
        className={styles.button}>
        0
      </Button>
      <SendScoreButton
        buttonsDisabled={buttonsDisabled}
        score={score}
        selectedApplication={selectedApplication}
        setDisableToggle={setDisableToggle}
      />

      <PrevNextButtons disabled={disableToggle} score={score.score} />
    </>
  );
};
