import {axiosBase} from '../api/axios';
import {IJudgingSportsmenResponse} from '../store/api';
import {store} from '../store/store';

interface IProtocolRequest {
  nominationId: number;
  sportsmens: Array<IJudgingSportsmenResponse>;
  protocolType: string;
  mainJudgeName: string;
  mainSecretaryName: string;
  judgeCount: number;
}

export const DownloadService = {
  async getStatementsFile(eventId: string) {
    return await axiosBase.get(`/admin/statement/${eventId}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'text/xlsx',
        Authorization: `Bearer ${store.getState().auth.accessToken}`,
      },
    });
  },

  async getProtocolFile(protocolRequestData: IProtocolRequest) {
    return await axiosBase.post(`/judging/protocol`, protocolRequestData, {
      responseType: 'blob',
    });
  },
};
