import {FC} from 'react';
import {Card, Col, Row} from 'antd';
import {useNavigate} from 'react-router-dom';
import {JudgingLayout} from 'ui';
import {judgingApi} from '../../../store/api';
import {IEvent} from '../../../models';

export const SelectEventOld: FC = () => {
  const navigate = useNavigate();

  const {data} = judgingApi.useGetEventsToJudgeQuery('');

  const buttonHandler = (event: IEvent) => {
    navigate('/judging/select-judge', {state: {eventId: event.id}});
  };

  return (
    <JudgingLayout title={'Выберите соревнование'}>
      {data?.items.map(event => (
        <Row key={event.id} justify="end" style={{width: '100%'}}>
          <Col style={{width: '100%'}}>
            <Card
              style={{cursor: 'pointer', marginBottom: 10}}
              onClick={() => buttonHandler(event)}>
              {event.title}
            </Card>
          </Col>
        </Row>
      ))}
    </JudgingLayout>
  );
};
